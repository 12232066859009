<div class="banner" role="banner">
  <h1>{{ 'games.page.score.predictor.title' | translate }}</h1>
</div>

<fgb-game-nav></fgb-game-nav>

<div class="container">
  <ng-container *ngIf="scorePredictors$ | async as scorePredictors">
    <div *ngIf="!scorePredictors.length">
      <div class="container">
        <div class="no-items mx-0 w-100 mt-4 no-results-placeholder-div">
          <div class="no-results-placeholder">{{ 'score.score.predictor.placeholder.description' | translate }}</div>
        </div>
      </div>
    </div>
    <div class="score-predictor-owl" *ngIf="scorePredictors?.length > 0">
      <div class="card rounded score-predictor-earn-points-card fw-bold mb-4">
        <h5 class="mb-0">{{ 'score.predictor.page.earn.points.message' | translate }}</h5>
      </div>
      <fgb-score-predictor-list></fgb-score-predictor-list>
    </div>
  </ng-container>
</div>
