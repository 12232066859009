<div class="banner" role="banner">
  <h1>{{ 'ticket.transfer.page.title' | translate }}</h1>
</div>

<div class="container mt-4">
  <div class="row">
    <div class="col-lg-9 col-12">
      <h2 class="mt-0">{{ 'ticket.transfer.page.welcome.text' | translate }}</h2>
      <p>
        {{ 'ticket.transfer.page.welcome.description' | translate }}
      </p>
    </div>
    <div class="col-lg-3 col-12">
      <button
        [routerLink]="['/contacts']"
        class="btn btn-primary fw-bold d-flex align-items-center justify-content-center ms-auto w-100"
      >
        {{ 'manage.contact.btn.text' | translate }}
        <span class="icon material-icons ms-1" aria-hidden="true">arrow_forward_ios</span>
      </button>
    </div>
    <div class="col-12">
      <h4 class="games-title mt-3 mb-4 text-primary">Home Game</h4>
    </div>
  </div>
  <!-- <fgbcl-games-list-with-tickets></fgbcl-games-list-with-tickets> -->

  <ng-container *ngIf="showPastGamesSection">
    <div class="col-12">
      <h4 class="games-title mt-3 mb-4 text-primary">Past Home Games</h4>
    </div>
    <fgbcl-games-list-with-tickets
      [showPastGamesOnly]="true"
      (pastGamesCountEvent)="shouldShowPastGameSection($event)"
    ></fgbcl-games-list-with-tickets>
  </ng-container>
</div>
