import { AfterViewInit, Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import {
  MemberQuery,
  PopUpNotificationQuery,
  PopUpNotificationService,
  config,
  PopUpNotificationViewModel,
  PopUpNotificationResponseType,
  PopUpWindowType,
  TermsAndConditionsType,
} from '@fgb/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CustomPopupComponent } from 'src/app/shared/components/custom-popup/custom-popup.component';
import { CtrlTemplateDirective } from 'src/app/shared/directives/control-template.directive';
import * as _moment from 'moment';
const moment = _moment;

@Component({
  selector: 'fgb-popup-notification',
  templateUrl: './popup-notification.component.html',
})
export class PopupNotificationComponent implements OnInit, AfterViewInit, OnDestroy {
  private isDestroyed$ = new Subject<void>();
  confirmAction$ = new Subject<boolean>();
  currentLcid: string = config.defaultLcid;
  popupResponse: PopUpNotificationResponseType;
  termsAndConditionsType = TermsAndConditionsType.General;
  termsAccepted = false;

  @ViewChildren(CtrlTemplateDirective) templateRefs: QueryList<CtrlTemplateDirective>;

  constructor(
    private popupNotificationService: PopUpNotificationService,
    private popupNotificationQuery: PopUpNotificationQuery,
    private memberQuery: MemberQuery,
    private router: Router,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    this.setLcidFromUserSub();
    this.setPopupFromNavigationSub();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.getPopupForPage(this.router.url);
    }, 1000);
  }

  /** Subscribe to user changes to update Lcid accordingly*/
  private setLcidFromUserSub(): void {
    this.memberQuery.userContext$.pipe(takeUntil(this.isDestroyed$)).subscribe((user) => {
      if (user && user.lcid) {
        this.currentLcid = user.lcid;
      }
    });
  }

  /** Subscribe to navigation changes to then fetch the popup notification from url*/
  private setPopupFromNavigationSub(): void {
    this.router.events.pipe(takeUntil(this.isDestroyed$)).subscribe((event) => {
      if (event instanceof NavigationStart) {
        setTimeout(() => {
          this.getPopupForPage(this.router.url);
        }, 1000);
      }
    });
  }

  /** Get the first popup for a specific page
   * @param pageUrl URL of the page that should be checked for popup notifications
   */
  private getPopupForPage(pageUrl: string): void {
    const newPopup = this.popupNotificationQuery.getPopUpNotificationForPage(pageUrl, +this.currentLcid);

    if (newPopup) {
      if (this.popupShouldBeShownNow(newPopup)) {
        this.openPopUp(newPopup);
      }
    }
  }

  /** Check if the popup should be shown now, testing against start and end times
   * @param popup the popup Notification that is being tested
   */
  private popupShouldBeShownNow(popup: PopUpNotificationViewModel): Boolean | undefined {
    const noStartDateSpecified = !popup.startDate;
    const startDateHasPassed = popup.startDate && moment.utc(popup.startDate).local().isBefore(moment());
    const noEndDateSpecified = !popup.endDate;
    const endDateHasNotPassed = popup.endDate && moment.utc(popup.endDate).local().isAfter(moment());

    return (noStartDateSpecified || startDateHasPassed) && (noEndDateSpecified || endDateHasNotPassed);
  }

  /** Open modal dialog box using the Custom PopUp Component from the shared folder
   * @param popup The popup containing the notification data
   */
  private openPopUp(popup: PopUpNotificationViewModel): void {
    const modalRef = this.modalService.open(CustomPopupComponent, this.definePopupOptions(popup));
    modalRef.componentInstance.popupData = popup;
    modalRef.componentInstance.dynamicComponentRef = this.findTemplate(popup.componentName);

    this.confirmAction$.pipe(takeUntil(this.isDestroyed$)).subscribe((val) => {
      if (val) {
        modalRef.componentInstance.actionConfirmation$.next(true);
      }
    });

    modalRef.result.then((result) => {
      this.savePopupResponse(popup, result);
    });
  }

  /** Find which component needs to be displayed in the popup, depending on its componentName
   * @param componentName The componentName from the popup you want to display
   * @returns The reference of the ng-template that matches the component
   */
  private findTemplate(componentName: string): any {
    const templateList: any[] = this.templateRefs.toArray();

    let component =  templateList.find((x) => x.name.toLowerCase() == componentName.toLowerCase());
    if (component) {
      return component.template;
    }
  }

  /** Save popup response
   * @param popup The popup containing the notification data
   * @param responseType The result returned by the modal popup
   */
  savePopupResponse(popup: PopUpNotificationViewModel, responseType: PopUpNotificationResponseType): void {
    this.popupNotificationService.markNotificationsAsRead(popup.popUpNotificationId, responseType);
  }

  /** Define Pop Up options depending on the notification windowType
   * @param popup The popup you want to configure
   * @returns The modal options to pass down when opening the modal window with modalService.open()
   * @note The classes mentionned in option.windowClass must be declared globally in styles.scss
   */
  private definePopupOptions(popup: PopUpNotificationViewModel): NgbModalOptions {
    let option: NgbModalOptions = {};
    option.backdrop = 'static';
    option.keyboard = false;
    switch (popup.windowType) {
      case PopUpWindowType.MessageBox: {
        option.size = 'lg';
        break;
      }
      case PopUpWindowType.ImportantMessage: {
        option.size = 'lg';
        option.windowClass = 'importantPopup';
        break;
      }
    }
    return option;
  }

  ngOnDestroy(): void {
    this.isDestroyed$.next();
    this.isDestroyed$.complete();
  }
}
