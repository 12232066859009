<nav class="navbar bg-dark-green navbar-dark navbar-expand-lg text-white d-none d-lg-flex">
  <a
    class="navbar-brand py-0"
    [routerLink]="['/']"
    routerLinkActive="router-link-active"
    attr.aria-label="{{ 'nav.home.page.screenreader' | translate : { fgbdefault: 'Move to home page' } }}"
  >
    <label id="homeLink" class="accessibility-only">Go To Home Page</label>
    <img alt="" class="ms-1 h-100" aria-labelledby="homeLink" src="assets/images/club-logo.png" draggable="false" />
  </a>

  <button
    class="navbar-toggler"
    type="button"
    (click)="isCollapsed = !isCollapsed"
    aria-controls="navbarSupportedContent"
    aria-expanded="false"
    attr.aria-label="{{ 'nav.toggle.screenreader' | translate }}"
  >
    <span class="navbar-toggler-icon"></span>
  </button>

  <!-- Left Side Content -->
  <div class="navbar-collapse" [ngbCollapse]="isCollapsed" id="navbarSupportedContent">
    <div class="nav-container">
      <ul class="navbar-nav me-auto" #greedyNav>
        <li class="nav-item active">
          <a
            class="nav-link mx-1"
            [routerLink]="['/rewards']"
            routerLinkActive="router-link-active"
            attr.aria-label="{{ 'nav.rewards.screenreader' | translate : { fgbdefault: 'Move to rewards page' } }}"
            >{{ 'nav.rewards' | translate }}</a
          >
        </li>
        <li class="nav-item active">
          <a
            class="nav-link mx-1"
            [routerLink]="['/challenges']"
            routerLinkActive="router-link-active"
            attr.aria-label="{{ 'nav.challenges.screenreader' | translate : { fgbdefault: 'Move to challenges page' } }}"
            >{{ 'nav.challenges' | translate }}</a
          >
        </li>

        <li class="nav-item active">
          <a
            class="nav-link mx-1"
            [routerLink]="['/games/quiz']"
            routerLinkActive="router-link-active"
            attr.aria-label="{{ 'nav.games.screenreader' | translate : { fgbdefault: 'Move to games page' } }}"
            >{{ 'nav.games' | translate }}</a
          >
        </li>

        <ng-container *notInPopulation="[populations.NonMember]">
          <li class="nav-item active">
            <a
              class="nav-link mx-1"
              [routerLink]="['/earn']"
              routerLinkActive="router-link-active"
              attr.aria-label="{{ 'nav.earn.screenreader' | translate : { fgbdefault: 'Move to earn page' } }}"
              >{{ 'nav.earn' | translate }}</a
            >
          </li>
        </ng-container>

        <li class="nav-item active">
          <a
            class="nav-link mx-1"
            [routerLink]="['/tiers']"
            routerLinkActive="router-link-active"
            attr.aria-label="{{ 'nav.tiers.screenreader' | translate : { fgbdefault: 'Move to tiers page' } }}"
            >{{ 'nav.tiers' | translate }}</a
          >
        </li>

        <li class="nav-item active help-dropdown" focusTab #helpBtn>
          <div ngbDropdown #ddHelp="ngbDropdown" [autoClose]="'outside'" role="group">
            <a
              class="nav-link mx-1"
              ngbDropdownToggle
              focusTabBtn
              attr.aria-label="{{ 'nav.help.screenreader' | translate : { fgbdefault: 'Click for help' } }}"
            >
              <span>{{ 'nav.help' | translate }}</span>
            </a>
            <ul ngbDropdownMenu class="shadow" (click)="closeDropDown(ddHelp)" #greedyDropdown>
              <li class="nav-item active">
                <a
                  class="nav-link mb-1 text-black"
                  [routerLink]="['/faq']"
                  routerLinkActive="router-link-active"
                  attr.aria-label="{{ 'nav.faqs.screenreader' | translate : { fgbdefault: 'Move FAQs page' } }}"
                  >{{ 'nav.faqs' | translate }}</a
                >
              </li>
              <li class="nav-item active">
                <a
                  class="nav-link mb-1 text-black"
                  [routerLink]="['/contact']"
                  routerLinkActive="router-link-active"
                  attr.aria-label="{{ 'nav.contact.screenreader' | translate : { fgbdefault: 'Move contact us page' } }}"
                  >{{ 'nav.contact' | translate }}</a
                >
              </li>
              <li class="nav-item active">
                <a
                  class="nav-link mb-1 text-black"
                  [routerLink]="['/terms']"
                  routerLinkActive="router-link-active"
                  attr.aria-label="{{ 'nav.terms.screenreader' | translate : { fgbdefault: 'Move terms and conditions page' } }}"
                  >{{ 'nav.terms' | translate }}</a
                >
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
    <!-- Right Side Content -->
    <div class="d-flex align-items-center flex-nowrap my-2 my-lg-0">
      <!-- <div *inPopulation="[populations.GuardianTest]">
        <div *inProductCode="[productcode.PremiumDigitalMember]">
          <fgb-back-to-guardian></fgb-back-to-guardian>
        </div>
      </div> -->
      <div class="balance font-size-14">
        <div class="d-flex align-items-center" *ngIf="loyaltyPointsCard$">
          <span
            class="icon material-icons currency-icons pe-1"
            role="img"
            attr.aria-label="{{ 'nav.points.screenreader' | translate }}"
            >emoji_events</span
          >
          <span class="fw-bold">{{ loyaltyPointsCard$ | async | FortressCurrencyPipe : 'PTS' }}</span>
        </div>
      </div>
      <!--Notifications-->

      <ng-container *notInPopulation="[populations.NonMember]">
        <div class="divider"></div>
        <div class="px-4">
          <fgb-notification-button></fgb-notification-button>
        </div>
      </ng-container>
      <div class="divider"></div>

      <!--New Dropdown (Needs Manage Tickets update before being looked at again)-->
      <div class="member px-3" ngbDropdown #ddAccount="ngbDropdown" [autoClose]="'outside'" focusTab>
        <button
          class="nav-link btn d-flex align-items-center mx-2 px-0 justify-content-end text-white"
          id="namedropdown"
          ngbDropdownToggle
          attr.aria-label="{{ 'nav.account.dropdown.screenreader' | translate : { fgbdefault: 'Click for more account menu' } }}"
          tabindex="-1"
        >
          <div *ngIf="memberDetails$ | async as memberDetails; else memberDetailsLoading" class="user-box h-100 d-inline-block">
            <div class="font-size-14 text-start">{{ memberDetails!.FirstName }}</div>
          </div>
        </button>

        <div
          ngbDropdownMenu
          (click)="closeDropDown(ddAccount)"
          aria-labelledby="namedropdown"
          class="shadow text-center border-0 dropdown-menu p-0 account-dropdown mt-2"
        >
          <div class="text-info">
            <a
              [routerLink]="['/activity']"
              routerLinkActive="router-link-active"
              attr.aria-label="{{ 'nav.activity.screenreader' | translate : { fgbdefault: 'Move to activity page' } }}"
              class="d-flex align-items-center account-btn text-black bg-white py-0 px-2 nav-item-fixed-height"
              ngbDropdownItem
            >
              <span class="icon material-icons material-dropdown-icon me-2" aria-hidden="true">history</span>
              <div class="dropdown-link mt-1 text-start">
                <div class="font-size-14 fw-bold">{{ 'nav.activity' | translate }}</div>
              </div>
            </a>
          </div>

          <hr class="text-muted w-90 ms-auto me-auto mt-0 mb-0" />

          <ng-container *ngIf="cards$ | async as cards">
            <div *ngIf="cards.length > 1">
              <div class="text-secondary ms-3">
                <div class="d-flex align-items-center account-btn bg-white py-0 px-2 nav-item-fixed-height" ngbDropdownItem>
                  <div class="icon icon-user-male-pictures me-2 font-size-16 text-primary"></div>
                  <div class="dropdown-link mt-1 text-start">
                    <div class="font-size-14 text-black fw-bold">{{ 'switch.account.dropdown.text' | translate }}</div>
                  </div>
                </div>
              </div>
              <div class="select-account-container">
                <ng-container *ngFor="let card of cards">
                  <hr class="text-muted w-75 my-0" />
                  <div class="text-secondary">
                    <button
                      (click)="loginWithCard(card.MemberId)"
                      class="d-flex align-items-center account-btn text-secondary bg-white py-0 px-2 nav-item-fixed-height switch-accounts"
                      ngbDropdownItem
                      attr.aria-label="{{ 'nav.account.expand.login.screenreader' | translate }}"
                    >
                      <div class="ten-game-club" [ngClass]="{ 'border-0': card.MemberId == selectedMember }">
                        <span class="icon icon-check" *ngIf="card.MemberId == selectedMember"></span>
                      </div>
                      <div class="dropdown-link mt-1 text-start">
                        <div class="font-size-14">{{ card.Name }}</div>
                        <div class="font-size-12 text-info">{{ 'member.id.card.text' | translate }} {{ card.MemberId }}</div>
                      </div>
                    </button>
                  </div>
                </ng-container>
              </div>
              <hr class="text-muted w-90 my-0" />
            </div>
          </ng-container>

          <div class="text-black">
            <a
              [routerLink]="['/rewards/wallet']"
              routerLinkActive="router-link-active"
              attr.aria-label="{{ 'nav.wallet.screenreader' | translate : { fgbdefault: 'Move to wallet page' } }}"
              class="d-flex align-items-center account-btn text-black bg-white py-0 px-2 nav-item-fixed-height"
              ngbDropdownItem
            >
              <span class="icon material-icons material-dropdown-icon me-2" aria-hidden="true">account_balance_wallet</span>
              <div class="dropdown-link mt-1 text-start">
                <div class="font-size-14 fw-bold">{{ 'nav.wallet' | translate }}</div>
              </div>
            </a>
          </div>
          <hr class="text-muted w-90 ms-auto me-auto mt-0 mb-0" />

          <div>
            <button
              class="logout-btn d-flex align-items-center bg-white px-2 nav-item-fixed-height rounded-bottom"
              (click)="logout()"
              ngbDropdownItem
              attr.aria-label="{{ 'nav.log.out.screenreader' | translate : { fgbdefault: 'Log out' } }}"
            >
              <span class="icon material-icons material-dropdown-icon me-2 text-danger" aria-hidden="true"
                >power_settings_new</span
              >
              <div class="dropdown-link text-start">
                <div class="text-danger py-1 font-size-14 fw-bold" ngbDropdownItem (click)="logout()">
                  {{ 'nav.log.out' | translate : { fgbdefault: 'Log out' } }}
                </div>
              </div>
            </button>
          </div>
        </div>
      </div>
      <fgbcl-language-button-select [version]="2" />
    </div>
  </div>
</nav>

<ng-template #memberDetailsLoading>
  <div class="user-box d-block text-center text-on-primary px-4">
    <fgbcl-loading [size]="'small'"></fgbcl-loading>
  </div>
</ng-template>
