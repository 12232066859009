import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BaseTier } from '../tier.base';
import { FGBSharedModule } from '@fgb/portal-component-library/src/lib/shared';
import { PipesModule } from '@fgb/portal-component-library/src/lib/pipes';
import { CommonModule, DecimalPipe } from '@angular/common';
import { QRCodeModule } from 'angularx-qrcode';
import { UserProfilePicComponent } from 'src/app/modules/user/components/user-profile-pic/user-profile-pic.component';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'fgb-tier-v2',
  standalone: true,
  imports: [FGBSharedModule, PipesModule, DecimalPipe, CommonModule, QRCodeModule, UserProfilePicComponent, RouterModule],
  templateUrl: './tier-v2.component.html',
  styleUrl: './tier-v2.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TierV2Component extends BaseTier{

  
}
