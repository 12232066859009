<div
  ngbDropdown
  class="notification-button"
  [class.open]="isOpen"
  (openChange)="toggleNotificationCenter($event)"
  #notificationDropdown="ngbDropdown"
>
  <button
    class="notification-btn btn px-0"
    id="notificationDropdown"
    attr.aria-label="{{ 'nav.notification.screenreader' | translate }}"
    tabindex="-1"
    ngbDropdownToggle
  >
    <span class="icon notification-bell material-icons material-mobile-nav" aria-hidden="true" [style.font-size]="size + 'px'"
      >notifications
    </span>
    <span class="notification-badge" *ngIf="unseenNotificationCount$ | async as unseeCount">
      <ng-container *ngIf="unseenCount > 0">{{ unseenCount }}</ng-container>
    </span>
  </button>

  <div ngbDropdownMenu aria-labelledby="notificationDropdown" class="notification-dropdown shadow dropdown-menu border-0 p-0">
    <div class="notification-header text-primary d-flex ps-3 fw-bold">
      <div class="notification-title">
        {{ 'navbar.notifications.title' | translate : { fgbdefault: 'Notifications' } }}
      </div>
      <i class="material-icons material-icons-outlined close-btn" (click)="notificationDropdown.close()">cancel</i>
    </div>
    <hr class="mt-1 mx-3" />
    <fgbcl-notification-hub></fgbcl-notification-hub>
  </div>
  <div class="notification-mobile-backdrop"></div>
</div>
