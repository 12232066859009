import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { FGBMissingTranslationHandler, FGBTranslationLoader } from '@fgb/core';
import { HttpClient } from '@microsoft/signalr';
import {FilterModalComponent} from './filter-modal.component';

@NgModule({
  declarations: [FilterModalComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: FGBTranslationLoader,
        deps: [HttpClient],
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: FGBMissingTranslationHandler,
      },
    }),
  ],
  exports: [FilterModalComponent],
})
export class FilterModalModule {}
