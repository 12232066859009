import { Component, OnInit } from '@angular/core';
import {
  config,
  AnnouncementService,
  MemberQuery,
  MemberDetails,
} from '@fgb/core';
import { Observable, firstValueFrom } from 'rxjs';
import { Populations } from 'src/app/shared/utilities/populations';

@Component({
  selector: 'fgb-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent implements OnInit {
  config = config;
  member$: Observable<MemberDetails | undefined>;
  populations = Populations;

  constructor(
    private announcementService: AnnouncementService,
    private memberQuery: MemberQuery
  ) {}

  ngOnInit() {
    firstValueFrom(this.announcementService.fetchAnnouncements());
    this.member$ = this.memberQuery.selectMemberDetails();
  }

}
