import { Component, Input, OnInit } from '@angular/core';
import { NotificationCenterQuery, NotificationCenterService } from '@fgb/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'fgb-notification-button',
  templateUrl: './notification-button.component.html',
  styleUrls: ['./notification-button.component.scss']
})
export class NotificationButtonComponent implements OnInit {
  @Input() size: number = 22;
  unseenNotificationCount$: Observable<number>;
  isOpen: boolean;

  constructor(
    private notificationService: NotificationCenterService,
    private notificationQuery: NotificationCenterQuery) { }

  ngOnInit(): void {
    this.unseenNotificationCount$ = this.notificationQuery.unseenNotificationsCount$;
  }

  toggleNotificationCenter(open: boolean) {
    this.isOpen = open;
    this.notificationService.updateIsOpen(open);
  }

}
