<div class="banner" role="banner">
  <h1 class="text-uppercase">{{ 'games.page.pick.player.title' | translate }}</h1>
</div>

<fgb-game-nav></fgb-game-nav>

<div class="container d-flex d-lg-none mb-3">
  <div class="pick-a-player-message card">
    <p class="m-0 font-size-14 fw-bold">{{ 'fantasy.games.page.message' | translate }}</p>
  </div>
</div>

<div class="container">
  <ng-container
    *ngIf="
      {
        pendingGames: pendingGames$ | async,
        currentGames: currentGames$ | async
      } as games;
      else noGames
    "
  >
    <ng-container *ngIf="!!games.currentGames?.length || !!games.pendingGames?.length; else noGames">
      <ng-container *ngIf="games.currentGames && !!games.currentGames.length">
        <div class="row">
          <div class="col-lg-6 col-12">
            <owl-carousel-o class="d-block d-lg-none" [options]="customOptions2">
              <ng-container *ngFor="let game of games.currentGames">
                <ng-template carouselSlide [width]="322">
                  <fgb-fantasy-game-panel
                    [game]="game"
                    (button)="goToDetail(game.GameId)"
                    [buttonState]="hasActivePlayer(game) ? 'player-link' : 'no-player-link'"
                  ></fgb-fantasy-game-panel>
                </ng-template>
              </ng-container>
            </owl-carousel-o>
            <fgb-fantasy-game-panel
              class="d-none d-lg-block"
              [game]="games.currentGames[0]"
              (button)="goToDetail(games.currentGames[0].GameId)"
              [buttonState]="hasActivePlayer(games.currentGames[0]) ? 'player-link' : 'no-player-link'"
            ></fgb-fantasy-game-panel>
          </div>
          <div class="col-lg-6 col-12 d-lg-block d-none">
            <div *ngIf="games.currentGames[1]">
              <fgb-fantasy-game-panel
                [game]="games.currentGames[1]"
                (button)="goToDetail(games.currentGames[1].GameId)"
                [buttonState]="hasActivePlayer(games.currentGames[1]) ? 'player-link' : 'no-player-link'"
              ></fgb-fantasy-game-panel>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>

  <div class="row">
    <div class="col-lg-6 col-12">
      <h5 class="font-heading mt-lg-4 mt-3 mb-2 text-uppercase">{{ 'pick.player.history.title' | translate }}</h5>
      <fgb-fantasy-history-list> </fgb-fantasy-history-list>
    </div>

    <div class="col-lg-6 col-12">
      <h5 class="font-heading mt-4 mb-2 text-uppercase">{{ 'pick.player.leaderboard.title' | translate }}</h5>
      <div class="card leaderboard-card rounded-4 border">
        <div class="nav d-flex align-items-center justify-content-center">
          <a
            class="nav-item w-25 text-center leaderboard-nav-item"
            [ngClass]="{ selected: showMonthly }"
            (click)="showMonthly = true"
          >
            <label class="font-size-14 font-default fw-bold text-center">{{
              'pick.player.leaderboard.monthly' | translate
            }}</label>
          </a>
          <a
            class="nav-item w-25 text-center leaderboard-nav-item"
            [ngClass]="{ selected: !showMonthly }"
            (click)="showMonthly = false"
          >
            <label class="font-size-14 font-default fw-bold text-center">{{
              'pick.player.leaderboard.yearly' | translate
            }}</label>
          </a>
        </div>
        <div class="leaderboard-nav" *ngIf="showMonthly; else yearly">
          <owl-carousel-o [options]="customOptions">
            <ng-template carouselSlide *ngFor="let item of monthlyLeaderboard">
              <div class="py-1 leaderboard-date-toggle">
                <h2 class="text-center font-default fw-bold font-size-14 m-0 text-uppercase">{{ item.month }}</h2>
              </div>
              <fgb-fantasy-leaderboard [fantasyEntries]="item.entries"></fgb-fantasy-leaderboard>
            </ng-template>
          </owl-carousel-o>
        </div>
        <ng-template #yearly>
          <div class="py-1 leaderboard-date-toggle mt-1">
            <h2 class="text-center font-default fw-bold font-size-14 m-0 text-uppercase">{{ year }}</h2>
          </div>
          <fgb-fantasy-leaderboard [fantasyEntries]="yearlyLeaderboards"></fgb-fantasy-leaderboard>
        </ng-template>
        <div class="leaderboard-button text-center w-100">
          <a class="fw-bold text-info font-size-14 leaderboard-colour" [routerLink]="['/fantasyleaderboard']">{{
            'pick.player.view.full.leaderboard' | translate
          }}</a>
        </div>
      </div>
    </div>
  </div>

  <!--Placeholder Information-->
  <ng-template #noGames>
    <div class="container">
      <div class="no-items mx-0 w-100 mt-4 no-results-placeholder-div">
        <div class="no-results-placeholder">{{ 'pick.player.placeholder.title' | translate }}</div>
      </div>
    </div>
  </ng-template>

  <!-- Rules -->
  <h5 class="text-start mt-4 text-uppercase font-heading pick-player-rules">{{ 'pick.player.rules.title' | translate }}</h5>

  <div class="col-12 games-rules-container mt-lg-2">
    <div class="row">
      <div ngbAccordion #a="ngbAccordion" [destroyOnHide]="false" class="mt-3 w-100 mt-lg-0">
        <div ngbAccordionItem id="ngb-panel-0">
          <div ngbPanelHeader>
            <h2 ngbAccordionHeader class="mt-0">
              <button ngbAccordionButton>{{ 'pick.player.rules1.title' | translate }}</button>
            </h2>
          </div>
          <div ngbAccordionCollapse>
            <div ngbAccordionBody>
              <div class="row py-0">
                <div class="content col-12">
                  <div class="fantasy">
                    <div
                      class="font-size-14 fw-normal"
                      [innerHTML]="'pick.player.rules1.description' | translate | markdownTranslate"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div ngbAccordionItem id="ngb-panel-1">
          <div ngbPanelHeader>
            <h2 ngbAccordionHeader class="mt-0">
              <button ngbAccordionButton>{{ 'pick.player.rules2.title' | translate }}</button>
            </h2>
          </div>
          <div ngbAccordionCollapse>
            <div ngbAccordionBody>
              <div class="row">
                <div class="content col-12">
                  <div class="fantasy">
                    <div
                      class="font-size-14 fw-normal"
                      [innerHTML]="'pick.player.rules2.description' | translate | markdownTranslate"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div ngbAccordionItem id="ngb-panel-2">
          <div ngbPanelHeader>
            <h2 ngbAccordionHeader class="mt-0">
              <button ngbAccordionButton>{{ 'pick.player.rules3.title' | translate }}</button>
            </h2>
          </div>
          <div ngbAccordionCollapse>
            <div ngbAccordionBody>
              <div class="row">
                <div class="content col-12">
                  <div class="fantasy">
                    <div
                      class="font-size-14 fw-normal"
                      [innerHTML]="'pick.player.rules3.description' | translate | markdownTranslate"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
