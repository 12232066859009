<div class="number-increment">
  <div class="arrow-container mb-2" *ngIf="!control?.disabled">
    <!-- Increment Button -->
    <label id="label-up{{ accessibilityLabel ? '-' + accessibilityLabel : '' }}" class="accessibility-only">{{
      (wscPrefix ? wscPrefix + '.up.screenreader' : 'up.screenreader') | translate
    }}</label>
    <a
      class="arrow up"
      role="button"
      aria-labelledby="label-up{{ accessibilityLabel ? '-' + accessibilityLabel : '' }}"
      [class.disabled]="innerValue === maxLimit || control?.disabled"
      (click)="increment()"
    ></a>
  </div>
  <input id="numberInput" type="text" [(ngModel)]="innerValue" #input [disabled]="control?.disabled" autocomplete="off" />
  <div class="arrow-container mt-2" *ngIf="!control?.disabled">
    <!-- Decrement Button -->
    <label id="label-down{{ accessibilityLabel ? '-' + accessibilityLabel : '' }}" class="accessibility-only">{{
      (wscPrefix ? wscPrefix + '.down.screenreader' : 'down.screenreader') | translate
    }}</label>
    <a
      class="arrow down"
      role="button"
      aria-labelledby="label-down{{ accessibilityLabel ? '-' + accessibilityLabel : '' }}"
      [class.disabled]="innerValue === 0 || control?.disabled"
      (click)="decrement()"
    ></a>
  </div>
</div>
