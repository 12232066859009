import { EnvironmentProviders, makeEnvironmentProviders } from "@angular/core";
import { TMAuthService, TMOauthService, tmAuthConfig, tmOAuthConfig } from "@fgb/core";

export interface TmConfig{
    oauthConfig: tmOAuthConfig;
    loginAuthConfig: tmAuthConfig;
}

export function provideTmConfig(config: TmConfig): EnvironmentProviders{
    return makeEnvironmentProviders([
        TMOauthService,
        TMAuthService,
        {
            provide: tmOAuthConfig,
            useValue: config.oauthConfig,
        },
        {
            provide: tmAuthConfig,
            useValue: config.loginAuthConfig,
        },
    ])
}