import { Component, OnInit, Input } from '@angular/core';
import { Barcode, BarcodeQuery, MemberCardQuery, SeatingDetailQuery, SeatingDetail } from '@fgb/core';
import { BarcodeService } from '@fgb/core';
import { Observable, firstValueFrom } from 'rxjs';
import { MemberCard } from '@fgb/core';

@Component({
  selector: 'fgb-barcode',
  templateUrl: './barcode.component.html',
  styleUrls: ['./barcode.component.scss'],
})
export class BarcodeComponent implements OnInit {
  @Input() primaryTheme: boolean = false;
  barcode$: Observable<Barcode>;
  card$: Observable<MemberCard | undefined>;
  seatingDetail$: Observable<SeatingDetail | undefined>;

  constructor(
    private barcodeService: BarcodeService,
    private barcodeQuery: BarcodeQuery,
    private memberCardQuery: MemberCardQuery,
    private seatingDetailQuery: SeatingDetailQuery
  ) {}

  ngOnInit() {
    this.seatingDetail$ = this.seatingDetailQuery.selectCurrentSeatingDetails();
    firstValueFrom(this.barcodeService.fetchBarcode());
    this.barcode$ = this.barcodeQuery.select();
    this.card$ = this.memberCardQuery.selectCurrentCard();
  }
}
