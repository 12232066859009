import { Component, OnInit } from '@angular/core';
import { PartnerPendingQuery, PartnerPendingService, PartnerPending } from '@fgb/core';
import { Observable, firstValueFrom } from 'rxjs';

@Component({
  selector: 'fgb-partner-pending-list',
  templateUrl: './partner-pending-list.component.html',
  styleUrls: ['./partner-pending-list.component.scss'],
})
export class PartnerPendingListComponent implements OnInit {
  constructor(private pendingQuery: PartnerPendingQuery, private pendingService: PartnerPendingService) {}

  pendingPartners$: Observable<PartnerPending[]>;

  ngOnInit() {
    this.pendingPartners$ = this.pendingQuery.selectAll();
  }

  delete(ticketKey: string) {
    firstValueFrom(this.pendingService.remove(ticketKey));
  }
}
