<h2 class="accessibility-only">Code Redemption</h2>
<div class="card">
  <div class="card-body">
    <div class="d-flex flex-lg-row flex-column align-items-center justify-content-center">
      <h4 class="card-heading fw-bold text-uppercase border-0 p-0 text-center me-lg-3 me-0 mb-lg-0 mb-1">
        {{ 'voucher.title' | translate }}
      </h4>
      <div class="input-form-field d-flex align-items-center justify-content-center flex-column">
        <div class="d-flex align-items-center">
          <input
            type="text"
            [(ngModel)]="voucherCode"
            [placeholder]="'voucher.placeholder' | translate"
            class="form-control text-center border-0 code-input"
            (keyup.enter)="redeemVoucher()"
          />
          <button
            class="btn btn-primary voucher-submit text-white border-0 p-0 d-flex align-items-center justify-content-center"
            (click)="redeemVoucher()"
            [disabled]="disableButton"
          >
            <span class="icon material-icons">chevron_right</span>
          </button>
        </div>
        <div class="result-box">
          <div class="result mt-0">
            <small class="text-success font-size-12" *ngIf="redemptionResult">
              {{ redemptionResult }}
            </small>
            <small class="text-danger font-size-12" *ngIf="errorMessage">
              {{ errorMessage }}
            </small>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
