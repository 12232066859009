import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UserProfilePicService } from 'src/app/modules/user/components/user-profile-pic/user-profile-pic.service';

@Component({
  selector: 'fgb-user-profile-pic',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './user-profile-pic.component.html',
  styleUrl: './user-profile-pic.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserProfilePicComponent implements OnInit{
  private profilePicService: UserProfilePicService = inject(UserProfilePicService);
  private destroyRef = inject(DestroyRef);
  private defaultImagePath = 'assets/images/icons/account_circle.svg';
  @ViewChild('image', { static: true }) imageContainer: any;

  ngOnInit(): void {
    this.profilePicService.profilePic$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: (data) => {
        this.createImageFromBlob(data);
      },
      error: (error) => {
        // If there is an error, we will show the default image
        this.imageContainer.nativeElement.src = this.defaultImagePath;
      }
    });
  }

  createImageFromBlob(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
       this.imageContainer.nativeElement.src = reader.result;
    }, false);
 
    if (image) {
       reader.readAsDataURL(image);
    }
 }

 onError(event: any){
  event.target.src = this.defaultImagePath;
 }

}
