<div class="banner d-lg-flex d-none" role="banner">
  <h1 class="text-uppercase">{{ 'games.page.pick.player.title' | translate }}</h1>
</div>

<fgb-game-nav class="d-lg-block d-none"></fgb-game-nav>

<div class="container">
  <div class="leaderboard-back-btn fw-bold d-flex d-lg-none my-2">
    <a
      [routerLink]="[backButtonLink]"
      class="btn back-btn d-flex align-items-center justify-content-center py-lg-2 p-0 pt-1 fw-bold"
    >
      <span class="icon material-icons" aria-hidden="true">chevron_left</span>
      <div class="d-flex align-items-center justify-content-start rounded w-100 ms-1">
        <div class="font-size-14 fw-bold">{{ 'pick.player.back.button' | translate }}</div>
      </div>
    </a>
  </div>
  <div class="card leaderboard-card mt-lg-3 rounded-4 border">
    <div class="nav d-flex align-items-center justify-content-center">
      <a
        class="nav-item w-25 text-center leaderboard-nav-item"
        [ngClass]="{ selected: showMonthly }"
        (click)="showMonthly = true"
      >
        <label class="font-size-14 font-default fw-bold text-center">{{ 'pick.player.leaderboard.monthly' | translate }}</label>
      </a>
      <a
        class="nav-item w-25 text-center leaderboard-nav-item"
        [ngClass]="{ selected: !showMonthly }"
        (click)="showMonthly = false"
      >
        <label class="font-size-14 font-default fw-bold text-center">{{ 'pick.player.leaderboard.yearly' | translate }}</label>
      </a>
    </div>
    <div class="leaderboard-nav" *ngIf="showMonthly; else yearly">
      <owl-carousel-o [options]="customOptions" #monthCarousel>
        <ng-template carouselSlide *ngFor="let item of monthlyLeaderboard; let i = index" [id]="i.toString()">
          <div class="py-1 leaderboard-date-toggle">
            <h2 class="text-center text-black font-default fw-bold font-size-14 m-0 month-position leaderboard-colour">
              {{ item.month }}
            </h2>
          </div>
          <fgb-fantasy-leaderboard [fantasyEntries]="item.entries"></fgb-fantasy-leaderboard>
        </ng-template>
      </owl-carousel-o>
    </div>
    <ng-template #yearly>
      <div class="py-1 yearly-header leaderboard-date-toggle">
        <h2 class="text-center text-black font-default fw-bold font-size-14 m-0 month-position leaderboard-colour">{{ year }}</h2>
      </div>
      <fgb-fantasy-leaderboard [fantasyEntries]="yearlyLeaderboards"></fgb-fantasy-leaderboard>
    </ng-template>
  </div>
</div>
