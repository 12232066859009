<div class="my-4">
  <div class="survey-answer">
    <div class="survey-answer-container justify-content-center">
      <div class="survey-answer-item survey-predictor-answer options-box-border" *ngFor="let answer of selectedAnswers">
        <ng-container [ngSwitch]="displayType">
          <ng-container *ngSwitchCase="answerDisplayType.ImageAndText">
            <div>
              <div class="image-container">
                <img src="{{ answer.ImageURL | contentImage : '7' }}" alt="{{ answer.ImageURL }}" />
              </div>
              <div class="predictor-answer-text text-center bg-tertiary text-white">
                <span>{{ answer.Title }}</span>
              </div>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="answerDisplayType.Text">
            <div class="d-flex flex-row">
              <div class="answer-text-landscape text-center text-white bg-secondary">
                <span>{{ answer.Title }}</span>
              </div>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="answerDisplayType.Image">
            <div class="d-flex flex-row">
              <img class="image-container" src="{{ answer.ImageURL | contentImage : '7' }}" alt="{{ answer.ImageURL }}" />
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="text-center font-default fw-bold font-size-14 text-primary mt-4">
    {{ 'predictor.completed.pending.thank.you' | translate }}
  </div>
  <div class="text-center font-size-14 mb-lg-5 mb-3 mt-2 px-lg-5 px-4">
    {{ 'predictor.completed.pending.message' | translate }}
  </div>
</div>
