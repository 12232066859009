import { Component, OnInit } from '@angular/core';
import { FGBSharedModule } from '@fgb/portal-component-library/src/lib/shared';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'fgb-member-upgrade-card',
  templateUrl: './member-upgrade-card.component.html',
  styleUrls: ['./member-upgrade-card.component.scss'],
  standalone: true,
  imports: [ FGBSharedModule]
})
export class MemberUpgradeCardComponent implements OnInit {
  upgradeLink: string = '';

  constructor(private translate: TranslateService) { }

  ngOnInit(): void {
    this._getUpgradeLink();
  }

  private _getUpgradeLink(): void {
    this.upgradeLink = this.translate.instant('home.page.upgrade.link');
  }
}
