import { InjectionToken, inject } from "@angular/core";
import { MemberQuery } from "@fgb/core";

export type ColorMode = 'Light' | 'Dark';

/**
 * COLOR_MODE Injection Token for setting the color mode between Light and Dark
 */
export const COLOR_MODE = new InjectionToken<ColorMode>('colorMode', {
    providedIn: 'root',
    factory: () => {
        const memberQuery: MemberQuery = inject(MemberQuery);
        return memberQuery.getUserContext()?.clientAttribute as ColorMode || 'Light';
    }
});