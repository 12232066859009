<div class="banner d-none d-lg-flex" role="banner">
  <h1 class="text-uppercase">{{ 'marketplace.page.title' | translate }}</h1>
</div>

<div class="container tier-container" *ifScreenSize="screenType.Mobile">
  <fgb-tier></fgb-tier>
</div>

<ng-container *notInPopulation="[populations.NonMember]">
  <fgb-mixed-loyalty-nav></fgb-mixed-loyalty-nav>
</ng-container>

<ng-container *inPopulation="[populations.NonMember]">
  <div class="d-none d-lg-block">
    <fgb-mixed-loyalty-nav></fgb-mixed-loyalty-nav>
  </div>
  <div class="container upgrade-card-container">
    <fgb-member-upgrade-card></fgb-member-upgrade-card>
  </div>
</ng-container>

<div class="container">
  <fgbcl-marketplace-list
    [isList]="true"
    [marketplaceItemView]="true"
    [marketplaceItemTypes]="[0, 1, 2]"
    [defaultSort]="0"
  ></fgbcl-marketplace-list>
</div>
