<div class="banner d-lg-flex d-none">
  <h1 class="text-uppercase">{{ 'lotto.page.title' | translate }}</h1>
</div>

<div class="container tier-container" *ifScreenSize="screenType.Mobile">
  <fgb-tier></fgb-tier>
</div>

<fgb-mixed-loyalty-nav></fgb-mixed-loyalty-nav>

<div class="container">
  <fgbcl-lottos-list [isList]="true" [defaultSort]="0"></fgbcl-lottos-list>
</div>
