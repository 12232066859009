<div class="banner d-flex" role="banner">
  <h1 class="text-uppercase">{{ 'badges.page.title' | translate }}</h1>
</div>

<!--Scorecard carousel-->
<fgb-challenges-nav></fgb-challenges-nav>

<div class="container mt-4">
  <fgbcl-badge-overview-list [phaseThree]="true" [showPlaceholder]="true"></fgbcl-badge-overview-list>
</div>
