import { Component } from '@angular/core';

@Component({
  selector: 'fgb-challenges-nav',
  templateUrl: './challenges-nav.component.html',
  styleUrl: './challenges-nav.component.scss'
})
export class ChallengesNavComponent {

}
