import { HttpErrorResponse } from "@angular/common/http";

export enum FGBErrorType{
    HTTP,
    CLIENT
};

export abstract class FGBError{
    code: number | null;
    message: string;
    translationKey: string;
    abstract type: FGBErrorType;

    constructor(o?: Partial<FGBError | any>){
        o = o || {};
        this.code = o.code || null;
        this.message = o.message || '';
        this.translationKey = o.translationKey || '';
    }
}

export class FGBHttpError extends FGBError{
    type: FGBErrorType = FGBErrorType.HTTP;

    constructor(o?: HttpErrorResponse){
        super(o);
        this.getError(o);
    }

    private getError(response: HttpErrorResponse | any){
        if( response.error instanceof ErrorEvent){
            this.message = response.error.message;
        }else{
            switch(response.status){
                case 400:
                    this.code = 400;
                    this.message = 'Bad Request.';
                    this.translationKey = `error.${response.error}`;

                    if( response.error.Code ){
                        this.translationKey = `error.${response.error.Code}`
                    }
                    if( response.error.Message ){
                        this.message = response.error.Message;
                    }
                    break;
                case 401:
                    this.code = 401;
                    this.message = 'You are unauthorized to do this action.';
                    this.translationKey = `error.unauthorized`;
                    break;
                case 403:
                    this.code = 403;
                    this.message = "You don't have permission to access the requested resource.";
                    this.translationKey = `error.forbidden`;
                    break;
                case 404:
                    this.code = 404;
                    this.message = 'The requested resource does not exist.';
                    this.translationKey = `error.notFound`;
                    break;
                case 412:
                    this.code = 412;
                    this.message = 'Precondition Failed.';
                    this.translationKey = `error.preconditionFailed`;
                    break;
                case 500:
                    this.code = 500;
                    this.message = 'Internal Server Error.';
                    this.translationKey = `error.internalServerError`;
                    break;
                case 503:
                    this.code = 503;
                    this.message = 'The requested service is not available.';
                    this.translationKey = `error.serviceUnavailable`;
                    break;
                default:
                    this.code = response.status;
                    this.message = response.statusText;
                    this.translationKey = `error.${response.status}`;

                    if( response.error.Code ){
                        this.translationKey = `error.${response.error.Code}`
                    }
                    if( response.error.Message ){
                        this.message = response.error.Message;
                    }
            }
        }
    }
}