<div class="tier-v2 rounded card border-0 bg-dark-green text-white">
  <div class="profile-img-container d-none d-lg-flex">
    <fgb-user-profile-pic />
  </div>
  <!-- Barcode -->
  <div class="barcode-container d-lg-none d-flex">
    <div class="barcode-wrapper">
      <ng-container *ngIf="barcode()">
        <ng-container *ngIf="barcode().Barcode; else loadingRef">
          <qrcode
            [qrdata]="barcode().Barcode"
            [allowEmptyString]="true"
            [size]="256"
            [level]="'M'"
            [usesvg]="true"
            [margin]="5"
            cssClass="barcode-cls"
          ></qrcode>
        </ng-container>
      </ng-container>
    </div>
  </div>

  <div class="tier-info-container">
    <div class="tier-info">
      <div class="profile-img-container d-flex d-lg-none">
        <fgb-user-profile-pic />
      </div>
      <div class="tier-title ms-3 ms-lg-0">
        <h5 class="member-name mb-0">{{ barcode()?.PassNickName }}</h5>
        <span class="club-id font-size-12">{{ 'tier.club.id.label' | translate }} {{ member()?.ExternalRefNumber }}</span>
      </div>
      <div class="tier-img-container">
        <img [src]="currentTier()?.Image | contentImage : '7'" alt="" />
      </div>
    </div>
    <div class="d-flex d-lg-none mb-3">
      <ng-container *ngTemplateOutlet="points"></ng-container>
    </div>
    <div class="tier-progress-container">
      <div class="tier-label-container font-size-12">
        <label class="fw-normal"
          >{{ 'tier.points.label' | translate }} <span class="current-points">{{ (currentTier()?.MaximumTierPoints + (lastTier()?.TierId == currentTier()?.TierId ? 0 : 1)) - tier()?.PointsToNextTier }}</span
          >/ {{ currentTier()?.MaximumTierPoints + (lastTier()?.TierId == currentTier()?.TierId ? 0 : 1)}}</label
        >
        
        <div *ngIf="lastTier()?.TierId > currentTier()?.TierId; then showNextTier else showPosition"></div>
        <ng-template #showNextTier><label>{{ nextTier()?.Name }}</label></ng-template>
        <ng-template #showPosition><label>#{{ tier()?.CurrentTierPosition }} / {{ tier()?.CurrentTierMemberCount }}</label></ng-template>

      </div>
      <div class="tier-progress">
        <div class="tier-progress-bar" [style.width.%]="progress()"></div>
        <span class="tier-point" [style.left.%]="progress()"></span>
      </div>
    </div>
  </div>
  <div class="points-container">
    <div class="d-none d-lg-flex">
      <ng-container *ngTemplateOutlet="points"></ng-container>
    </div>
    <div class="action-wrapper">
      <a class="tier-button" routerLink="/tiers">
        <span class="tier-label">{{ 'tier.navigate.button' | translate }}</span
        ><i class="material-icons">east</i></a
      >
    </div>
  </div>
</div>

<ng-template #points>
  <div class="points-wrapper bg-primary">
    <div class="points-title">
      <label class="point-title mb-0">{{ 'tier.points.title' | translate }}</label>
      <span class="point-sub-title font-size-10">{{ 'tier.points.subtitle' | translate }}</span>
    </div>
    <div class="points-value">
      <h3 class="mb-0">{{ balance | number : '1.0-2' }}</h3>
    </div>
  </div>
</ng-template>

<!-- Loading template -->
<ng-template #loadingRef>
  <div class="loading-container">
    <fgbcl-loading></fgbcl-loading>
  </div>
</ng-template>
