<div class="banner" role="banner">
  <h1 class="text-uppercase">{{ 'earn.page.title' | translate }}</h1>
</div>

<div class="container mt-2">
  <fgbcl-earn-points [svgColor]="'primary'"></fgbcl-earn-points>
  <div class="mt-2">
    <p class="earn-page-message font-size-14 fw-bold">{{ 'earn.page.message' | translate }}</p>
  </div>
</div>
