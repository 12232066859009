<div class="background">
  <div *ngIf="orderDetails$ | async as orderDetails" class="container d-flex h-100 p-3">
    <div class="card d-flex flex-row mx-auto my-lg-auto px-2 px-lg-0 shadow">
      <div class="col-lg-5 d-none d-lg-block login-banner shadow"></div>
      <div class="col-lg-7 d-flex flex-column justify-content-between personal-details-panel px-lg-5 h-100">
        <div class="d-flex flex-column">
          <ng-container *ngTemplateOutlet="logo"></ng-container>
          <h2 class="text-lg-left text-center text-success mb-4 mt-3 my-lg-4">{{ 'purchase.success.title' | translate }}</h2>
          <p class="font-size-14 mb-4">
            {{ 'purchase.success.description' | translate }}
            <span class="text-primary fw-bold">{{ 'purchase.success.description.email' | translate }}</span
            ><strong>{{ 'purchase.success.description.number' | translate }}</strong>
          </p>
          <div class="d-flex flex-row justify-content-end font-size-14 mt-2">
            <span class="fw-bold">{{ 'purchase.success.total.label' | translate }}</span
            >{{ orderDetails.OrderMerchantPrice / 100 | FortressCurrencyPipe }}
          </div>
        </div>
        <div class="w-100 d-flex justify-content-center">
          <button class="btn btn-primary w-100 my-lg-4 my-3 font-size-14 fw-bold" [routerLink]="['/']">
            {{ 'purchase.success.button.label' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<!--Use this to use a different logo for each population-->
<ng-template #logo>
  <div class="d-flex d-lg-none">
    <img alt="" class="logo mx-auto" src="assets/images/login-registration/fortress-login.svg" draggable="false" />
  </div>
</ng-template>
