<div class="survey-answer">
  <div class="text-center fw-bold text-white pb-3 font-size-14 d-none d-lg-block" *ngIf="pointsWon > 0">+{{ pointsWon }} Pts</div>
  <div class="survey-answer-container py-3 mx-lg-5">
    <div
      class="p-0 my-2 my-lg-3"
      [ngClass]="{
        'col-lg-6': survey.AnswerDisplayType !== answerDisplayType.Image,
        'col-lg-3': survey.AnswerDisplayType === answerDisplayType.Image,
        'col-12': survey.AnswerDisplayType !== answerDisplayType.Image,
        'col-6': survey.AnswerDisplayType === answerDisplayType.Image
      }"
      *ngFor="let answer of survey.SurveyAnswers"
    >
      <div class="survey-answer-item" [ngClass]="{ 'w-90': survey.AnswerDisplayType != answerDisplayType.Image }">
        <fgb-survey-item-answer-completed-poll-item
          [answer]="answer"
          [entryCount]="survey.SurveyEntryCount"
          [displayType]="survey.AnswerDisplayType"
        ></fgb-survey-item-answer-completed-poll-item>
      </div>
    </div>
  </div>
</div>
