<ng-container *ngIf="memberCard$ | async as memberCard; else loadingRef">
  <h2 class="accessibility-only">Member Card</h2>
  <div class="member-card" *ngIf="!dropDownMemberCard" [ngClass]="{ 'bg-primary text-white': isAccountCard }">
    <div class="d-flex flex-column align-items-lg-start align-items-center justify-content-between h-100">
      <!--Name and ID-->
      <div class="card-id text-lg-left">
        <p
          class="mb-0 card-name text-uppercase"
          [ngClass]="isAccountCard ? 'text-white' : 'text-primary'"
          *ngIf="memberDetails$ | async as memberDetails"
        >
          {{ memberDetails.FirstName }} {{ memberDetails.Surname }}
        </p>
        <div class="d-flex card-member-id">
          <div>{{ 'member.card.club.id.label' | translate }}&nbsp;</div>
          <div>{{ memberCard.ExternalRefNumber }}</div>
        </div>
      </div>
      <div *ifScreenSize="screenType.Mobile">
        <fgb-barcode></fgb-barcode>
      </div>
      <!--Balances-->

      <div class="d-flex mt-auto" *ngIf="tier$ | async as tier">
        <div class="card-points text-lg-left pe-4">
          <label class="font-size-14">{{ pointsBalance$ | async | number : '1.0-0' }}</label>
          <div class="font-size-12">{{ 'member.card.points.balance' | translate }}</div>
        </div>
        <div class="divider"></div>
        <div class="life-time-card-points text-lg-left ps-4" [ngClass]="isAccountCard ? 'border-white' : 'border-primary'">
          <label class="font-size-14">{{ tier.PointsToNextTier | number : '1.0-0' }}</label>
          <div class="font-size-12">{{ 'member.card.life.time.points.balance' | translate }}</div>
        </div>
      </div>
      <!-- <div
        class="member-card-bottom w-100 d-flex align-items-center justify-content-between text-white px-3 py-2"
        [ngClass]="{
          'bg-primary': memberCard.CardStatus === 0,
          'bg-frozen': memberCard.CardStatus === 1
        }"
      ></div> -->
    </div>
  </div>
  <div *ngIf="dropDownMemberCard">
    <div class="d-flex flex-column justify-content-between h-100">
      <!--Name and ID-->
      <div class="text-white card-id text-lg-left">
        <p class="mb-0 card-name card-name-dd text-uppercase" *ngIf="memberDetails$ | async as memberDetails">
          {{ memberDetails.FirstName }} {{ memberDetails.Surname }}
        </p>
        <div class="d-flex font-size-12">
          <div>{{ 'member.card.club.id.label' | translate }}&nbsp;</div>
          <div>{{ memberCard.ExternalRefNumber }}</div>
        </div>
      </div>
      <hr class="bg-white" />

      <!--Balances-->
      <div class="text-white d-flex tier-details" *ngIf="tier$ | async as tier">
        <div class="card-points text-lg-left pe-4">
          <label class="font-size-14">{{ pointsBalance$ | async | number : '1.0-0' }}</label>
          <div class="font-size-12">{{ 'member.card.points.balance' | translate }}</div>
        </div>
        <div class="divider"></div>
        <div class="life-time-card-points text-lg-left ps-4">
          <label class="font-size-14">{{ tier.PointsToNextTier | number : '1.0-0' }}</label>
          <div class="font-size-12">{{ 'member.card.life.time.points.balance' | translate }}</div>
        </div>
      </div>
      <hr class="bg-white" />
    </div>
  </div>
</ng-container>

<ng-template #loadingRef>
  <fgbcl-loading></fgbcl-loading>
</ng-template>
