import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'fgb-split-transaction-history',
  templateUrl: './split-transaction-history.component.html',
  styleUrls: ['./split-transaction-history.component.scss']
})
export class SplitTransactionHistoryComponent implements OnInit {

  transactionStartDate: Date = new Date();
  transactionEndDate: Date = new Date();

  ngOnInit(): void {
    this.transactionStartDate.setDate(new Date(this.transactionStartDate).getDate() - 1);
  }

}
