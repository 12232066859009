<ng-container *ngIf="fantasyHistory$ | async as fantasyHistory">
  <ng-container *ngIf="fantasyHistory.length > 0; else noPlayerHistory">
    <div class="card border rounded-4 px-2 mx-1">
      <ng-container *ngFor="let item of fantasyHistory">
        <fgb-fantasy-history-list-item [fantasyHistoryItem]="item"></fgb-fantasy-history-list-item>
      </ng-container>
    </div>
  </ng-container>
</ng-container>
<!--Placeholder Information-->
<ng-template #noPlayerHistory>
  <div class="container px-0">
    <div class="no-items mx-0 w-100 mt-1 no-results-placeholder-div">
      <div class="no-results-placeholder">{{ 'pick.player.history.placeholder.title' | translate }}</div>
    </div>
  </div>
</ng-template>
