<ng-template #accountDetails [appCtrlTemplate]="'fgb-edit-account-details'">
  <fgb-edit-account-details [autoEditMode]="true" (actionDone)="confirmAction$.next($event)"></fgb-edit-account-details>
</ng-template>

<ng-template #termsAndConditions [appCtrlTemplate]="'fgb-terms-and-conditions'">
  <fgb-terms-and-conditions [type]="termsAndConditionsType"></fgb-terms-and-conditions>
  <input type="checkbox" class="mx-2 my-3" [(ngModel)]="termsAccepted" /> <span>I Agree to the Terms and Conditions</span>
  <button
    class="popup-terms-confirm btn btn-success fw-bold w-100"
    [disabled]="!termsAccepted"
    (click)="confirmAction$.next($event)"
  >
    Confirm
  </button>
</ng-template>
