<div class="fantasy-panel border rounded-4 overflow-hidden">
  <div class="row mx-0">
    <div class="col-12 px-0 bg-primary" [ngClass]="{ 'col-lg-7': horizontal }">
      <div class="game-summary text-white h-100">
        <div class="d-none d-lg-flex flex-column justify-content-between h-100">
          <div class="text-center line-height-18">
            <p class="mb-2 font-size-12 text-white">{{ 'pick.player.time.left' | translate }}</p>
            <fgb-countdown class="font-size-14 font-default fw-bold" [endDateTime]="game.EndDate"></fgb-countdown>
          </div>
          <div class="row team-vs-team d-flex justify-content-center align-items-center mx-0" [ngClass]="{ 'my-4': horizontal }">
            <div class="team home col-4">
              <img
                *ngIf="game.HomeTeamLogo"
                class="d-flex ms-auto me-auto mt-3 mt-lg-0"
                src="{{ game.HomeTeamLogo | contentImage : '6' }}"
              />
            </div>
            <div class="versus col-4 text-center">
              <div class="mx-auto text-white versus-text">VS</div>
            </div>
            <div class="team away col-4">
              <img
                *ngIf="game.AwayTeamLogo"
                class="d-flex ms-auto me-auto mt-3 mt-lg-0"
                src="{{ game.AwayTeamLogo | contentImage : '6' }}"
              />
            </div>
          </div>
          <div class="line-height-18 font-size-12 text-center">
            <div class="date">{{ gameDate }}, {{ gameTime }}</div>
          </div>
        </div>
        <div class="d-block d-lg-none">
          <div class="row">
            <div class="col-4 line-height-18">
              <p class="mb-lg-0 mb-2 font-size-12 mt-lg-0 mt-1 text-white">{{ 'pick.player.time.left' | translate }}</p>
              <fgb-countdown class="font-size-14 font-default fw-bold" [endDateTime]="game.EndDate"></fgb-countdown>
            </div>
            <div class="col-8 d-flex flex-column">
              <div class="row team-vs-team d-flex justify-content-center align-items-center mx-0">
                <div class="team home col-4">
                  <img *ngIf="game.HomeTeamLogo" class="d-flex mx-auto" src="{{ game.HomeTeamLogo | contentImage : '6' }}" />
                </div>
                <div class="versus col-4 text-center">
                  <div class="mx-auto text-white versus-text">VS</div>
                </div>
                <div class="team away col-4">
                  <img *ngIf="game.AwayTeamLogo" class="d-flex mx-auto" src="{{ game.AwayTeamLogo | contentImage : '6' }}" />
                </div>
              </div>
              <div class="line-height-18 font-size-12 text-center">
                <div class="dates">{{ gameDate }}, {{ gameTime }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 py-2 px-3 fantasy-game-panel-right-div" [ngClass]="{ 'col-lg-5': horizontal }">
      <div class="d-none d-lg-block">
        <div class="player-select text-center">
          <div class="player-image mx-auto overflow-hidden">
            <img
              *ngIf="selectedPlayer && selectedPlayer.Image"
              src="{{ selectedPlayer.Image | contentImage : '6' }}"
              class="m-auto img rounded-circle"
            />
            <img
              *ngIf="!selectedPlayer || !selectedPlayer.Image"
              src="assets/images/placeholders/pick-player.png"
              class="m-auto img rounded-circle"
            />
          </div>

          <fgb-fantasy-player-select-confirm
            [isGamePending]="isGamePending"
            [game]="game"
            [buttonState]="buttonState"
            [selectedPlayer]="selectedPlayer"
            (button)="buttonClick()"
          >
          </fgb-fantasy-player-select-confirm>
        </div>
      </div>
      <div class="d-block d-lg-none">
        <div class="player-select text-center">
          <div class="row">
            <div class="col-3 p-0">
              <div class="player-image mx-auto overflow-hidden">
                <img
                  *ngIf="selectedPlayer && selectedPlayer.Image"
                  src="{{ selectedPlayer.Image | contentImage : '6' }}"
                  class="m-auto img rounded-circle"
                />
                <img
                  *ngIf="!selectedPlayer || !selectedPlayer.Image"
                  src="assets/images/placeholders/pick-player.png"
                  class="m-auto img rounded-circle"
                />
              </div>
            </div>
            <div class="col-9">
              <fgb-fantasy-player-select-confirm
                [isGamePending]="isGamePending"
                [game]="game"
                [buttonState]="buttonState"
                [selectedPlayer]="selectedPlayer"
                (button)="buttonClick()"
              >
              </fgb-fantasy-player-select-confirm>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
