<div class="container multiple-selection mt-4" *ngIf="!loading && transferAccounts; else loadingRef">
  <div class="multiple-account-panel text-center rounded bg-white d-block ms-auto me-auto">
    <div class="result-title text-secondary">Multiple Accounts</div>
    <p class="mb-4 font-size-16">
      Unfortunately we have found more than one account with the email<span class="text-primary">
        {{ transferAccounts.EmailAddress }}</span
      >
    </p>
    <p class="text-secondary text-center font-size-14">Please select one of the accounts below to send to.</p>
    <div *ngFor="let memberId of availableMemberIds">
      <a (click)="transferFundsToMemberId(memberId)" class="account-select d-block bg-disabled rounded text-start font-size-14"
        >Account Number {{ memberId }}</a
      >
    </div>
    <a
      class="btn funds-return rounded d-block ms-auto me-auto bg-secondary text-white font-size-14 fw-bold text-center border-0"
      [routerLink]="['/']"
    >
      Back to Funds
    </a>
  </div>
</div>

<ng-template #loadingRef>
  <fgbcl-loading></fgbcl-loading>
</ng-template>

<fgbcl-errors></fgbcl-errors>
