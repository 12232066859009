<div class="d-block w-100">
  <div *ngIf="!canEdit" class="score-prediction-text">
    <hr class="separator border-grey my-2" />
    <div class="text-primary font-default font-size-14">{{ 'score.predictor.completed.good.luck.text' | translate }}</div>
    <div
      class="font-size-14 text-black pb-1 pb-lg-4 px-3 score-predictor-footer-text"
      [innerHTML]="'score.predictor.completed.submitted.text' | translate | markdownTranslate"
    ></div>
  </div>
  <div *ngIf="canEdit" class="score-prediction d-flex justify-content-center">
    <button class="btn btn-tertiary font-size-14 font-default fw-bold" (click)="editAnswer.emit()">
      {{ 'score.predictor.completed.change.button' | translate }}
    </button>
  </div>
</div>
