import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { LoggingService, NotificationCenterService, PortalProductsService, SignalRHub } from '@fgb/core';
import { ScreenType } from '@fgb/portal-component-library/src/lib/directives';
import { NavigationService, NavigationSettings } from '@fgb/portal-component-library/src/lib/shared/services';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ThemeService } from 'src/app/shared/services/theme.service';

@Component({
  selector: 'fgb-master-page',
  templateUrl: './master-page.component.html',
  styleUrls: ['./master-page.component.scss'],
})
export class MasterPageComponent implements OnInit {
  private _destroyRef = inject(DestroyRef);
  screenType = ScreenType;
  navigationSettings: NavigationSettings;

  constructor(
    private signalRHub: SignalRHub,
    private logService: LoggingService,
    private notificationCenterService: NotificationCenterService,
    private portalProductsService: PortalProductsService,
    private theme: ThemeService,
    private navigationService: NavigationService
  ) {}

  ngOnInit() {
    this.signalRHub.connectionEstablished$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((connection) => {
      this.logService.debug('SignalR connection', connection);
    });
    this.notificationCenterService.fetchNotificationCenter(5).pipe(takeUntilDestroyed(this._destroyRef)).subscribe();
    this.portalProductsService.fetchPortalProducts().pipe(takeUntilDestroyed(this._destroyRef)).subscribe();
    this.theme.initColorMode();
    
     // Subscribe to navigation changes
     this.navigationService.onNavigationChanged
     .pipe(
       takeUntilDestroyed(this._destroyRef)
     ).subscribe((navigationSettings) => {
       this.navigationSettings = navigationSettings;
     });
  }

}

