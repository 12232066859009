<div class="bg-primary w-100">
  <div class="col-12 register-panel">
    <div class="overflow-hidden p-0">
      <div class="row">
        <div class="bg-dark-green d-flex w-100 d-md-none m-auto mobile-header">
          <img draggable="false" src="assets/images/club-logo.png" />
        </div>
        <div class="col-lg-6 d-none d-lg-block login-banner"></div>
        <div class="user-login-panel col-lg-6 col-12 text-start bg-light-grey">
          <div class="container h-100">
            <div class="row h-100">
              <div class="col-12">
                <router-outlet></router-outlet>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
