import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CardService, AuthService, ErrorStateService } from '@fgb/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, firstValueFrom } from 'rxjs';
import { InvalidTokenModalComponent } from '../invalid-token-modal/invalid-token-modal.component';
import { PopupAddToHomeScreenComponent } from '../popup-add-to-home-screen/popup-add-to-home-screen.component';

@Component({
  selector: 'fgb-user-login',
  templateUrl: './user-login.component.html',
  styleUrls: ['./user-login.component.scss'],
})
export class UserLoginComponent implements OnInit {
  loginForm: UntypedFormGroup;
  returnUrl: string;
  submitted = false;
  loading = false;
  private _destroyed$ = new Subject<void>();

  constructor(
    public cardService: CardService,
    private authService: AuthService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    public errorService: ErrorStateService,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });

    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';
  }

  get f() {
    return this.loginForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;

    firstValueFrom(this.authService
      .login(this.f.username.value, this.f.password.value))
      .then(() => {
        this.router.navigate([this.returnUrl]);
        let userAgent = navigator.userAgent;
        setTimeout(() => {
          // android detection
          if (/android/i.test(userAgent)) {
            const modalRef = this.modalService.open(PopupAddToHomeScreenComponent, {
              windowClass: 'popup-add-to-home-screen-android-modal-window',
              modalDialogClass: 'popup-add-to-home-screen-dialog',
              ariaLabelledBy: 'modal-basic-title',
              backdrop: false,
            });
            modalRef.componentInstance.detectedDevice = 'android';
          }
          // iOS detection
          if (/iPad|iPhone|iPod/g.test(userAgent)) {
            const modalRef = this.modalService.open(PopupAddToHomeScreenComponent, {
              windowClass: 'popup-add-to-home-screen-ios-modal-window',
              modalDialogClass: 'popup-add-to-home-screen-dialog',
              ariaLabelledBy: 'modal-basic-title',
              backdrop: false,
            });
            modalRef.componentInstance.detectedDevice = 'ios';
          }
        }, 3000);
      })
      .catch((response: HttpErrorResponse) => {
        this.loading = false;
        this.errorService.clearErrors();
        if (response.status == 401) {
          this.errorService.addError('Invalid Refresh Token.');
          this.modalService.open(InvalidTokenModalComponent, {
            modalDialogClass: 'invalid-token-modal',
            ariaLabelledBy: 'modal-basic-title',
            centered: true,
          });
        } else {
          this.errorService.addError('Invalid Email Address and Password.');
        }
      });
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }
}
