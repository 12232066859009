import { Component, OnInit } from '@angular/core';
import { LoyaltyCustomerTier, MemberQuery, TierQuery, TierService } from '@fgb/core';
import { Observable, firstValueFrom, fromEvent } from 'rxjs';
import { distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators';

@Component({
  selector: 'fgb-tiers-page',
  templateUrl: './tiers-page.component.html',
  styleUrls: ['./tiers-page.component.scss']
})
export class TiersPageComponent implements OnInit {
  tier$: Observable<LoyaltyCustomerTier | undefined>;
  progressBarStyle$: Observable<any>;

  constructor(private tierService: TierService, 
              private tierQuery: TierQuery,
              private memberQuery: MemberQuery) { }

  ngOnInit(): void {
    firstValueFrom(this.tierService.fetchTiersWithPositions(this.memberQuery.getUserContext().externalRef));
    this.tier$ = this.tierQuery.customerTier$;
    const view$ = fromEvent(window, 'resize').pipe(startWith(window.innerWidth < 991), map(() => window.innerWidth < 991), distinctUntilChanged());
    this.progressBarStyle$ = view$.pipe(
      switchMap(isMobile => this.tier$.pipe(
        map(tier => {
          let progressValue = 0;
          const barWidth = 2;

          if( tier?.Tiers?.length ){
            // @TODO: might need to change if order is based on tierId or something else
            progressValue = tier.Tiers.findIndex(t => t.TierId === tier.CurrentTierId) / (tier.Tiers.length - 1) * 100;
          }

          return isMobile ? { height: `${progressValue}%`, width: `${barWidth}px` } : { width: `${progressValue}%`, height: `${barWidth}px` };
        })
    )));
  }

}
