import { Component, OnInit, OnDestroy } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { FantasyLeaderboardQuery, FantasyLeaderboard, FantasyLeaderboardService } from '@fgb/core';
import { Subscription, combineLatest } from 'rxjs';
import * as _moment from 'moment';
import { OwlCarouselSettings } from 'src/app/shared/utilities/owl-carousel-settings';
const moment = _moment;
@Component({
  selector: 'fgb-fantasy-leaderboard-page',
  templateUrl: './fantasy-leaderboard-page.component.html',
  styleUrls: ['./fantasy-leaderboard-page.component.scss'],
})
export class FantasyLeaderboardPageComponent implements OnInit, OnDestroy {
  backButtonLink: string = '/games/pickaplayer';

  yearlyLeaderboards: FantasyLeaderboard[];
  monthlyLeaderboard: any[] = [];
  fantasySub: Subscription;

  showMonthly: boolean = true;
  year: number = new Date().getFullYear();

  customOptions: OwlOptions = {
    items: 1,
    margin: OwlCarouselSettings.Margin,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    navSpeed: 700,
    nav: true,
    dots: false,
    startPosition: new Date().getMonth(),
    navText: [
      '<i class="material-icons fw-bold text-black">chevron_left</i>',
      '<i class="material-icons fw-bold text-black">chevron_right</i>',
    ],
    stagePadding: 0,
  };

  constructor(
    private fantasyLeaderboardQuery: FantasyLeaderboardQuery,
    private fantasyLeaderboardService: FantasyLeaderboardService
  ) {}

  ngOnInit() {
    this.fantasySub = combineLatest([
      this.fantasyLeaderboardService.fetchMonthlyLeaderboard(10),
      this.fantasyLeaderboardService.fetchYearlyLeaderboard(10),
    ]).subscribe((x) => {
      let monthly = this.fantasyLeaderboardQuery.getMonthlyLeaderboard() as FantasyLeaderboard[];
      this.yearlyLeaderboards = this.fantasyLeaderboardQuery.getYearlyLeaderboard();

      let today = new Date();
      let currentMonth = today.getMonth() + 1;
      let currentYear = today.getFullYear() - 1;

      for (let index = 1; index <= 12; index++) {
        currentMonth++;
        if (currentMonth > 12) {
          currentMonth = today.getMonth();
          currentYear = today.getFullYear();
        }

        if (currentYear == today.getFullYear() && currentMonth > today.getMonth() + 1) {
          this.monthlyLeaderboard.push({
            month: moment.monthsShort(currentMonth - 1) + ' ' + currentYear,
            entries: [],
          });
        } else {
          this.monthlyLeaderboard.push({
            month: moment.monthsShort(currentMonth - 1) + ' ' + currentYear,
            entries: monthly.filter((x: FantasyLeaderboard) => {
              return (x.FantasyMonth as unknown as number) === currentMonth;
            }),
          });
        }
      }
    });
  }

  ngOnDestroy() {
    if (this.fantasySub) {
      this.fantasySub.unsubscribe();
    }
  }
}
