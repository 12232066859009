<div class="card b-0 mt-3 mt-lg-0 shadow">
  <div class="card-body p-3">
    <div class="d-flex flex-column flex-lg-row justify-content-center">
      <fgbcl-date-range-picker [(startDate)]="transactionStartDate" [(endDate)]="transactionEndDate"></fgbcl-date-range-picker>
      <button
        type="button"
        class="search-button fw-bold mb-2 d-block border-0 text-white bg-black"
        (click)="splitTransaction.reset(); splitTransaction.search()"
      >
        Search
      </button>
    </div>
  </div>
</div>

<div class="mt-4">
  <fgb-split-transaction-list
    [dateFrom]="transactionStartDate"
    [dateTo]="transactionEndDate"
    #splitTransaction
  ></fgb-split-transaction-list>
</div>
