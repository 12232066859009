import { NgModule } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { UserModule } from '../user/user.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { AccountNavComponent } from './components/account-nav/account-nav.component';
import { FooterComponent } from './components/footer/footer.component';
import { MixedLoyaltyNavComponent } from './components/mixed-loyalty-nav/mixed-loyalty-nav.component';
import { MobileNavMenuComponent } from './components/mobile-nav-menu/mobile-nav-menu.component';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { NavigationIndicatorComponent } from './components/navigation-indicator/navigation-indicator.component';
import { TicketNavComponent } from './components/ticket-nav/ticket-nav.component';
import { GameNavComponent } from './components/game-nav/game-nav.component';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { GuardianModule } from '../guardian/guardian.module';
import { ClubModule } from '../club/club.module';
import { PopupNotificationComponent } from './components/popup-notification/popup-notification.component';
import { FormsModule } from '@angular/forms';
import { FloatingCartButtonComponent } from './components/floating-cart-button/floating-cart-button.component';
import { LanguageSelectorModule } from '@fgb/portal-component-library/src/lib/shared/language-selector';
import { LoyaltyIconModule } from '@fgb/portal-component-library/src/lib/shared/loyalty-icon';
import { CartItemModule } from '@fgb/portal-component-library/src/lib/loyalty/cart-item';
import { AccessibilityModule } from '@fgb/portal-component-library/accessibility';
import { NotificationModule } from '@fgb/portal-component-library/src/lib/club/notification';
import { ArenaCashNavComponent } from './components/arena-cash-nav/arena-cash-nav.component';
import { ChallengesNavComponent } from './components/challenges-nav/challenges-nav.component';

@NgModule({
    declarations: [
        NavBarComponent,
        NavigationIndicatorComponent,
        FooterComponent,
        MixedLoyaltyNavComponent,
        AccountNavComponent,
        GameNavComponent,
        MobileNavMenuComponent,
        TicketNavComponent,
        PopupNotificationComponent,
        FloatingCartButtonComponent,
        ArenaCashNavComponent,
        ChallengesNavComponent
    ],
    exports: [
        NavBarComponent,
        NavigationIndicatorComponent,
        FooterComponent,
        MixedLoyaltyNavComponent,
        AccountNavComponent,
        GameNavComponent,
        MobileNavMenuComponent,
        TicketNavComponent,
        PopupNotificationComponent,
        FloatingCartButtonComponent,
        ArenaCashNavComponent,
        ChallengesNavComponent
    ],
    imports: [
        RouterModule,
        NgbModule,
        CommonModule,
        FormsModule,
        InlineSVGModule,
        SharedModule,
        UserModule,
        ClubModule,
        GuardianModule,
        LanguageSelectorModule,
        LoyaltyIconModule,
        CartItemModule,
        AccessibilityModule,
        NotificationModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: function HttpLoaderFactory(http: HttpClient) {
                    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
                },
                deps: [HttpClient],
            },
        }),
    ]
})
export class LayoutModule {}
