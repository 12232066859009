<div class="card-body p-0 floating-button rounded-circle" *ngIf="totalItems$ | async as totalItems">
  <button
    id="cart-button"
    class="w-100 p-3 btn btn-primary shadow bg-primary rounded-circle"
    attr.aria-label="{{ 'cart.mobile.button.screenreader' | translate }}"
    (click)="openCartModal()"
  >
    <span class="svg large-basket-icon mt-2" [inlineSVG]="'assets/images/icons/shopping-basket.svg'"> </span>
    <div class="circle-basket-count bg-danger text-center rounded-circle">
      <span class="text-white basket-count d-block">{{ totalItems }}</span>
    </div>
  </button>
</div>
