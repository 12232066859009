import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MemberService } from '@fgb/core';
import { Regex } from '../../../../shared/utilities/regex';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'fgb-user-reset-password',
  templateUrl: './user-reset-password.component.html',
  styleUrls: ['./user-reset-password.component.scss'],
})
export class UserResetPasswordComponent implements OnInit {
  formReset: UntypedFormGroup;
  isSuccess: boolean;
  errorMessage: string;
  timeout: any;
  appleLinkUrl: string;
  googleLinkUrl: string;

  constructor(private _fb: UntypedFormBuilder, 
              private memberService: MemberService, 
              private translate: TranslateService) {}

  ngOnInit(): void {
    this.initForm();
    this._initLinks();
  }

  private _initLinks(): void{
    this.appleLinkUrl = this.translate.instant('apple.link.url');
    this.googleLinkUrl = this.translate.instant('google.link.url');
  }
  
  initForm() {
    this.formReset = this._fb.group({
      email: ['', [Validators.required, Validators.pattern(Regex.email)]],
    });
  }

  submit() {
    this.isSuccess = false;
    this.errorMessage = '';
    this.formReset.markAllAsTouched();

    if( this.timeout ){
      clearTimeout(this.timeout);
    }

    if (this.formReset.invalid) return;

    const email = this.formReset.get('email')?.value;
    const clubId = 1;
    this.memberService
      .resetPassword(email, clubId)
      .then((_) => {
        this.isSuccess = true;
        this.clearSuccessMessage();
      })
      .catch((err) => {
        this.errorMessage = err.error.Message ? err.error.Message : this.translate.instant('user.reset.password.error');
      });
  }

  clearSuccessMessage(): void{
    this.timeout = setTimeout(() => {
      this.isSuccess = false;
    }, 5000);
  }

}
