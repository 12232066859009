<div class="announcements-container row w-100 rounded overflow-hidden border">
  <ng-container *ngIf="announcement.Image">
    <div class="col-lg-6">
      <img class="announcement-image" src="{{ announcement.Image | contentImage: '23' }}" alt="{{ announcement.Image }}" />
    </div>
  </ng-container>
  <div class="col-lg-6 announcements-text text-start position-relative">
    <h3 class="announcements-title">{{ announcement.Title }}</h3>
    <div class="announcements-info" [innerHTML]="announcement.Description | safeHtml"></div>
    <ng-container *ngIf="announcement.LinkUrl">
      <a class="announcement-link" *ngIf="announcement.LinkType == 1" [routerLink]="announcement.LinkUrl">
        {{ announcement.LinkTitle }}
      </a>
      <a
        class="announcement-link"
        *ngIf="announcement.LinkType == 2"
        [href]="announcement.LinkUrl"
        [target]="isEnableTarget ? '_blank' : ''"
      >
        {{ announcement.LinkTitle }}
      </a>
    </ng-container>
  </div>
</div>
