import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ChildService, ErrorStateService } from '@fgb/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'fgb-lost-barcode-modal',
  templateUrl: './lost-barcode-modal.component.html',
  styleUrls: ['./lost-barcode-modal.component.scss'],
})
export class LostBarcodeModalComponent implements OnInit {
  barcodeForm: UntypedFormGroup;
  @Input() childId: number;
  replacementSuccess = false;
  @Input() isBarcodeLost = false;
  constructor(
    private childService: ChildService,
    private formBuilder: UntypedFormBuilder,
    public activeModal: NgbActiveModal,
    private errorService: ErrorStateService
  ) {}

  ngOnInit() {
    this.barcodeForm = this.formBuilder.group({
      barcode: ['', Validators.required],
    });
  }

  linkNewBarcode() {
    this.errorService.clearErrors();
    let barcodeText = this.barcodeForm.value.barcode;
    if (barcodeText == '') {
      this.errorService.addError('Please enter a barcode');
    } else {
      this.childService.lostCard(this.childId, barcodeText).then(() => {
        this.replacementSuccess = true;
      });
    }
  }

  closeModalAndUpdate() {
    this.activeModal.close();
    location.reload();
  }
}
