import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BalanceQuery, config, PointsTransferService, PointsTransferType, PartnerQuery, Partner } from '@fgb/core';
import { Subscription, Observable, firstValueFrom } from 'rxjs';
import { OwlCarouselSettings } from 'src/app/shared/utilities/owl-carousel-settings';

@Component({
  selector: 'fgb-points-transfer',
  templateUrl: './points-transfer.component.html',
  styleUrls: ['./points-transfer.component.scss'],
})
export class PointsTransferComponent implements OnInit, OnDestroy {
  transferPointsForm: UntypedFormGroup = this.formBuilder.group({
    PartnerAccountNumber: ['', [Validators.required]],
    Amount: ['', [Validators.required]],
  });
  selectedPointsTransferType: PointsTransferType = PointsTransferType.Partner;
  PointsTransferTypes: PointsTransferType[] = [PointsTransferType.Partner, PointsTransferType.NonPartner];
  balanceSub: Subscription;
  partners$: Observable<Partner[]>;
  amount: number = 10;
  balance: number;
  selectedPartner: Partner | undefined;
  hasSelectedPartner: boolean;
  customOptions: OwlOptions = {
    margin: OwlCarouselSettings.Margin,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    navSpeed: 700,
    center: false,
    nav: false,
    responsive: {
      0: { items: 2 },
      400: { items: 3 },
      740: { items: 5 },
    },
  };

  buttonNames = {
    [PointsTransferType.Partner]: 'Partner',
    [PointsTransferType.NonPartner]: 'Non-Partner',
  };

  @ViewChild('successModal') successModalRef: ElementRef;
  @ViewChild('confirmModal') confirmModalRef: ElementRef;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private modalService: NgbModal,
    private balanceQuery: BalanceQuery,
    private pointsTransferService: PointsTransferService,
    private partnerQuery: PartnerQuery
  ) {}

  ngOnInit() {
    this.partners$ = this.partnerQuery.selectAll();
    this.transferPointsForm.controls.Amount.setValue(this.amount.toString());
    this.balanceSub = this.balanceQuery.selectPurse(config.purseConfig.virtualLoyalty).subscribe((bal) => {
      this.balance = bal ?? 0;
    });
    this.SelectPointsTransferType(PointsTransferType.Partner);
  }

  ngOnDestroy() {
    if (this.balanceSub) {
      this.balanceSub.unsubscribe();
    }
  }

  SelectPointsTransferType(type: PointsTransferType) {
    this.selectedPointsTransferType = type;
    this.transferPointsForm.controls.PartnerAccountNumber.setValue('');
    this.selectedPartner = undefined;
  }

  submitStep() {
    if (!this.transferPointsForm.valid) {
      return;
    }
    this.transferPointsForm.controls.Amount.setValue(this.amount);
    this.modalService.open(this.confirmModalRef);
  }

  showPointsCounter(): boolean {
    return this.selectedPointsTransferType === 0 && this.transferPointsForm.controls.PartnerAccountNumber.value === '';
  }

  selectPartner(selectedPartner: Partner) {
    this.transferPointsForm.controls.PartnerAccountNumber.setValue(selectedPartner.ExternalRefNumber);
    this.selectedPartner = selectedPartner;
  }

  transferPoints() {
    let accountNumber = this.transferPointsForm.controls.PartnerAccountNumber.value;
    let amountToTransfer = this.transferPointsForm.controls.Amount.value;

    firstValueFrom(this.pointsTransferService
      .pointsTransfer(amountToTransfer, accountNumber))
      .then((response) => {
        this.goToSuccessModal();
      })
      .catch((err) => {
        this.modalService.dismissAll();
      });
  }

  addPoints(value: number) {
    this.amount += value;
    this.transferPointsForm.controls.Amount.setValue(this.amount);
  }

  goToSuccessModal() {
    this.modalService.open(this.successModalRef);
  }

  closeModal() {
    this.modalService.dismissAll();
  }
}
