<nav class="nav nav-secondary account-details-nav">
  <!--Activity-->
  <a
    class="nav-item"
    [routerLink]="['/activity']"
    [routerLinkActive]="'active'"
    [routerLinkActiveOptions]="{ exact: true }"
    attr.aria-label="{{ 'account.nav.activity.screenreader' | translate : { fgbdefault: 'Move to activity page' } }}"
  >
    <div class="icon material-icons" aria-hidden="true">history</div>
    <label>{{ 'account.nav.activity' | translate }}</label>
  </a>
  <!--Tiers-->
  <a
    class="nav-item"
    [routerLink]="['/tiers']"
    [routerLinkActive]="'active'"
    [routerLinkActiveOptions]="{ exact: true }"
    attr.aria-label="{{ 'account.nav.tiers.screenreader' | translate : { fgbdefault: 'Move to tiers page' } }}"
  >
    <div class="icon material-icons" aria-hidden="true">bar_chart</div>
    <label>{{ 'account.nav.tiers' | translate }}</label>
  </a>
</nav>
