import { Component, OnInit } from '@angular/core';
import { AuctionService, LottoService, MarketplaceService, MemberQuery } from '@fgb/core';
import { tap, zip } from 'rxjs';
import { Populations } from 'src/app/shared/utilities/populations';

@Component({
  selector: 'fgb-rewards-page',
  templateUrl: './rewards-page.component.html',
  styleUrls: ['./rewards-page.component.scss'],
})
export class RewardsPageComponent implements OnInit {
  populations = Populations;
  
  constructor(
    private auctionService: AuctionService,
    private lottoService: LottoService,
    private marketplaceService: MarketplaceService,
    private memberQuery: MemberQuery
  ) {}

  filterTypesList: { label: string; value: string }[] = [{ label: 'reward.filter_modal.all', value: 'all' }];
  selectedValues: string[] = ['all'];
  showOffCanvas: boolean = false;

  ngOnInit() {
    const isFreeMember = this.memberQuery.getPopulationName() === Populations.NonMember;

    zip(
      this.auctionService.fetchAuctions(),
      this.lottoService.fetchLottos().pipe(
        tap(() => {
          if (isFreeMember) {
            this.lottoService.lockAllItems();
          }
        })
      ),
      this.marketplaceService.fetchMarketplaceItems().pipe(
        tap(() => {
          if (isFreeMember) {
            this.marketplaceService.lockAllItems();
          }
        })
      )
    ).toPromise();
  }

  handleFilterTypesListEmitter(filterTypes: { label: string; value: string }[]) {
    this.filterTypesList = [...filterTypes];
  }

  handleSelectedValuesEmitter(selectedValues: string[]) {
    this.selectedValues = [...selectedValues];
  }

  handleShowOffCanvasEmitter(showOffCanvas: boolean) {
    this.showOffCanvas = showOffCanvas;
  }
}
