<!--Back Button-->
<div class="container">
  <div class="back-btn pt-3 cursor-pointer d-none d-lg-block" [routerLink]="'/rewards'">
    <span class="material-icons align-middle fw-bold pe-2">navigate_before</span>
    <span class="font-size-14 fw-bold">{{ 'rewards.details.page.back.button' | translate }}</span>
  </div>
</div>

<ng-container *inPopulation="[populations.NonMember]">
  <div class="container mt-3 mb-2 d-none d-lg-flex">
    <fgb-member-upgrade-card></fgb-member-upgrade-card>
  </div>
</ng-container>

<!--Point Summary Bar-->
<div class="container back-btn-container">
  <fgb-points-summary-bar [backButtonLink]="'/rewards'" [backButtonTitle]="'Raffles'"></fgb-points-summary-bar>
</div>

<ng-container *inPopulation="[populations.NonMember]">
  <div class="container mt-3 mb-3 d-flex d-lg-none">
    <fgb-member-upgrade-card></fgb-member-upgrade-card>
  </div>
</ng-container>

<!--Lotto Details-->
<fgbcl-lotto-details [lottoId]="id" [showTermsAndConditions]="true"></fgbcl-lotto-details>
