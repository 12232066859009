import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TicketTopUpListComponent } from './components/ticket-top-up/ticket-topup-list/ticket-topup-list.component';
import { TicketTopupCheckoutComponent } from './components/ticket-top-up/ticket-topup-checkout/ticket-topup-checkout.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QRCodeModule } from 'angularx-qrcode';
import { RouterModule } from '@angular/router';
import { TicketDetailsUnloadedComponent } from './components/ticket-top-up/ticket-details/ticket-details-unloaded/ticket-details-unloaded.component';
import { TicketUnloadedListComponent } from './components/ticket-top-up/ticket-unloaded-list/ticket-unloaded-list.component';
import { TicketLoadedListComponent } from './components/ticket-top-up/ticket-loaded-list/ticket-loaded-list.component';
import { EcashModule } from '../ecash/ecash.module';
import { TicketDetailsLoadedComponent } from './components/ticket-top-up/ticket-details/ticket-details-loaded/ticket-details-loaded.component';
import { TicketTopupCheckoutListComponent } from './components/ticket-top-up/ticket-topup-checkout-list/ticket-topup-checkout-list.component';
import { UserModule } from '../user/user.module';
import { EventsModule } from '../events/events.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { ErrorsModule } from '@fgb/portal-component-library/src/lib/shared/errors';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { FGBMissingTranslationHandler, FGBTranslationLoader } from '@fgb/core';
import { HttpClient } from '@angular/common/http';

@NgModule({
  declarations: [
    TicketTopUpListComponent,
    TicketTopupCheckoutComponent,
    TicketDetailsUnloadedComponent,
    TicketUnloadedListComponent,
    TicketLoadedListComponent,
    TicketDetailsLoadedComponent,
    TicketTopupCheckoutListComponent,
  ],
  exports: [
    TicketTopupCheckoutComponent,
    TicketDetailsUnloadedComponent,
    TicketUnloadedListComponent,
    TicketLoadedListComponent,
    TicketTopUpListComponent,
  ],
  imports: [
    NgbModule,
    CommonModule,
    UserModule,
    SharedModule,
    FormsModule,
    QRCodeModule,
    RouterModule,
    ReactiveFormsModule,
    EcashModule,
    EventsModule,
    ErrorsModule,
    InlineSVGModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: FGBTranslationLoader,
        deps: [HttpClient],
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: FGBMissingTranslationHandler,
      },
    }),
  ],
})
export class TicketsModule {}
