import { Injectable } from "@angular/core";
import { EntityState, EntityStore, StoreConfig } from "@datorama/akita";
import { LoyaltyCustomerTier, LoyaltyTier } from "@fgb/core";

export interface TierState extends EntityState<LoyaltyTier> {
  tier?: LoyaltyCustomerTier;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'tiers', idKey: 'TierId', cache: { ttl: 30000 } })
export class TierStore extends EntityStore<TierState, LoyaltyTier> {

  constructor() {
    super({});
  }

  setTier(tier: LoyaltyCustomerTier) {
    this.update({ tier });
  }

}