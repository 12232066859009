<div *ngIf="member$ | async as member">
  <form [formGroup]="detailsForm" class="row flex-column flex-lg-row">
    <div class="col-lg-6 col-12">
      <label class="mb-0">{{ 'edit.account.details.dob' | translate }}</label>
      <div class="d-flex p-2 m-0 mt-1 rounded-input">
        <span class="icon material-icons" aria-hidden="true">date_range</span>
        <span class="ms-2 text-info w-75">
          <input
            type="text"
            class="edit-input p-0"
            formControlName="dateOfBirth"
            ngbDatepicker
            [minDate]="{ year: 1930, month: 1, day: 1 }"
            [maxDate]="{ year: maxYear, month: currentMonth, day: currentDate }"
            [startDate]="{ year: maxYear, month: currentMonth, day: currentDate }"
            #dobDatePicker="ngbDatepicker"
            (click)="dobDatePicker.toggle()"
          />
        </span>
      </div>
    </div>
    <div class="col-lg-6 col-12 mt-lg-0 mt-2">
      <label id="email-label" class="mb-0 personal-info">{{ 'edit.account.details.email.address' | translate }}</label>
      <div class="d-flex p-2 m-0 mt-1 rounded-input">
        <span class="icon material-icons" aria-hidden="true">email</span>
        <span class="ms-2 text-info w-75">
          <div>
            <span *ngIf="notInAnyEditMode()">{{ member.EmailAddress1 }}</span>
            <input
              aria-labelledby="email-label"
              class="edit-input p-0"
              *ngIf="anyEditMode()"
              type="text"
              formControlName="emailAddress"
            />
          </div>
        </span>
      </div>
    </div>
    <ng-container *ngIf="isLoading">
      <fgbcl-loading></fgbcl-loading>
    </ng-container>
    <ng-container *ngIf="!isLoading && showEditOptions">
      <button
        type="button"
        class="edit-button fw-bold d-block mx-auto border-0 rounded-input text-white bg-primary"
        *ngIf="notInAnyEditMode()"
        (click)="edit()"
      >
        {{ 'edit.account.details.edit.button.text' | translate }}
      </button>
      <button
        #autoEditConfirm
        type="button"
        class="save-button fw-bold d-block mx-auto border-0 rounded-input text-white bg-success"
        *ngIf="autoEditMode"
        [ngClass]="disableIfFormIncomplete()"
        (click)="save()"
      >
        {{ 'edit.account.details.confirm.button.text' | translate }}
      </button>
      <div class="d-flex justify-content-between">
        <button
          type="button"
          class="cancel-button fw-bold d-inline-block border-0 rounded-input text-white bg-danger"
          *ngIf="isInEditMode"
          (click)="stopEditing()"
        >
          {{ 'edit.account.details.cancel.button.text' | translate }}
        </button>
        <button
          type="button"
          class="save-button fw-bold d-inline-block border-0 rounded-input text-white bg-success"
          *ngIf="isInEditMode"
          (click)="save()"
        >
          {{ 'edit.account.details.save.button.text' | translate }}
        </button>
      </div>
    </ng-container>
  </form>
</div>
