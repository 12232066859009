<div>
  <div class="row">
    <div class="col-2">
      <div class="player-img-container rounded-circle overflow-hidden mx-auto">
        <img src="{{ fantasyHistoryItem.PlayerImage | contentImage : '1' }}" class="h-100" />
      </div>
    </div>
    <div class="col-lg-7 col-6 d-flex align-items-center px-0">
      <div>
        <div class="d-inline-flex">
          <div class="font-size-14 fw-bold player-name text-black fantasy-player-history">
            {{ fantasyHistoryItem.PlayerName }}
          </div>
        </div>
        <div class="font-default mt-1 away-team-name text-black d-flex align-items-center fantasy-player-history">
          <div class="font-size-14 fw-bold text-black me-2 fantasy-player-history">VS</div>
          <div class="font-size-12 fantasy-player-history">{{ fantasyHistoryItem.AwayTeamName }} - {{ gameDate }}</div>
        </div>
      </div>
    </div>

    <div class="col-lg-3 col-4 d-flex align-items-center justify-content-end ps-0 pe-lg-3">
      <div
        class="text-end font-size-14 fw-bold"
        *ngIf="!isPending; else pointsPending"
        [ngClass]="{
          'text-primary processed-fantasy': fantasyHistoryItem.PlayerGamePoints > 0,
          'text-info ': fantasyHistoryItem.PlayerGamePoints === 0
        }"
      >
        <span *ngIf="fantasyHistoryItem.PlayerGamePoints > 0">+</span>
        {{ fantasyHistoryItem.PlayerGamePoints / 100 | FortressCurrencyPipe : 'PTS' }}
      </div>
      <ng-template #pointsPending>
        <span class="font-size-14 text-grey fw-bold">{{ 'fantasy.history.points.pending' | translate }}</span>
      </ng-template>
    </div>
  </div>
</div>
