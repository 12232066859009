<div class="container" *ngIf="gameNumber$ | async as gameNumber">
  <!-- Desktop heading -->
  <div class="desktop-heading">
    <fgb-ticket-nav></fgb-ticket-nav>
    <div class="row">
      <div class="col-md-4 member-card p-3">
        <fgb-member-card></fgb-member-card>
      </div>
      <div class="col-md-8 pe-0">
        <div class="event-selector p-3">
          <h2>EVENT SELECTOR</h2>
          <fgb-events-drop-down (eventSelected)="setSelectedEvent($event)" [eventId]="gameNumber"></fgb-events-drop-down>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 ticketing-eCash-balance-banner">
        <fgb-ecash-balance></fgb-ecash-balance>
      </div>
    </div>
  </div>

  <div class="mobile-heading">
    <div class="row">
      <div class="col-md-12">
        <div class="member-card p-3">
          <fgb-member-card></fgb-member-card>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="memberCard">
          <h3>SHOW MY MEMBER CARD</h3>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <fgb-ticket-nav></fgb-ticket-nav>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="ticketing-eCash-balance-banner">
          <fgb-ecash-balance></fgb-ecash-balance>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="event-selector p-3">
          <h2>EVENT SELECTOR</h2>
          <fgb-events-drop-down (eventSelected)="setSelectedEvent($event)" [eventId]="gameNumber"></fgb-events-drop-down>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div *ngIf="gameNumber" class="col-md-12">
      <fgb-ticket-topup-list [gameNumber]="gameNumber"></fgb-ticket-topup-list>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12 topup-container p-3">
      <fgb-ticket-topup-checkout [gameNumber]="gameNumber"></fgb-ticket-topup-checkout>
    </div>
  </div>
</div>
