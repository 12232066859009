import { Component, OnInit } from '@angular/core';
import { MarketplaceService, MemberQuery } from '@fgb/core';
import { Populations } from 'src/app/shared/utilities/populations';

@Component({
  selector: 'fgb-products-page',
  templateUrl: './products-page.component.html',
  styleUrls: ['./products-page.component.scss'],
})
export class ProductsPageComponent implements OnInit {
  constructor(private marketplaceService: MarketplaceService, private memberQuery: MemberQuery) {}

  ngOnInit(): void {
    const isFreeMember = this.memberQuery.getPopulationName() === Populations.NonMember;
    this.marketplaceService
      .fetchMarketplaceItems()
      .toPromise()
      .then(() => {
        if (isFreeMember) {
          this.marketplaceService.lockAllItems();
        }
      });
  }
}
