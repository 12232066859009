import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@fgb/core';
import { ErrorStateService } from '@fgb/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'fgb-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent implements OnInit {
  appleLinkUrl: string;
  googleLinkUrl: string;
  
  constructor(private router: Router, 
              public errorService: ErrorStateService, 
              private authService: AuthService, 
              private translate: TranslateService) {
    if (this.authService.hasCurrentRefreshToken()) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit() {    
    this._initLinks();
  }

  private _initLinks(): void{
    this.appleLinkUrl = this.translate.instant('apple.link.url');
    this.googleLinkUrl = this.translate.instant('google.link.url');
  }
}
