import { Component, OnInit } from '@angular/core';
import { NgbDate, NgbDateNativeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { Transaction, LoggingService, MemberQuery, TransactionQuery, TransactionService, TransactionType } from '@fgb/core';
import { switchMap, take } from 'rxjs/operators';

import * as _moment from 'moment';
import { firstValueFrom, of } from 'rxjs';
const moment = _moment;

@Component({
  selector: 'fgb-manage-ticket-history-page',
  templateUrl: './manage-ticket-history-page.component.html',
  styleUrls: ['./manage-ticket-history-page.component.scss'],
})
export class ManageTicketHistoryPageComponent implements OnInit {
  loading: boolean = false;
  startDate: NgbDate;
  endDate: NgbDate;
  today: NgbDate;
  transactions?: { [day: string]: Transaction[] };
  dayKeys: string[];

  constructor(
    private transactionService: TransactionService,
    private transactionQuery: TransactionQuery,
    private memberQuery: MemberQuery,
    private logService: LoggingService
  ) {
    let currentDate = new Date(Date.now());
    this.startDate = new NgbDate(currentDate.getFullYear(), currentDate.getMonth() + 1, currentDate.getDate());
    this.endDate = new NgbDate(currentDate.getFullYear(), currentDate.getMonth() + 1, currentDate.getDate());
    this.today = new NgbDate(currentDate.getFullYear(), currentDate.getMonth() + 1, currentDate.getDate());
  }

  ngOnInit() {
    this.getTransactions();
  }

  async getTransactions() {
    this.loading = true;

    const user = this.memberQuery.getValue().user;

    if (!user) {
      this.logService.warning('Failed to get transactions - user is not logged in.');
      return;
    }

    let searchFrom = new NgbDateNativeAdapter().toModel(this.startDate);
    let searchTo = new NgbDateNativeAdapter().toModel(this.endDate);

    if (searchFrom === null || searchTo === null) {
      this.logService.warning('Search dates are null');
      return;
    }

    searchFrom.setHours(0, 0, 0, 0);
    searchTo.setHours(23, 59, 59, 999);

    let transactions: Transaction[];

    transactions = await firstValueFrom(this.transactionService
      .fetchTransactions(searchFrom, searchTo, user.memberId, TransactionType.ecash)
      .pipe(
        switchMap(() => {
          if (searchFrom == null || searchTo == null) {
            this.logService.warning('Search dates are null');
            return of([]);
          }
          return this.transactionQuery.selectTransactions(searchFrom, searchTo, TransactionType.ecash);
        }),
        take(1)
      ));

    // Update the transactions property for the HTML
    this.setTransactionsAndDayKeys(transactions);

    this.loading = false;
  }

  /** Sets the transactions property to the given transaction list. */
  private setTransactionsAndDayKeys(transactions?: Transaction[]): { [day: string]: Transaction[] } | undefined {
    if (!transactions) {
      this.transactions = undefined;
    } else {
      this.transactions = {};

      transactions.forEach((transaction) => {
        let transactionDate = moment(transaction.TransactionDate);
        let key: string = transactionDate.format('D MMM, YYYY');
        let existingTransactions = this.transactions![key] || [];
        existingTransactions = [...existingTransactions, transaction];
        existingTransactions = existingTransactions.sort((a, b) =>
          moment(a.TransactionDate).isBefore(moment(b.TransactionDate)) ? 1 : -1
        );
        this.transactions![key] = existingTransactions;
      });

      this.dayKeys = Object.keys(this.transactions).filter((x) => this.transactions!.hasOwnProperty(x));
    }
    return this.transactions;
  }
}
