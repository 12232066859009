import { Component, Input, OnInit } from '@angular/core';
import { ActionLabels, MarketplaceService, MemberQuery } from '@fgb/core';
import { firstValueFrom } from 'rxjs';
import { HideMobileNav } from '@fgb/portal-component-library/src/lib/directives';
import { Populations } from 'src/app/shared/utilities/populations';

@Component({
  selector: 'fgb-marketplace-detail-page',
  templateUrl: './marketplace-detail-page.component.html',
  styleUrls: ['./marketplace-detail-page.component.scss'],
  hostDirectives: [HideMobileNav],
})
export class MarketplaceDetailPageComponent implements OnInit {
  @Input() id: number = 0;
  actionLabels = ActionLabels;
  populations = Populations;

  constructor(private marketplaceService: MarketplaceService, private memberQuery: MemberQuery) {}

  ngOnInit() {
    const isFreeMember = this.memberQuery.getPopulationName() === Populations.NonMember;
    firstValueFrom(this.marketplaceService.fetchMarketplaceItemById(this.id)).then(() => {
      if (isFreeMember) {
        this.marketplaceService.lockAllItems();
      }
    });
  }
}
