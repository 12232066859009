import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AnnouncementModel, AnnouncementQuery } from '@fgb/core';
import { Observable } from 'rxjs';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { OwlCarouselSettings } from '@fgb/portal-component-library/src/lib/shared/utilities';

@Component({
  selector: 'fgbcl-announcements',
  templateUrl: 'announcements.component.html',
  styleUrls: ['./announcements.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AnnouncementsComponent implements OnInit {
  announcements$: Observable<AnnouncementModel[]>;
  customOptions: OwlOptions = {
    items: 1,
    autoHeight: true,
    autoWidth: true,
    margin: 10,
    autoplay: false,
    autoplayTimeout: OwlCarouselSettings.AutoplayTimeout,
    autoplaySpeed: OwlCarouselSettings.AutoplaySpeed,
    navSpeed: 700,
    dots: true,
    responsive: {
      0: { items: 1 }
    }
  };

  constructor(private announcementQuery: AnnouncementQuery) {}

  ngOnInit() {
    this.announcements$ = this.announcementQuery.announcements();
  }
}
