<div class="text-center px-4">
  <div class="d-flex align-items-center justify-content-center pt-2 text-danger fw-bold">
    <span class="icon material-icons invalid-token-model-header-icon me-3">login</span>
    <span>{{ 'invalid.token.modal.heading' | translate }}</span>
  </div>
  <hr />
  <div class="m-5">
    {{ 'invalid.token.modal.description' | translate | markdownTranslate }}
  </div>
  <button class="invalid-token-model-button btn btn-success mt-2" (click)="closeModal()">
    {{ 'invalid.token.modal.button.text' | translate }}
  </button>
</div>
