import { Inject, Injectable, Renderer2, RendererFactory2 } from "@angular/core";
import { COLOR_MODE } from "src/app/shared/injection-tokens";

/**
 * Service helper for adding bootstrap color theming
 */
@Injectable({
    providedIn: 'root'
})
export class ThemeService{
    private renderer: Renderer2;

    constructor(private rendererFactory: RendererFactory2, @Inject(COLOR_MODE) private colorMode: string){
        this.renderer = this.rendererFactory.createRenderer(null, null);
    }

    initColorMode(): void{
        if(this.colorMode){
            this.renderer.addClass(document.body, `${this.colorMode.toLocaleLowerCase()}-theme`);
        }
    }

    setTheme(themeName: string): void{
        localStorage.setItem('theme', themeName);
        this.renderer.addClass(document.body, `${themeName}-theme`);
    }

    removeTheme(themeName: string): void{
        localStorage.removeItem('theme');
        this.renderer.removeClass(document.body, `${themeName}-theme`);
    }
}