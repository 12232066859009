import { Component, OnInit, Input, input, InputSignal, ChangeDetectionStrategy } from '@angular/core';
import { SurveyType } from '@fgb/core';
import { Survey } from '@fgb/core';

@Component({
  selector: 'fgb-survey-item',
  templateUrl: './survey-item.component.html',
  styleUrls: ['./survey-item.component.scss', '../survey.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SurveyItemComponent implements OnInit {
  survey: InputSignal<Survey | undefined> = input<Survey | undefined>(undefined);
  @Input() hideTitle: boolean = false;
  surveyType = SurveyType;

  constructor() {}

  ngOnInit() {}
}
