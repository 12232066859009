<div class="offer-banner bg-secondary" *ngIf="currentCompany$ | async as currentCompany">
  <div class="company-image bg-white rounded overflow-hidden d-flex justify-content-center align-items-center">
    <img src="{{ currentCompany.ImageUrl | contentImage : '7' }}" alt="{{ currentCompany.ImageUrl }}" />
  </div>
  <div class="offer-banner-text">
    <h1 class="text-white text-capitalize">{{ currentCompany.DisplayName }}</h1>
  </div>
</div>

<div class="offer-container pb-4 shadow">
  <div class="offers container" *ngIf="currentOffer$ | async as currentOffer">
    <!--Main Offer-->
    <h5 class="text-start text-capitalize fw-bold mb-3 pt-4">{{ 'offers.details.offer' | translate }}</h5>
    <fgb-offers-details [offer]="currentOffer"></fgb-offers-details>
  </div>
</div>
<div class="container offers" *ngIf="currentOffer$ | async as currentOffer">
  <!--Other Offers-->
  <div class="row" *ngIf="relatedOffers$ | async as relatedOffers; else noRelatedOffers">
    <div class="col-12">
      <ng-container *ngIf="!!relatedOffers.length; else noRelatedOffers">
        <h5 class="mt-5 text-capitalize mb-3">{{ 'offers.details.other.offers' | translate }}</h5>
        <owl-carousel-o [options]="customOptions">
          <ng-container *ngFor="let offer of relatedOffers">
            <ng-template carouselSlide [width]="301">
              <fgb-offers-list-item (navigateToOffer)="loadNewOffer($event)" [offer]="offer"></fgb-offers-list-item>
            </ng-template>
          </ng-container>
          <ng-template #noRelatedOffers> {{ 'offers.details.other.offer.placeholder' | translate }} </ng-template>
        </owl-carousel-o>
      </ng-container>
    </div>
  </div>
  <!--Offers you may also like-->
  <div class="row" *ngIf="otherOffers$ | async as otherOffers; else noOtherOffers">
    <div class="col-12">
      <ng-container *ngIf="!!otherOffers.length; else noOtherOffers">
        <h5 class="mt-5 text-capitalize mb-3">{{ 'offers.details.liked.offers' | translate }}</h5>
        <owl-carousel-o [options]="customOptions">
          <ng-container *ngFor="let offer of otherOffers">
            <ng-template carouselSlide [width]="301">
              <fgb-offers-list-item (navigateToOffer)="loadNewOffer($event)" [offer]="offer"></fgb-offers-list-item>
            </ng-template>
          </ng-container>
          <ng-template #noOtherOffers> {{ 'offers.details.liked.offers.placeholder' | translate }} </ng-template>
        </owl-carousel-o>
      </ng-container>
    </div>
  </div>
</div>

<!--Offer Earn Points Tab-->
<fgb-offer-earn-tab></fgb-offer-earn-tab>

<div class="container">
  <!--Back Button-->
  <div class="d-block">
    <a [routerLink]="[backButtonLink]" class="btn ps-0 mt-3 d-flex align-items-center h-100 fw-bold">
      <span class="pe-2 icon-arrow-1-left text-primary fw-bold"></span>
      <span class="fw-bold text-primary font-size-14">{{ 'offers.back.to.offers' | translate }}</span>
    </a>
  </div>
</div>
