import { Component, OnInit } from '@angular/core';
import { GameDataSource, GameService } from '@fgb/core';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'fgb-transfer-confirm-page',
  templateUrl: './transfer-confirm-page.component.html',
  styleUrls: ['./transfer-confirm-page.component.scss'],
})
export class TransferConfirmPageComponent implements OnInit {
  constructor(private gameService: GameService) {}

  ngOnInit() {
    firstValueFrom(this.gameService.fetchGames('', GameDataSource.ExternalRef));
  }
}
