<div *ngIf="portalProduct">
  <div class="mt-3 container">
    <div class="row shadow rounded sub-card">
      <div
        class="col-3 text-center rounded-left"
        [ngClass]="{ 'bg-primary': portalProduct.ProductType == 1, 'bg-secondary': portalProduct.ProductType == 3 }"
      >
        <img alt="" class="product-image" src="{{ portalProduct.PrimaryImage | contentImage : '6' }}" />
      </div>
      <div class="col-9 mt-2 d-flex flex-column justify-content-between">
        <div class="row">
          <div class="col-8">
            <div
              class="font-size-14 fw-bold"
              [ngClass]="{ 'text-primary': portalProduct.ProductType == 1, 'text-secondary': portalProduct.ProductType == 3 }"
            >
              {{ portalProduct.DisplayName }}
            </div>
          </div>
          <div class="col-4 text-end">
            <button class="btn p-0" (click)="openProductDescription(productInfo)">
              <label class="accessibility-only">{{ 'subscription.open.description.screenreader' | translate }}</label>
              <img alt="" src="assets/images/icons/info-icon.svg" class="info-icon" />
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <p class="font-size-12 subs-description text-info" [innerHTML]="portalProduct.Description"></p>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <button
              class="btn font-size-14 fw-bold w-100 mb-2"
              [ngClass]="{ 'btn-primary': portalProduct.ProductType == 1, 'btn-secondary': portalProduct.ProductType == 3 }"
              (click)="addSubscriptionProductToCart()"
            >
              <span>Upgrade</span>
              {{ portalProduct.MerchantPrice / 100 | FortressCurrencyPipe }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--Product info modal-->
<ng-template #productInfo let-modal>
  <div class="text-black bg-white">
    <div class="modal-header border-bottom-0 pb-0">
      <p class="font-size-14 fw-bold mx-auto">Subscription Details</p>
    </div>
    <hr class="mt-0 mx-5" />
    <div class="modal-body">
      <div class="row">
        <div class="col-2">
          <div
            class="text-center rounded info-image-bg"
            [ngClass]="{ 'bg-primary': portalProduct.ProductType == 1, 'bg-secondary': portalProduct.ProductType == 3 }"
          >
            <img alt="" class="info-product-image my-2" src="{{ portalProduct.PrimaryImage | contentImage : '6' }}" />
          </div>
        </div>
        <div class="col-10">
          <p>{{ portalProduct.DisplayName }}</p>
          <p>
            <span class="fw-bold">Cost: </span>
            {{ portalProduct.MerchantPrice / 100 | FortressCurrencyPipe }}
          </p>
          <p [innerHTML]="portalProduct.Description"></p>
        </div>
      </div>
      <div class="text-center">
        <button class="btn btn-primary close-info-btn fw-bold" (click)="modal.dismiss('close')">Close</button>
      </div>
    </div>
  </div>
</ng-template>
