import { Component, OnInit, Input } from '@angular/core';
import {
  MemberCard,
  MemberCardQuery,
  MemberDetails,
  Balance,
  MemberQuery,
  BalanceQuery,
  config,
  BarcodeService,
  BarcodeQuery,
  Barcode,
  TierService,
  TierQuery,
  LoyaltyCustomerTier,
} from '@fgb/core';
import { ScreenType } from '@fgb/portal-component-library/src/lib/directives';
import { Observable } from 'rxjs';
import { Populations } from 'src/app/shared/utilities/populations';

@Component({
  selector: 'fgb-member-card',
  templateUrl: './member-card.component.html',
  styleUrls: ['./member-card.component.scss'],
})
export class MemberCardComponent implements OnInit {
  @Input() showSeating: boolean;
  @Input() dropDownMemberCard: boolean = false;
  @Input() isAccountCard: boolean = false;
  memberCard$: Observable<MemberCard | undefined>;
  barcode$: Observable<Barcode>;
  ecash$: Observable<number | undefined>;
  memberDetails$: Observable<MemberDetails | undefined>;
  balance$: Observable<Balance>;
  member$: Observable<MemberDetails | undefined>;
  pointsBalance$: Observable<number | undefined>;
  combinedCard$: Observable<number | undefined>;
  screenType = ScreenType;
  tier$: Observable<LoyaltyCustomerTier | undefined>;
  populations = Populations;

  constructor(
    private barcodeService: BarcodeService,
    private barcodeQuery: BarcodeQuery,
    private memberCardQuery: MemberCardQuery,
    private memberQuery: MemberQuery,
    private balanceQuery: BalanceQuery,
    private tierService: TierService,
    private tierQuery: TierQuery
  ) {}

  ngOnInit() {
    this.barcodeService.fetchBarcode().toPromise();
    this.barcode$ = this.barcodeQuery.select();
    this.memberCard$ = this.memberCardQuery.selectCurrentCard();
    this.combinedCard$ = this.balanceQuery.selectPurse(config.purseConfig.combined);
    this.tierService.fetchTiersWithPositions(this.memberQuery.getUserContext().externalRef).toPromise();
    this.tier$ = this.tierQuery.customerTier$;
    this.memberDetails$ = this.memberQuery.selectMemberDetails();
    this.pointsBalance$ = this.balanceQuery.selectPurse(config.purseConfig.virtualLoyalty);
    this.ecash$ = this.balanceQuery.selectPurse(config.purseConfig.combined);
  }
}
