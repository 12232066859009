<div class="predicition-score font-size-14 font-heading" *ngIf="!hasSelectedCorrect">{{ homeScore }} - {{ awayScore }}</div>
<hr class="separator border-grey my-2" />
<!--Correct Heading-->
<div class="score-prediction pb-4 pt-lg-1 pt-0" *ngIf="hasSelectedCorrect">
  <div class="text-primary font-default font-size-14">{{ 'score.predictor.completed.prediction.correct' | translate }}</div>
  <div class="font-size-14 px-3 pb-0 pb-lg-1 score-predictor-footer-text">
    {{ 'score.predictor.completed.prediction.correct.you.won.text' | translate }} {{ survey.PointValue }} Points! <br />{{
      'score.predictor.completed.prediction.correct.process.time' | translate
    }}
  </div>
</div>
<!--Incorrect Heading-->
<div class="score-prediction pb-4 pt-lg-1 pt-0" *ngIf="!hasSelectedCorrect">
  <div class="text-danger font-default font-size-14">{{ 'score.predictor.completed.prediction.incorrect' | translate }}</div>
  <div
    class="font-size-14 px-3 pb-0 pb-lg-1 score-predictor-footer-text"
    [innerHTML]="'score.predictor.completed.prediction.incorrect.text' | translate | markdownTranslate"
  ></div>
</div>
