<nav class="nav nav-secondary mixed-loyalty-nav">
  <a
    attr.aria-label="{{ 'challenges.nav.screenreader' | translate }}"
    class="nav-item"
    [routerLink]="['/challenges']"
    [routerLinkActive]="'active'"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    <div class="icon material-icons" aria-hidden="true">flag</div>
    <label>{{ 'challenges.nav' | translate }}</label>
  </a>
  <a
    class="nav-item"
    [routerLink]="['/badges']"
    [routerLinkActive]="'active'"
    [routerLinkActiveOptions]="{ exact: true }"
    attr.aria-label="{{ 'challenges.nav.badges.screenreader' | translate : { fgbdefault: 'Move to badges page' } }}"
  >
    <div class="icon material-icons" aria-hidden="true">local_police</div>
    <label>{{ 'challenges.nav.badges' | translate }}</label>
  </a>
</nav>
