<div class="banner d-flex">
  <h1>{{ 'featured.rewards.page.title' | translate }}</h1>
</div>

<fgb-mixed-loyalty-nav></fgb-mixed-loyalty-nav>

<ng-container *inPopulation="[populations.NonMember]">
  <div class="container mt-3 mb-4">
    <fgb-member-upgrade-card></fgb-member-upgrade-card>
  </div>
</ng-container>

<ng-container *notInPopulation="[populations.NonMember]">
  <div class="mb-4">
    <!-- Filter options-->
    <fgbcl-filter-modal
      [filterTypesList]="filterTypesList"
      (filterTypesListEmitter)="handleFilterTypesListEmitter($event)"
      [selectedValues]="selectedValues"
      (selectedValuesEmitter)="handleSelectedValuesEmitter($event)"
      [showOffCanvas]="showOffCanvas"
      (showOffCanvasEmitter)="handleShowOffCanvasEmitter($event)"
    >
    </fgbcl-filter-modal>
  </div>
</ng-container>

<div class="container mt-3">
  <fgbcl-mixed-loyalty-list [onlyFavourites]="true" [filterOptionsList]="selectedValues"></fgbcl-mixed-loyalty-list>
</div>
