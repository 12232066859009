import { Component } from '@angular/core';

@Component({
  selector: 'fgb-challenges-page',
  templateUrl: './challenges-page.component.html',
  styleUrl: './challenges-page.component.scss'
})
export class ChallengesPageComponent {

}
