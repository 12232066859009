<div class="member-upgrade-card rounded bg-dark-green text-white d-flex flex-column">
  <div class="member-upgrade-card-body d-flex">
    <div class="img-container">
      <img alt="" aria-labelledby="homeLink" src="assets/images/club-logo.png" draggable="false" />
    </div>
    <div class="content-container ms-lg-4 ms-0">
      <h5 class="mb-0 py-3 py-lg-0">{{ 'home.upgrade.title' | translate }}</h5>
      <a
        class="btn d-block bg-primary rounded text-white fw-bold font-size-16 ms-lg-auto ms-0"
        [href]="upgradeLink"
        target="_blank"
        >{{ 'home.upgrade.button.text' | translate }}</a
      >
    </div>
  </div>
</div>
