<div
  class="tier"
  *ngIf="{
    member: member$ | async,
    tier: tier$ | async,
    currentTier: currentTier$ | async
  } as data"
>
  <div class="tier-name-wrapper">
    <div class="tier-name">{{ data?.currentTier?.Name }}</div>
  </div>
  <div class="tier-container">
    <div class="tier-personal-information">
      <!-- Hide Image if no current tier -->
      <div class="image-container" *ngIf="data?.currentTier?.Image">
        <img [src]="data?.currentTier?.Image | contentImage : '7'" alt="{{ data?.currentTier?.Name }}" />
      </div>
      <div class="tier-personal-details">
        <div class="full-name">{{ data?.member?.FirstName }} {{ data?.member?.Surname }}</div>
        <div class="club-id">{{ 'tier.club.id.label' | translate }} {{ data?.member?.ExternalRefNumber }}</div>
      </div>
    </div>
    <!-- Barcode -->
    <div class="barcode-container d-lg-none d-flex">
      <ng-container *ngIf="barcode$ | async as barcode">
        <ng-container *ngIf="barcode.Barcode; else loadingRef">
          <qrcode
            (click)="openBarcodeModal(barcodeModal)"
            [qrdata]="barcode.Barcode"
            [allowEmptyString]="true"
            [size]="256"
            [level]="'M'"
            [usesvg]="true"
            [margin]="1"
          ></qrcode>
        </ng-container>

        <!-- Barcode Modal for mobile -->
        <ng-template #barcodeModal let-modal>
          <div class="tier-modal">
            <div class="tier-modal-header">
              <h4>{{ data?.member?.FirstName }} {{ data?.member?.Surname }}</h4>
              <i id="tier-close-btn" class="material-icons-outlined" (click)="modal.close()">cancel</i>
            </div>
            <div class="tier-modal-content">
              <qrcode
                [qrdata]="barcode.Barcode"
                [allowEmptyString]="true"
                [size]="256"
                [level]="'M'"
                [width]="168"
                [usesvg]="true"
                [margin]="1"
              ></qrcode>
            </div>
            <div class="tier-modal-footer">
              <h5>{{ 'tier.modal.scan.me' | translate }}</h5>
            </div>
          </div>
        </ng-template>
      </ng-container>
    </div>

    <div class="tier-points-details">
      <div class="points-wrapper">
        <div class="points-information">
          <div class="tier-points">
            {{ loyaltyPointsCard$ | async | number : '1.0-2' }}
          </div>
          <div class="tier-label">
            {{ 'tier.points.to.burn' | translate }}
          </div>
        </div>
        <div class="points-information">
          <div class="tier-points">
            {{ data?.tier?.PointsToNextTier | number : '1.0-2' }}
          </div>
          <div class="tier-label">
            <span>
              {{ 'tier.points.to.next.tier' | translate }}
            </span>
          </div>
        </div>
      </div>
      <a id="navigate-button" class="btn btn-primary" routerLink="/tiers">
        <span>{{ 'tier.navigate.button' | translate }}</span>
        <i class="material-icons">trending_flat</i>
      </a>
    </div>
  </div>
</div>

<!-- Loading template -->
<ng-template #loadingRef>
  <div class="loading-container">
    <fgbcl-loading></fgbcl-loading>
  </div>
</ng-template>
