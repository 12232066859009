import { CurrencyPipe, DatePipe } from "@angular/common";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { APP_INITIALIZER, EnvironmentProviders, ErrorHandler, inject, makeEnvironmentProviders } from "@angular/core";
import { Router } from "@angular/router";
import { AuthGuard, AuthService, DATE_OPTIONS, DEFAULT_DATE_OPTIONS, DateOptions, ErrorStateService, FgbErrorHandler, FirstTimeRegistrationInterceptor, HttpErrorInterceptor, LANGUAGE, LoggingService, MaintenanceGuard, MemberQuery, RegistrationStepService, TokenRefreshIntereptor, TranslationLoader } from "@fgb/core";
import { TranslateService } from "@ngx-translate/core";
import { CookieService } from "ngx-cookie-service";
import { ColorMode } from "../injection-tokens";

export interface AppConfig{
    dateOptions: Partial<DateOptions>;
    defaultColorMode?: ColorMode;
}

export function provideAppConfig(appConfig: AppConfig): EnvironmentProviders{
    // initialize defaults
    const dateOptions = { ...DEFAULT_DATE_OPTIONS, ...appConfig.dateOptions };

    return makeEnvironmentProviders([
        DatePipe,
        CurrencyPipe,
        MaintenanceGuard,
        CookieService,
        AuthGuard,
        AuthService,
        {
            provide: DATE_OPTIONS,
            useValue: dateOptions,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenRefreshIntereptor,
            multi: true,
            deps: [AuthService],
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: FirstTimeRegistrationInterceptor,
            multi: true,
            deps: [MemberQuery, RegistrationStepService, LoggingService, Router],
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true,
            deps: [ErrorStateService, LoggingService, TranslateService, Router, AuthService],
        },
        {
            provide: ErrorHandler,
            useClass: FgbErrorHandler
        },
        {
            provide: LANGUAGE,
            useValue: { label: 'EN', lang: 'en-GB', lcid: '2057', flag: 'GB' },
            multi: true
          },
          {
            provide: LANGUAGE,
            useValue: { label: 'GR', lang: 'el-GR', lcid: '1032', flag: 'GR' },
            multi: true
          },
        {
            provide: APP_INITIALIZER,
            useFactory: () => {
                // Initialize translation
                inject(TranslationLoader).init();

                return () => {}
            },
            multi: true
        }
    ])
}