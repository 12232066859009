import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { BalanceQuery, Barcode, BarcodeQuery, BarcodeService, LoyaltyCustomerTier, LoyaltyTier, MemberDetails, MemberQuery, TierQuery, TierService, config } from '@fgb/core';
import { ScreenType } from '@fgb/portal-component-library/src/lib/directives';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'fgb-tier',
  templateUrl: './tier.component.html',
  styleUrls: ['./tier.component.scss']
})
export class TierComponent implements OnInit, OnDestroy{
  member$: Observable<MemberDetails | undefined>;
  tier$: Observable<LoyaltyCustomerTier | undefined>;
  barcode$: Observable<Barcode>;
  currentTier$: Observable<LoyaltyTier | undefined>;
  loyaltyPointsCard$: Observable<number | undefined>;
  screenType = ScreenType;
  private subscription: Subscription;

  constructor(private tierService: TierService,
              private tierQuery: TierQuery, 
              private memberQuery: MemberQuery,
              private barcodeQuery: BarcodeQuery,
              private barcodeService: BarcodeService,
              private ngModal: NgbModal,
              private balanceQuery: BalanceQuery,
              private breakpoint: BreakpointObserver) { }

  ngOnInit(): void {

    this.subscription = this.breakpoint.observe(['(max-width: 991px)']).subscribe(result => {
      if(result.matches){
        this.barcodeService.fetchBarcode().toPromise();
      }
    });
    
    this.loyaltyPointsCard$ = this.balanceQuery.selectPurse(config.purseConfig.virtualLoyalty);
    this.tierService.fetchTiersWithPositions(this.memberQuery.getUserContext().externalRef).toPromise();
    this.tier$ = this.tierQuery.customerTier$;
    this.currentTier$ = this.tierQuery.getCurrentTier();
    this.barcode$ = this.barcodeQuery.select();
    this.member$ = this.memberQuery.selectMemberDetails();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  openBarcodeModal(modal: any): void{
    this.ngModal.open(modal, { centered: true });
  }

}
