import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { config, MemberQuery } from '@fgb/core';
import { Observable, shareReplay, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserProfilePicService {
  // Added shareReplay so that the image is only fetched once
  profilePic$ = this.fetchProfilePic().pipe(shareReplay());

  constructor(private http: HttpClient, private memberQuery: MemberQuery) { }

  private fetchProfilePic(): Observable<Blob> {
    let headers = new HttpHeaders();

    let token = localStorage.getItem('token');
    if (token) {
      headers = headers.append('Authorization', `Bearer ${token}`);
    }

    return this.memberQuery.userContext$.pipe(switchMap(user => this.http.get(`${config.baseApiUrl}/api/content/getcustomerimage?portalId=${user?.portalId}`, { headers, responseType: 'blob' }))) ;
  }
  
}
