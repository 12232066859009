import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { SurveyItemComponent } from './components/survey/survey-item/survey-item.component';
import { SurveyItemAnswerComponent } from './components/survey/survey-item-answer/survey-item-answer.component';
import { TransferArenaCashComponent } from './components/transfer-arena-cash/transfer-arena-cash/transfer-arena-cash.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TransferArenaMultipleAccountsComponent } from './components/transfer-arena-cash/transfer-arena-multiple-accounts/transfer-arena-multiple-accounts.component';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { ProgressBarComponent } from './components/average-points/progress-bar/progress-bar.component';
import { SurveyItemQuizComponent } from './components/survey/survey-item/survey-item-quiz/survey-item-quiz.component';
import { SurveyItemPredictorComponent } from './components/survey/survey-item/survey-item-predictor/survey-item-predictor.component';
import { SurveyItemPollComponent } from './components/survey/survey-item/survey-item-poll/survey-item-poll.component';
import { SurveyCompletedPollComponent } from './components/survey/survey-item-answer/completed/survey-completed-poll/survey-completed-poll.component';
import { SurveyCompletedQuizComponent } from './components/survey/survey-item-answer/completed/survey-completed-quiz/survey-completed-quiz.component';
import { SurveyCompletedPollItemComponent } from './components/survey/survey-item-answer/completed/survey-completed-poll/survey-completed-poll-item/survey-completed-poll-item.component';
import { SurveyCompletedQuizTextComponent } from './components/survey/survey-item-answer/completed/survey-completed-quiz/survey-completed-quiz-text/survey-completed-quiz-text.component';
import { SurveyCompletedQuizImageAndTextComponent } from './components/survey/survey-item-answer/completed/survey-completed-quiz/survey-completed-quiz-image-and-text/survey-completed-quiz-image-and-text.component';
import { SurveyCompletedPredictorComponent } from './components/survey/survey-item-answer/completed/survey-completed-predictor/survey-completed-predictor.component';
import { PredictorCompletedPendingComponent } from './components/survey/survey-item-answer/completed/survey-completed-predictor/predictor-completed-pending/predictor-completed-pending.component';
import { PredictorCompletedProcessedComponent } from './components/survey/survey-item-answer/completed/survey-completed-predictor/predictor-completed-processed/predictor-completed-processed.component';
import { LeaderboardComponent } from './components/leaderboard/leaderboard.component';
import { RouterModule } from '@angular/router';
import { JokesCardComponent } from './components/jokes-card/jokes-card.component';
import { ProgressBarGameAttendanceComponent } from './components/progress-bar-game-attendance/progress-bar-game-attendance.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { OffersListItemComponent } from './components/offers/offers-list-item/offers-list-item.component';
import { OffersListComponent } from './components/offers/offers-list/offers-list.component';
import { OffersDetailsComponent } from './components/offers/offers-details/offers-details.component';
import { OfferTermsModalComponent } from './components/offers/offer-terms-modal/offer-terms-modal.component';
import { PartnersListItemComponent } from './components/offers/partners-list-item/partners-list-item.component';
import { OffersToolbarComponent } from './components/offers/offers-toolbar/offers-toolbar.component';
import { OfferEarnTabComponent } from './components/offers/offer-earn-tab/offer-earn-tab.component';
import { OffersMapComponent } from './components/offers/offers-map/offers-map.component';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ShortLongDescriptionModule } from '@fgb/portal-component-library/src/lib/shared/short-long-description';
import { ErrorsModule } from '@fgb/portal-component-library/src/lib/shared/errors';
import { FilterByModule } from '@fgb/portal-component-library/src/lib/shared/filter-by';
import { OfferSearchBarModule } from '@fgb/portal-component-library/src/lib/club/offer-search-bar';
import { LeaderboardMonthlyComponent } from './components/leaderboard-monthly/leaderboard-monthly.component';
import { CountdownModule } from '@fgb/portal-component-library/src/lib/shared/countdown';
import { TimeSinceModule } from '@fgb/portal-component-library/src/lib/shared/time-since';
import { ScorePredictorModule } from './components/score-predictor/score-predictor.module';
import { FeaturedSurveysComponent } from './components/survey/featured-surveys/featured-surveys.component';
import { SurveyCompletedQuizImageComponent } from './components/survey/survey-item-answer/completed/survey-completed-quiz/survey-completed-quiz-image/survey-completed-quiz-image.component';
import { ClubNotificationComponent } from './components/club-notification/club-notification.component';
import { NotificationButtonComponent } from './components/notification/notification-button/notification-button.component';
import { NotificationModule } from '@fgb/portal-component-library/src/lib/club/notification';

@NgModule({
  declarations: [
    SurveyItemComponent,
    SurveyItemAnswerComponent,
    SurveyCompletedQuizComponent,
    SurveyCompletedPollComponent,
    SurveyCompletedPollItemComponent,
    SurveyCompletedPredictorComponent,
    TransferArenaCashComponent,
    TransferArenaMultipleAccountsComponent,
    SurveyCompletedQuizTextComponent,
    SurveyCompletedQuizImageAndTextComponent,
    ProgressBarComponent,
    SurveyItemQuizComponent,
    SurveyItemPredictorComponent,
    SurveyItemPollComponent,
    PredictorCompletedPendingComponent,
    PredictorCompletedProcessedComponent,
    LeaderboardComponent,
    JokesCardComponent,
    ProgressBarGameAttendanceComponent,
    OffersListItemComponent,
    OffersListComponent,
    OffersToolbarComponent,
    OffersMapComponent,
    OfferEarnTabComponent,
    OffersDetailsComponent,
    OfferTermsModalComponent,
    PartnersListItemComponent,
    LeaderboardMonthlyComponent,
    FeaturedSurveysComponent,
    SurveyCompletedQuizImageComponent,
    ClubNotificationComponent,
    NotificationButtonComponent
  ],
  imports: [
    NgbModule,
    SharedModule,
    CommonModule,
    CarouselModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ShortLongDescriptionModule,
    OfferSearchBarModule,
    NotificationModule,
    ErrorsModule,
    FilterByModule,
    CountdownModule,
    TimeSinceModule,
    ToastrModule.forRoot(),
    InlineSVGModule.forRoot(),
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: function HttpLoaderFactory(http: HttpClient) {
          return new TranslateHttpLoader(http, './assets/i18n/', '.json');
        },
        deps: [HttpClient],
      },
    }),
    RouterModule,
  ],
  exports: [
    SurveyItemComponent,
    TransferArenaCashComponent,
    TransferArenaMultipleAccountsComponent,
    ProgressBarComponent,
    LeaderboardComponent,
    JokesCardComponent,
    ProgressBarGameAttendanceComponent,
    OffersListItemComponent,
    OffersListComponent,
    OffersDetailsComponent,
    OfferTermsModalComponent,
    OffersToolbarComponent,
    OfferEarnTabComponent,
    PartnersListItemComponent,
    LeaderboardMonthlyComponent,
    ScorePredictorModule,
    FeaturedSurveysComponent,
    NotificationButtonComponent
  ]
})
export class ClubModule {}
