<div class="container">
  <div class="back-btn pt-3 cursor-pointer d-none d-lg-block" [routerLink]="'/rewards'">
    <span class="material-icons align-middle fw-bold pe-2">navigate_before</span>
    <span class="font-size-14 fw-bold">Back </span>
  </div>
</div>

<ng-container *inPopulation="[populations.NonMember]">
  <div class="container mt-3 mb-2 d-none d-lg-flex">
    <fgb-member-upgrade-card></fgb-member-upgrade-card>
  </div>
</ng-container>

<!-- Points Summary -->
<div class="container back-btn-container">
  <fgb-points-summary-bar [backButtonLink]="'/rewards'"></fgb-points-summary-bar>
</div>

<ng-container *inPopulation="[populations.NonMember]">
  <div class="container mt-3 mb-3 d-flex d-lg-none">
    <fgb-member-upgrade-card></fgb-member-upgrade-card>
  </div>
</ng-container>

<fgbcl-marketplace-details [itemId]="id" [actionLabels]="actionLabels"></fgbcl-marketplace-details>
