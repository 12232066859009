import { Component, Input, input, InputSignal, ChangeDetectionStrategy, Signal, computed } from '@angular/core';
import { Survey, SurveyType } from '@fgb/core';

@Component({
  selector: 'fgb-survey-item-poll',
  templateUrl: './survey-item-poll.component.html',
  styleUrls: ['./survey-item-poll.component.scss', '../../survey.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SurveyItemPollComponent{
  @Input() hideTitle: boolean = false;
  survey: InputSignal<Survey | undefined> = input<Survey | undefined>(undefined);
  selectedAnswers: Signal<any> = computed(() => this.survey()?.SurveyAnswers.filter((sa) => sa.UserSelected));
  pointsWon: Signal<number> = computed(() => {
    if( this.selectedAnswers() && this.selectedAnswers().length > 0 ){
      return this.selectedAnswers()[0].PointValue / 100;
    }
    return 0;
  });
  surveyType = SurveyType;
}
