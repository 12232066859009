<div class="px-4 pt-3 mb-3">
  <div class="row mb-2 fw-bold font-size-12">
    <div class="col-2 col-lg-3 text-black">{{ 'pick.player.leaderboard.rank' | translate }}</div>
    <div class="col-6 text-black">{{ 'pick.player.leaderboard.name' | translate }}</div>
    <div class="col-4 col-lg-3 text-end text-black">{{ 'pick.player.leaderboard.points' | translate }}</div>
  </div>
  <div
    class="font-size-12 mb-2 fantasy-row"
    *ngFor="let entry of fantasyEntries; let i = index"
    [ngClass]="{ 'current-user-row': entry.IsCurrentUser }"
  >
    <div class="row my-2" [ngClass]="{ 'rounded-4 bg-primary text-white py-2 px-2 mb-2 current-user': entry.IsCurrentUser }">
      <div class="col-2 col-lg-3 fw-bold leaderboard-colour">
        {{ entry.Position }}
      </div>
      <div class="col-6 text-capitalize leaderboard-colour" [ngClass]="{ 'text-info': !entry.IsCurrentUser }">
        <div class="entry-name">{{ entry.FullName }}</div>
      </div>
      <div class="col-4 col-lg-3 text-end leaderboard-colour" [ngClass]="{ 'text-info': !entry.IsCurrentUser }">
        {{ entry.Points / 100 }}
      </div>
    </div>
  </div>
</div>
