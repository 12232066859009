<div class="survey-container overflow-hidden rounded predictor row">
  <div class="survey-header mb-1 col-12 col-lg-6">
    <div class="image-container rounded-top-4 border-gold">
      <img *ngIf="survey.ImageURL" src="{{ survey.ImageURL | contentImage : '23' }}" alt="{{ survey.ImageURL }}" />
      <div
        *ngIf="!survey.ImageURL"
        class="h-100 d-flex flex-column justify-content-center align-items-center bg-predictor py-4 placeholder-predictor"
      >
        <div class="text-white predictor-placeholder-title text-uppercase">
          {{ 'predictor.noimage.placeholder.title' | translate }}
        </div>
      </div>
    </div>
    <div class="survey-info d-flex flex-column align-content-between border rounded-bottom-4">
      <div class="d-flex justify-content-between mb-4">
        <div class="d-flex align-items-center">
          <div class="icon material-icons text-primary predictor-icon" aria-hidden="true">flaky</div>
        </div>
        <div class="d-flex align-items-center text-primary text-end predictor-countdown-icon">
          <div class="icon material-icons me-2" aria-hidden="true">schedule</div>
          <fgbcl-countdown [endDateTime]="survey.EndDate" [isUTC]="true" class="font-size-14 font-heading"></fgbcl-countdown>
        </div>
      </div>
      <div class="survey-title mb-3 font-default text-start">
        <div class="font-size-14">{{ survey.SurveyQuestion }}</div>
      </div>
      <div class="d-flex align-items-end mt-4 h-100">
        <button
          *ngIf="!survey.HasBeenAnsweredByUser"
          (click)="submitAnswer()"
          class="btn btn-primary col-6 font-size-14 font-default fw-bold mx-auto"
          [disabled]="disableSubmitButton"
        >
          {{ 'predictor.submit.button' | translate }}
        </button>
        <div
          class="d-flex flex-row w-100 justify-content-center my-1 align-items-center text-primary entered-predictor"
          *ngIf="survey.HasBeenAnsweredByUser"
        >
          <div class="text-uppercase font-italic font-heading font-size-20 px-2 pt-1">{{ 'predictor.entered' | translate }}</div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-12 col-lg-6">
    <ng-container *ngIf="survey.HasBeenAnsweredByUser; else answer">
      <fgb-survey-item-answer-completed-predictor [survey]="survey"></fgb-survey-item-answer-completed-predictor>
    </ng-container>
    <ng-template #answer>
      <div class="h-100 w-100 d-table">
        <div class="d-table-cell align-middle">
          <fgb-survey-item-answer #itemAnswer [survey]="survey" (predictorAnswers)="hasUserAnswered()"></fgb-survey-item-answer>
        </div>
      </div>
    </ng-template>
  </div>
</div>
