import { Component, Input, OnInit } from '@angular/core';
import { LottoService, MemberQuery } from '@fgb/core';
import { HideMobileNav } from '@fgb/portal-component-library/src/lib/directives';
import { Populations } from 'src/app/shared/utilities/populations';

@Component({
  selector: 'fgb-lotto-detail-page',
  templateUrl: './lotto-detail-page.component.html',
  styleUrls: ['./lotto-detail-page.component.scss'],
  hostDirectives: [HideMobileNav],
})
export class LottoDetailPageComponent implements OnInit {
  @Input() id: number = 0;
  populations = Populations;

  constructor(private lottoService: LottoService, private memberQuery: MemberQuery) {}

  ngOnInit() {
    const isFreeMember = this.memberQuery.getPopulationName() === Populations.NonMember;
    if (isFreeMember) {
      this.lottoService.lockAllItems();
    }
  }
}
