<div class="banner d-lg-flex d-none" role="banner">
  <h1 class="text-uppercase">{{ name$ | async }}</h1>
</div>

<ng-container *notInPopulation="[populations.NonMember]">
  <div class="order-2 order-lg-1">
    <fgb-account-nav></fgb-account-nav>
  </div>
</ng-container>

<div class="container tier-container order-1 order-lg-2">
  <fgb-tier-v2></fgb-tier-v2>
</div>

<div class="container order-3">
  <div class="row">
    <div class="col-12">
      <h5 class="text-start mb-2 mt-lg-0 mt-0">{{ 'account.details.title' | translate }}</h5>
      <fgb-account-details></fgb-account-details>
    </div>
  </div>
</div>
