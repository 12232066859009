import { Component, OnInit } from '@angular/core';
import { MarketplaceService, MemberQuery } from '@fgb/core';
import { firstValueFrom } from 'rxjs';
import { Populations } from 'src/app/shared/utilities/populations';

@Component({
  selector: 'fgb-events-page',
  templateUrl: './events-page.component.html',
  styleUrls: ['./events-page.component.scss'],
})
export class EventsPageComponent implements OnInit {
  constructor(private marketplaceService: MarketplaceService, private memberQuery: MemberQuery) {}

  ngOnInit() {
    const isFreeMember = this.memberQuery.getPopulationName() === Populations.NonMember;
    firstValueFrom(this.marketplaceService.fetchMarketplaceItems()).then(() => {
      if (isFreeMember) {
        this.marketplaceService.lockAllItems();
      }
    });
  }
}
