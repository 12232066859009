<div class="w-100 bg-white p-3 position-relative popup-add-to-home-screen-div">
  <div class="text-primary cursor-pointer text-end position-absolute popup-close-button">
    <span class="material-icons text-end" (click)="closeModal()">cancel</span>
  </div>
  <!-- Android -->
  <div class="m-2 text-center font-size-14 text-grey" *ngIf="detectedDevice === 'android'">
    <div class="mb-2 me-3">
      <img src="favicon.png" draggable="false" class="popup-image" />
    </div>
    <div class="mb-4 mx-4 fw-bold">{{ 'popup.add.to.home.screen.text' | translate }}</div>
    <div>1. Click <i class="icon material-icons">more_vert</i> then</div>
    <div>2. Select "Add to Home Screen"</div>
    <div>3. Confirm</div>
  </div>
  <!-- iOS with Safari -->
  <div class="m-2 font-size-14 text-grey" *ngIf="detectedDevice === 'ios'">
    <div class="d-flex align-items-center mb-3">
      <img src="favicon.png" draggable="false" class="popup-image me-3" />
      <div class="fw-bold">{{ 'popup.add.to.home.screen.text' | translate }}</div>
    </div>
    <div>1. Click <i class="icon material-icons">ios_share</i> then</div>
    <div>2. Select "Add to Home Screen <span class="material-icons-outlined">add_box</span>"</div>
  </div>
</div>
<div class="popup-add-to-home-screen-pointer-div" *ngIf="detectedDevice === 'ios'"></div>
