import { Component, OnInit } from '@angular/core';
import { RewardsWalletService, RewardsWalletItem, RewardsWalletQuery } from '@fgb/core';
import { ScreenType } from '@fgb/portal-component-library/src/lib/directives';
import { Observable, firstValueFrom } from 'rxjs';

@Component({
  selector: 'fgb-wallet-page',
  templateUrl: './wallet-page.component.html',
  styleUrls: ['./wallet-page.component.scss'],
})
export class WalletPageComponent implements OnInit {
  walletItemsUnclaimed$: Observable<RewardsWalletItem[]>;
  screenType = ScreenType;

  constructor(private rewardWalletItemsService: RewardsWalletService, private rewardWalletQuery: RewardsWalletQuery) {}

  async ngOnInit() {
    firstValueFrom(this.rewardWalletItemsService.fetchWalletItems());
    this.walletItemsUnclaimed$ = this.rewardWalletQuery.selectRewardWalletItemsByStatus(false);
  }
}
