<div class="points-summary-bar d-flex justify-content-between d-lg-none">
  <!-- Back Button -->
  <div class="text-danger cursor-pointer d-flex align-items-center d-lg-none" [routerLink]="'/rewards'">
    <span class="material-icons align-middle fw-bold pe-2">navigate_before</span>
    <span class="font-size-14 fw-bold">{{ 'points.summary.bar.back.label' | translate }}</span>
  </div>
  <ng-container *ngIf="showPoints">
    <!-- Points Bar -->
    <div class="justify-content-center flex-grow-1 flex-shrink-1 h-100">
      <fgb-points-summary></fgb-points-summary>
    </div>
  </ng-container>
</div>
