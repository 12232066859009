import { Component, OnInit } from '@angular/core';
import { MarketplaceItem, MarketplaceQuery, MarketplaceService, MemberQuery } from '@fgb/core';
import { ActivatedRoute } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { Populations } from 'src/app/shared/utilities/populations';

@Component({
  selector: 'fgb-marketplace-terms-page',
  templateUrl: './marketplace-terms-page.component.html',
  styleUrls: ['./marketplace-terms-page.component.scss'],
})
export class MarketplaceTermsPageComponent implements OnInit {
  marketplaceItem: MarketplaceItem | undefined;
  constructor(
    private marketplaceQuery: MarketplaceQuery,
    private marketplaceService: MarketplaceService,
    private route: ActivatedRoute,
    private memberQuery: MemberQuery
  ) {}

  ngOnInit() {
    let id = +(this.route.snapshot.paramMap.get('id') || 0);
    const isFreeMember = this.memberQuery.getPopulationName() === Populations.NonMember;

    firstValueFrom(this.marketplaceService.fetchMarketplaceItemById(id)).then(() => {
      this.marketplaceItem = this.marketplaceQuery.getEntity(id);
      if (isFreeMember) {
        this.marketplaceService.lockAllItems();
      }
    });
  }
}
