import { Component, OnInit } from '@angular/core';
import { CartQuery } from '@fgb/core';
import { CartModalComponent } from '@fgb/portal-component-library/src/lib/loyalty/cart-item/cart-modal';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';

@Component({
  selector: 'fgb-floating-cart-button',
  templateUrl: './floating-cart-button.component.html',
  styleUrls: ['./floating-cart-button.component.scss'],
})
export class FloatingCartButtonComponent implements OnInit {
  isFooterInViewport = false;
  footerHeight = '20px';
  totalItems$: Observable<number>;
  constructor(private cartQuery: CartQuery, private modalService: NgbModal) {}

  ngOnInit() {
    this.totalItems$ = this.cartQuery.updateTotalItems();
  }

  openCartModal() {
    this.modalService.open(CartModalComponent, { windowClass: 'mt-5' });
  }
}
