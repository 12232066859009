import { Component, OnInit } from '@angular/core';
import { ChildQuery, ChildService, ChildViewModel, MembershipOptionService } from '@fgb/core';
import { Observable, Subscription, firstValueFrom } from 'rxjs';

@Component({
  selector: 'fgb-guardian-page',
  templateUrl: './guardian-page.component.html',
  styleUrls: ['./guardian-page.component.scss'],
})
export class GuardianPageComponent implements OnInit {
  public childLimit: number = 10;
  public emptyChildren: any[];
  isGuardianSubscription: Subscription;
  isGuardian: boolean;

  children$: Observable<ChildViewModel[]>;
  freeChildren: ChildViewModel[];

  constructor(private childQuery: ChildQuery, private membershipOptionService: MembershipOptionService, private childService: ChildService) {}

  ngOnInit() {
    firstValueFrom(this.childService.fetchGuardian()).then(() => {
      firstValueFrom(this.membershipOptionService.fetchMembershipOptions());
    });
    this.isGuardianSubscription = this.childQuery.selectGuardian().subscribe(guardian => {
      this.isGuardian = !!guardian;
    });
  }

  ngOnDestroy(){
    if(this.isGuardianSubscription){
      this.isGuardianSubscription.unsubscribe();
    }
  }

  becomeGuardian(): void {
    firstValueFrom(this.childService
      .registerAsGuardian())
      .then(() => (this.isGuardian = true));
  }
}
