<div class="container">
  <div class="offer-earn-box border border-disabled rounded py-3 px-4">
    <h2 class="mt-0 text-primary text-center">{{ 'offers.earn.tab.title' | translate }}</h2>
    <div class="row mt-3">
      <!--Earn 1-->
      <div class="col-12 col-lg-4 d-flex flex-column justify-content-center align-items-center">
        <div class="offer-earn-icon text-primary centered position-relative border border-primary">
          <div class="offer-earn-num position-absolute centered font-size-14 fw-bold">1</div>
          <div class="icon material-icons" aria-hidden="true">ads_click</div>
        </div>
        <div class="font-size-12 mt-3 text-center px-4">{{ 'offers.earn.tab.step.one' | translate }}</div>
      </div>
      <!--Earn 2-->
      <div class="col-12 col-lg-4 d-flex flex-column justify-content-center align-items-center">
        <div class="offer-earn-icon text-secondary centered position-relative border border-secondary">
          <div class="offer-earn-num position-absolute centered font-size-14 fw-bold">2</div>
          <div class="icon material-icons" aria-hidden="true">shopping_cart</div>
        </div>
        <div class="font-size-12 mt-3 text-center px-4">{{ 'offers.earn.tab.step.two' | translate }}</div>
      </div>
      <!--Earn 3-->
      <div class="col-12 col-lg-4 d-flex flex-column justify-content-center align-items-center">
        <div class="offer-earn-icon text-tertiary centered position-relative border border-tertiary">
          <div class="offer-earn-num position-absolute centered font-size-14 fw-bold">3</div>
          <div class="icon material-icons" aria-hidden="true">emoji_events</div>
        </div>
        <div class="font-size-12 mt-3 text-center px-4">{{ 'offers.earn.tab.step.three' | translate }}</div>
      </div>
    </div>
  </div>
</div>
