import { inject, Injectable } from '@angular/core';
import { TierStore } from './tier.store';
import { map, switchMap, tap } from 'rxjs/operators';
import { cacheable } from '@datorama/akita';
import { ApiService, LoggingService, LoyaltyCustomerTier, LoyaltyTier, MemberQuery } from '@fgb/core';

@Injectable({
  providedIn: 'root'
})
export class TierService {
  private api: ApiService = inject(ApiService);
  private tierStore: TierStore = inject(TierStore);
  private logService: LoggingService = inject(LoggingService);
  private memberQuery: MemberQuery = inject(MemberQuery);

  fetchTiers(){
    const request$ = this.memberQuery.userContext$.pipe(
      switchMap(user => this.api.get<LoyaltyTier>(`loyalty/tiers/${user?.clubId}`).pipe(
        tap((tiers) => {
          this.logService.debug(`Fetching Tiers`);
          this.tierStore.set(tiers);
        })) 
    ));
    return cacheable(this.tierStore, request$).pipe(map(() => null));
  }

  fetchTier() {
    const purseNumber: number = 7;
    const request$ = this.memberQuery.userContext$.pipe(
      switchMap(user => this.api.getAnySingle<LoyaltyCustomerTier>(`loyalty/tiers/${user?.clubId}/${user?.portalId}/${purseNumber}`)
      .pipe(
        tap((tier) => {
          if( tier ){
            this.logService.debug(`Fetching customer loyalty tier`);
            this.tierStore.setTier(tier);
            this.tierStore.set(tier.Tiers);
          }
        })
      )));
    return cacheable(this.tierStore, request$).pipe(map(() => null));
  } 

  fetchTiersWithPositions() {
    const request$ = this.memberQuery.userContext$.pipe(
      switchMap(user => this.api.getAnySingle<LoyaltyCustomerTier>(`loyalty/tiers/v2/${user?.externalRef}`)
      .pipe(
        tap((tier) => {
          if( tier ){
            this.logService.debug(`Fetching customer loyalty tier with positions`);
            this.tierStore.setTier(tier);
            this.tierStore.set(tier.Tiers);
          }
        })
      )));
    return cacheable(this.tierStore, request$).pipe(map(() => null));
  } 

}
