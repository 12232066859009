<div class="points-management-placeholder mb-4">
  <div class="row">
    <div class="col-lg-5 col-12">
      <img src="assets/images/placeholder.jpg" />
    </div>

    <div class="col-lg-2 col-12 d-flex flex-column justify-content-between px-lg-0 px-4">
      <div class="row">
        <div class="col-6 col-md-12 points-balance-box pt-2 mb-3 ps-lg-4">
          <h5 class="points-balance-text fw-bold mb-0 mt-4">
            {{ 'points.management.points.balance' | translate }}
          </h5>
          <h3 class="points-balance-value text-secondary">{{ loyaltyPointsCard$ | async | number : '1.0-0' }}</h3>
        </div>

        <div *ngIf="memberDetails$ | async as memberDetails" class="col-6 col-md-12 account-number-box pt-2 ps-lg-4">
          <h5 class="account-number-text mb-0 mt-lg-0 mt-4">{{ 'points.management.account.number' | translate }}</h5>
          <h5 class="account-number-value text-primary">{{ memberDetails!.ExternalRefNumber }}</h5>
        </div>
      </div>
    </div>

    <div class="col-lg-5 placeholder-text-box col-12 px-lg-0 px-4">
      <p class="pe-lg-5 py-4">
        {{ 'points.management.card.description' | translate }}
      </p>
    </div>
  </div>
</div>
