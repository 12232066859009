import { Component, OnInit, OnDestroy } from '@angular/core';
import { FantasyGameQuery, FantasyGame, FantasyLeaderboardQuery, FantasyLeaderboardService, FantasyLeaderboard } from '@fgb/core';
import { Observable, Subscription, combineLatest, map } from 'rxjs';
import { Router } from '@angular/router';
import * as _moment from 'moment';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { OwlCarouselSettings } from 'src/app/shared/utilities/owl-carousel-settings';

const moment = _moment;

@Component({
  selector: 'fgb-fantasy-games-page',
  templateUrl: './fantasy-games-page.component.html',
  styleUrls: ['./fantasy-games-page.component.scss'],
})
export class FantasyGamesPageComponent implements OnInit, OnDestroy {
  currentGames$: Observable<FantasyGame[]>;
  pendingGames$: Observable<FantasyGame[]>;
  pendingPeriod: number = 48;

  yearlyLeaderboards: FantasyLeaderboard[];
  monthlyLeaderboard: any[] = [];

  showMonthly: boolean = true;
  year: number = new Date().getFullYear();

  customOptions: OwlOptions = {
    items: 1,
    margin: OwlCarouselSettings.Margin,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    navSpeed: 700,
    center: false,
    nav: true,
    dots: false,
    startPosition: new Date().getMonth(),
    navText: [
      '<i class="material-icons fw-bold">chevron_left</i>',
      '<i class="material-icons fw-bold">chevron_right</i>',
    ],
    stagePadding: 0,
  };

  customOptions2: OwlOptions = {
    items: 1,
    autoHeight: true,
    autoWidth: true,
    margin: OwlCarouselSettings.Margin,
    autoplay: false,
    navSpeed: 2000,
    responsive: {
      0: { autoWidth: false },
      740: { autoWidth: true, autoHeight: false },
    },
  };
  hasSelectedPlayer$: Observable<boolean>;
  fantasySub: Subscription;

  constructor(
    private fantasyQuery: FantasyGameQuery,
    private router: Router,
    private fantasyLeaderboardService: FantasyLeaderboardService,
    private fantasyLeaderboardQuery: FantasyLeaderboardQuery
  ) {}

  ngOnInit() {
    this.currentGames$ = this.fantasyQuery.selectCurrentGames();
    this.hasSelectedPlayer$ = this.currentGames$.pipe(map((x) => x.some((g) => g.GamePlayers.some((p) => p.IsSelected))));
    this.pendingGames$ = this.fantasyQuery.selectFinshedFantasyGamesAfterDate(moment.utc().subtract(this.pendingPeriod, 'hours'));
    this.fantasySub = combineLatest([
      this.fantasyLeaderboardService.fetchMonthlyLeaderboard(3),
      this.fantasyLeaderboardService.fetchYearlyLeaderboard(3),
    ]).subscribe((x) => {
      let monthly = this.fantasyLeaderboardQuery.getMonthlyLeaderboard() as FantasyLeaderboard[];
      this.yearlyLeaderboards = this.fantasyLeaderboardQuery.getYearlyLeaderboard();

      let today = new Date();
      let currentMonth = today.getMonth() + 1;
      let currentYear = today.getFullYear() - 1;

      for (let index = 1; index <= 12; index++) {
        currentMonth++;
        if (currentMonth > 12) {
          currentMonth = today.getMonth();
          currentYear = today.getFullYear();
        }

        if (currentYear == today.getFullYear() && currentMonth > today.getMonth() + 1) {
          this.monthlyLeaderboard.push({
            month: moment.monthsShort(currentMonth - 1) + ' ' + currentYear,
            entries: [],
          });
        } else {
          this.monthlyLeaderboard.push({
            month: moment.monthsShort(currentMonth - 1) + ' ' + currentYear,
            entries: monthly.filter((x: FantasyLeaderboard) => {
              return (x.FantasyMonth as unknown as number) === currentMonth;
            }),
          });
        }
      }
    });
  }

  ngOnDestroy() {
    if (this.fantasySub) {
      this.fantasySub.unsubscribe();
    }
  }

  goToDetail(gameId: number) {
    this.router.navigate([`games/pickaplayer/${gameId}`]);
  }

  hasActivePlayer(game: FantasyGame) {
    return game.GamePlayers.some((g) => g.IsSelected);
  }
}
