<div class="w-100 d-md-flex d-block px-0">
  <!--Left Side-->
  <div class="shared-login-banner d-md-block d-none">
    <img draggable="false" src="assets/images/login-registration/login-banner-2024.jpg" />
  </div>
  <div class="bg-dark-green d-flex w-100 d-md-none m-auto mobile-header">
    <img draggable="false" src="assets/images/club-logo.png" />
  </div>
  <!--Right Side-->
  <div class="p-lg-4 p-2 right-side-login bg-light-grey">
    <form class="reset-password container mt-lg-0 mt-3" (submit)="submit()" [formGroup]="formReset">
      <h4 class="reset-password-title mb-4">{{ 'reset.password.title' | translate : { fgbdefault: 'RESET YOUR PASSWORD' } }}</h4>
      <p class="font-size-14 mb-4">
        {{ 'reset.password.instruction' | translate : { fgbdefault: 'Enter your email to reset your password:' } }}
      </p>

      <div class="mb-3">
        <div class="form-floating">
          <input class="form-control col-12 col-lg-9 me-2" formControlName="email" />
          <label>{{ 'reset.password.label' | translate : { fgbdefault: 'Email Address' } }}</label>
        </div>
        <div class="text-danger font-size-12 mt-2" *ngIf="formReset.get('email')?.touched && formReset.get('email')?.invalid">
          {{ 'reset.pw.warn' | translate }}
        </div>
        <div class="text-danger font-size-12 mt-2" *ngIf="!isSuccess">
          {{ errorMessage }}
        </div>
      </div>
      <p class="mt-3 mb-5 font-size-14" [innerHtml]="'reset.password.message' | translate | markdownTranslate"></p>
      <div class="button-wrapper d-flex justify-content-center align-items-center flex-column">
        <button [disabled]="formReset.invalid" type="submit" class="btn btn-primary round">
          {{ 'reset.password.button' | translate : { fgbdefault: 'Send Code' } }}
        </button>
        <p *ngIf="isSuccess" class="font-size-14 text-success text-center mt-3">
          {{ 'user.reset.password.success' | translate : { fgbdefault: 'Check your email to change password!' } }}
        </p>
      </div>
    </form>
  </div>
</div>
