import { AfterViewInit, Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { AnnouncementModel } from '@fgb/core';
import { Subject } from 'rxjs';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'fgbcl-announcement-content',
  templateUrl: './announcement-content.component.html',
  styleUrls: ['./announcement-content.component.scss'],
})
export class AnnouncementContentComponent implements OnInit, AfterViewInit {
  @Input() announcement: AnnouncementModel;
  @ViewChild('link') private _link: ElementRef<HTMLAnchorElement>;
  isDisabled: boolean;
  isEnableTarget: boolean = false;
  private _destroyed$ = new Subject<void>();

  constructor(private renderer: Renderer2, private breakpointObserver: BreakpointObserver) {}

  ngOnInit(): void {
    this.isDisabled = !!!this.announcement.LinkUrl;

    // detect screen size changes
    this.breakpointObserver
      .observe(['(max-width: 991px)'])
      .pipe(takeUntil(this._destroyed$))
      .subscribe((result: BreakpointState) => {
        if (result.matches) {
          this.isEnableTarget = false;
        } else {
          this.isEnableTarget = true;
        }
      });
  }

  ngAfterViewInit(): void {
    this.checkLink();
  }

  checkLink(): void {
    if (this.isDisabled) {
      if (this._link) {
        this.renderer.addClass(this._link.nativeElement, 'disabled');
        this.renderer.setAttribute(this._link.nativeElement, 'aria-disabled', 'true');
        this.renderer.listen(this._link.nativeElement, 'click', (event) => {
          // While user click is disabled, keyboard event still able to click using enter key
          // Prevents entering using keyboard
          event.preventDefault();
        });
      }
      return;
    }
  }

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }
}
