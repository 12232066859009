<div class="banner d-flex" role="banner">
  <h1 class="text-uppercase">{{ 'games.page.trivia.title' | translate }}</h1>
</div>

<fgb-game-nav></fgb-game-nav>

<div class="container">
  <ng-container *ngIf="quizzes$ | async as quizzes">
    <div *ngIf="!quizzes.length">
      <div class="container">
        <div class="no-items mx-0 w-100 mt-4 no-results-placeholder-div">
          <div class="no-results-placeholder">{{ 'score.quiz.placeholder.description' | translate }}</div>
        </div>
      </div>
    </div>
    <div *ngFor="let quiz of quizzes">
      <fgb-survey-item [survey]="quiz"></fgb-survey-item>
    </div>
  </ng-container>
</div>
