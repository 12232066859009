import { Component, OnInit } from '@angular/core';
import { TransactionType } from '@fgb/core';

@Component({
  selector: 'fgb-activity-page',
  templateUrl: './activity-page.component.html',
  styleUrls: ['./activity-page.component.scss']
})
export class ActivityPageComponent implements OnInit {
  transactionTypes = TransactionType;

  constructor() {}
  ngOnInit() {}

}
