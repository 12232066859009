<div class="background">
  <div class="container d-flex h-100 p-3">
    <div class="card d-flex flex-row mx-auto my-lg-auto px-2 px-lg-0 shadow">
      <div class="col-lg-5 d-none d-lg-block login-banner shadow"></div>
      <div class="col-lg-7 d-flex flex-column justify-content-between personal-details-panel px-lg-5 h-100">
        <div class="d-flex flex-column">
          <ng-container *ngTemplateOutlet="logo"></ng-container>
          <h2 class="text-lg-left text-center text-primary mb-4 mt-3 my-lg-4">{{ 'purchase.failure.title' | translate }}</h2>
          <p class="font-size-14 mb-4">
            {{ 'purchase.failure.description' | translate }}
          </p>
        </div>
        <div class="w-100 d-flex justify-content-center">
          <button class="btn btn-primary w-100 my-lg-4 my-3 font-size-14 fw-bold" [routerLink]="['/']">
            {{ 'purchase.failure.button.label' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<!--Use this to use a different logo for each population-->
<ng-template #logo>
  <div class="d-flex d-lg-none">
    <img alt="" class="logo mx-auto" src="assets/images/login-registration/fortress-login.svg" draggable="false" />
  </div>
</ng-template>
