import { Component, OnInit } from '@angular/core';
import { MarketplaceService, MemberQuery } from '@fgb/core';
import { ScreenType } from '@fgb/portal-component-library/src/lib/directives';
import { firstValueFrom } from 'rxjs';
import { Populations } from 'src/app/shared/utilities/populations';

@Component({
  selector: 'fgb-marketplace-page',
  templateUrl: './marketplace-page.component.html',
  styleUrls: ['./marketplace-page.component.scss'],
})
export class MarketplacePageComponent implements OnInit {
  screenType = ScreenType;
  populations = Populations;

  constructor(private marketplaceService: MarketplaceService, private memberQuery: MemberQuery) {}

  ngOnInit() {
    const isFreeMember = this.memberQuery.getPopulationName() === Populations.NonMember;
    firstValueFrom(this.marketplaceService.fetchMarketplaceItems()).then(() => {
      if (isFreeMember) {
        this.marketplaceService.lockAllItems();
      }
    });
  }
}
