<div class="h-100 w-100 px-0 d-flex flex-column flex-md-row">
  <!--Left Side-->
  <div class="shared-login-banner d-md-flex d-none">
    <img draggable="false" src="assets/images/login-registration/login-banner-2024.jpg" />
  </div>
  <div class="bg-primary d-flex w-100 d-md-none mobile-header"></div>
  <!--Right Side-->
  <div class="p-4 right-side-login">
    <div class="login-container">
      <div class="panel-text mlb-text mt-0 font-size-14">
        {{ 'login.page.description' | translate }}
      </div>
      <fgb-user-login></fgb-user-login>
    </div>
    <div class="d-block d-md-none">
      <ng-template *ngTemplateOutlet="app"></ng-template>
    </div>
  </div>
</div>

<ng-template #app>
  <div class="mobile-app">
    <div class="font-size-16 fw-bolder text-center mb-2 download-text d-block">DOWNLOAD CLUB APP</div>
    <div class="apple-google-logos d-flex justify-content-center">
      <a [href]="appleLinkUrl" target="_blank">
        <img src="assets/images/login-registration/apple-link-logo.png" />
      </a>
      <a [href]="googleLinkUrl" target="_blank">
        <img src="assets/images/login-registration/google-link-logo.png" />
      </a>
    </div>
  </div>
</ng-template>
