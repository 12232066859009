import { Injectable } from "@angular/core";
import { QueryEntity } from "@datorama/akita";
import { TierState, TierStore } from "./tier.store";
import { map, Observable, switchMap } from "rxjs";
import { LoyaltyTier } from "@fgb/core";

/**
 * Temporary, should be changed in the component library
 */
@Injectable({ providedIn: 'root' })
export class TierQuery extends QueryEntity<TierState, LoyaltyTier> {
  customerTier$ = this.select(state => state.tier);
  tiers$ = this.selectAll();

  constructor(protected store: TierStore) {
    super(store);
  }

  currentTier(): Observable<LoyaltyTier | undefined> {
    return this.customerTier$.pipe(
      map(tier => {

        if( tier ){
          const foundTier = tier.Tiers.find(t => t.TierId === tier.CurrentTierId);

          if( foundTier ){
            return foundTier;
          }
          // Some data may not have current tier id, so we will return the first tier in the list
          return tier.Tiers[0];
        }

        return undefined;
      })
    );
  }

  nextTier(): Observable<LoyaltyTier | undefined> {
    return this.currentTier().pipe(
      switchMap(currentTier => {
        return this.customerTier$.pipe(
          map(tier => {
            if( tier && currentTier ){
              const currentTierIndex = tier.Tiers.findIndex(t => t.TierId === currentTier.TierId);
              return tier.Tiers[currentTierIndex + 1];
            }
            return undefined;
          })
        )
      })
    );
  }

  lastTier(): Observable<LoyaltyTier | undefined> {
    return this.customerTier$.pipe(
      map(tier => {

        if( tier && tier.Tiers?.length > 0 ){
          //Sort tiers by MaximumTierPoints in ascending order to find last tier
          const sortedTiers = [ ...tier.Tiers ].sort((t1, t2) => t1.MaximumTierPoints - t2.MaximumTierPoints);
          return sortedTiers[sortedTiers.length - 1];
        }

        return undefined;
      })
    );
  }

  /*
   * Gets the tier by id
   * @param id 
   */
  getTierById(id: number): LoyaltyTier | undefined{
    return this.getEntity(id);
  }

  /**
   * Selects the tier by id
   * @param id 
   */
  selectTierById(id: number): Observable<LoyaltyTier | undefined>{
    return this.selectEntity(id);
  }

}