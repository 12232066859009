<form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
  <div class="form-floating mb-4">
    <input
      type="text"
      formControlName="username"
      class="form-control"
      id="floatingInput"
      placeholder="Insert email here"
      [ngClass]="{ 'is-invalid': submitted && f.username.errors }"
    />
    <label for="floatingInput">{{ 'user.login.username.label' | translate : { fgbdefault: 'Email Address' } }}</label>
    <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
      <div class="alert-danger" *ngIf="f.username.errors.required">
        {{ 'user.login.username.required' | translate : { fgbdefault: 'This field is required' } }}
      </div>
    </div>
  </div>
  <div class="form-floating">
    <input
      type="password"
      formControlName="password"
      class="form-control"
      id="floatingPassword"
      placeholder="Insert password here"
      [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
    />
    <label for="floatingPassword">{{ 'user.login.password.label' | translate : { fgbdefault: 'Password' } }}</label>
    <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
      <div class="alert-danger" *ngIf="f.password.errors.required">
        {{ 'user.login.password.required' | translate : { fgbdefault: 'This field is required' } }}
      </div>
    </div>
  </div>
  <fgbcl-errors></fgbcl-errors>
  <div class="form-group mb-2 mt-4">
    <button [disabled]="loading" class="btn btn-primary btn-block login-button font-size-14 w-100 fw-bold" aria-label="Login">
      <div *ngIf="!loading">{{ 'user.login.button.text' | translate : { fgbdefault: 'Login' } }}</div>
      <fgbcl-loading [size]="'small'" *ngIf="loading"></fgbcl-loading>
    </button>
  </div>
  <div class="panel-text forgot-password text-center font-size-14 mt-4">
    <a class="fw-bold text-danger" [routerLink]="'/reset-password'">{{
      'user.login.forgot.password' | translate : { fgbdefault: 'Forgot Password?' }
    }}</a>
  </div>
</form>
