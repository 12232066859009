<div class="container block">
  <div class="mt-3">
    <a class="text-danger fw-bold d-flex align-item-center" [routerLink]="'/linkcard'">
      <span class="icon material-icons">arrow_back_ios</span> {{ 'card.linking.success.back.to.payment.label' | translate }}
    </a>
  </div>
  <fgbcl-card-link-success-content></fgbcl-card-link-success-content>
  <div class="d-flex align-item-center justify-content-center">
    <fgbcl-card-link-offers-list
      [cardLinkOfferOptions]="cardLinkOfferOptionsRecommendedOffers"
      [labelName]="'card.linking.success.offer.you.may.like.label'"
    ></fgbcl-card-link-offers-list>
  </div>
</div>
