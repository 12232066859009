<div class="score col-12 p-0">
  <div
    class="score-predictor-countdown d-lg-none d-block"
    *ngIf="status === scorePredictorStatus.EntryOpen || status === scorePredictorStatus.Answered"
  >
    <ng-container *ngIf="canEditAnswer">
      <span class="material-icons align-middle pe-1 text-primary"> schedule </span>
      <fgbcl-countdown
        [endDateTime]="survey.SeparateEntryWindow ? survey.EntryEndDate : survey.EndDate"
        [isUTC]="true"
        class="countdown font-size-14 font-heading fw-bold text-primary"
      ></fgbcl-countdown>
    </ng-container>
  </div>
  <div class="d-lg-none d-block m-auto" *ngIf="status !== scorePredictorStatus.ComingSoon">
    <ng-container *ngTemplateOutlet="dateTime"></ng-container>
  </div>
  <form
    class="row no-gutters justify-content-lg-center justify-content-around align-items-center m-0"
    [ngClass]="
      status == scorePredictorStatus.EntryOpen
        ? 'py-2 px-lg-0 px-4'
        : status === scorePredictorStatus.Answered && canEditAnswer
        ? 'align-with-submit-body px-lg-0 px-4'
        : 'default'
    "
    [formGroup]="scoreForm"
  >
    <div class="d-flex align-items-center justify-content-center col-lg-4 col-5">
      <!-- Home Image -->
      <ng-container *ngTemplateOutlet="imageTemplate; context: { $implicit: homeTeam, team: 'home' }"></ng-container>
      <!--Home Count-->
      <div class="d-inline-block px-lg-2 px-1 pt-2" *ngIf="status !== scorePredictorStatus.ComingSoon">
        <fieldset [disabled]="status != scorePredictorStatus.EntryOpen">
          <fgb-number-increment-input
            formControlName="home"
            accessibilityLabel="home"
            wscPrefix="score.predictor.score"
            [ngClass]="[
              survey.ProcessStatus === 2 && hasSelectedCorrect
                ? 'text-success'
                : survey.ProcessStatus === 2 && !hasSelectedCorrect
                ? 'text-danger'
                : ''
            ]"
          >
          </fgb-number-increment-input>
        </fieldset>
      </div>
    </div>
    <div class="col-4 d-lg-block" [class.d-none]="status !== scorePredictorStatus.ComingSoon">
      <div
        class="score-predictor-countdown"
        *ngIf="status === scorePredictorStatus.EntryOpen || status === scorePredictorStatus.Answered"
      >
        <ng-container *ngIf="canEditAnswer">
          <span class="material-icons align-middle pe-1 text-primary"> schedule </span>
          <fgbcl-countdown
            [endDateTime]="survey.SeparateEntryWindow ? survey.EntryEndDate : survey.EndDate"
            [isUTC]="true"
            class="countdown font-size-14 font-heading fw-bold text-primary"
          ></fgbcl-countdown>
        </ng-container>
      </div>
      <div class="align-self-center">
        <ng-container *ngTemplateOutlet="dateTime"></ng-container>
      </div>
    </div>
    <div class="d-flex align-items-center justify-content-center col-lg-4 col-5">
      <!--Away Count-->
      <div class="d-inline-block px-lg-2 px-1 pt-2" *ngIf="status !== scorePredictorStatus.ComingSoon">
        <fieldset [disabled]="status != scorePredictorStatus.EntryOpen">
          <fgb-number-increment-input
            formControlName="away"
            accessibilityLabel="away"
            wscPrefix="score.predictor.score"
            [ngClass]="[
              survey.ProcessStatus === 2 && hasSelectedCorrect
                ? 'text-success'
                : survey.ProcessStatus === 2 && !hasSelectedCorrect
                ? 'text-danger'
                : ''
            ]"
          >
          </fgb-number-increment-input>
        </fieldset>
      </div>
      <!-- Away Image -->
      <ng-container *ngTemplateOutlet="imageTemplate; context: { $implicit: awayTeam, team: 'away' }"></ng-container>
    </div>
  </form>
</div>

<!--Entry Window Date-->
<ng-template #dateTime>
  <div
    class="text-center font-size-12 date-time"
    [ngClass]="
      status === scorePredictorStatus.Answered && canEditAnswer
        ? 'align-with-submit-time'
        : status === scorePredictorStatus.Answered || status === scorePredictorStatus.ComingSoon
        ? 'mt-2'
        : 'mt-2'
    "
  >
    {{
      survey.SeparateEntryWindow
        ? (survey.EntryEndDate | UtcToLocaltimePipe : 'DD MMM, HH:mm')
        : (survey.EndDate | UtcToLocaltimePipe : 'DD MMM, HH:mm')
    }}
  </div>
</ng-template>

<!-- Team Image Template -->
<ng-template #imageTemplate let-item let-team="team">
  <div class="team-{{ team }}-img-container" [ngClass]="{ 'px-lg-2 px-1': status !== scorePredictorStatus.ComingSoon }">
    <ng-container *ngIf="survey.SurveyAnswers.length && survey.SurveyAnswers[item]">
      <img
        class="{{ team + '-team-image' }}"
        alt=""
        src="{{ survey.SurveyAnswers[item].ImageURL | contentImage : '7' }}"
        draggable="false"
      />
    </ng-container>
  </div>
</ng-template>
