<div
  class="details"
  [ngClass]="
    sameLine ? 'text-center d-flex justify-content-around align-items-baseline sticky-cta mt-0 mt-lg-4' : 'ms-auto me-2 mx-lg-0'
  "
  [ngSwitch]="buttonState"
  *ngIf="!isGamePending"
>
  <ng-container *ngSwitchCase="'player-link'">
    <div class="question-text pe-1">
      <span class="text-lg-black font-size-14 font-default fw-bold d-block d-lg-none">{{ selectedPlayer?.PlayerName }}</span>
      <span class="text-lg-black font-size-14 font-default fw-bold d-none d-lg-block">{{ selectedPlayer?.PlayerName }}</span>
    </div>
    <button
      class="btn btn-tertiary text-white btn-select font-size-14 font-default fw-bold rounded-4 mb-2"
      [routerLink]="['/games/pickaplayer/' + game.GameId]"
      routerLinkActive="router-link-active"
    >
      {{ 'pick.player.change.player.button' | translate }}
    </button>
  </ng-container>

  <ng-container *ngSwitchCase="'no-player-link'">
    <div class="question-text font-size-14 font-default fw-bold text-capitalize">
      {{ 'pick.player.no.player.selected' | translate }}
    </div>
    <button
      class="btn btn-primary btn-select font-size-14 font-default fw-bold rounded-4 mb-2"
      [routerLink]="['/games/pickaplayer/' + game.GameId]"
      routerLinkActive="router-link-active"
    >
      {{ 'pick.player.select.player.button' | translate }}
    </button>
  </ng-container>

  <ng-container *ngSwitchCase="'make-selection'">
    <div class="question-text font-size-14 font-default fw-bold pe-1 no-player-text">
      {{ 'pick.player.no.player.selected' | translate }}
    </div>
    <button
      class="btn btn-primary btn-select btn-submit font-size-14 font-default fw-bold rounded-4 mb-2 disabled"
      [disabled]="true"
    >
      {{ 'pick.player.select.player.button' | translate }}
    </button>
  </ng-container>

  <ng-container *ngSwitchCase="'changing-player'">
    <div class="question-text pe-1">
      <span class="text-lg-black font-size-14 font-default fw-bold">{{ selectedPlayer?.PlayerName }}</span>
    </div>
    <button class="btn btn-primary btn-select font-size-14 font-default fw-bold rounded-4 mb-2" (click)="buttonClick()">
      {{ 'pick.player.change.player.button' | translate }}
    </button>
  </ng-container>

  <ng-container *ngSwitchCase="'confirm'">
    <div class="question-text font-size-14 pe-1">
      <span class="text-lg-black font-size-14 font-default fw-bold d-block d-lg-none">{{ selectedPlayer?.PlayerName }}</span>
      <span class="text-lg-black font-size-14 font-default fw-bold d-none d-lg-block">{{ selectedPlayer?.PlayerName }}</span>
    </div>
    <button
      class="btn btn-primary btn-select btn-submit font-size-14 font-default fw-bold rounded-4 mb-2"
      (click)="buttonClick()"
    >
      {{ 'pick.player.submit.button' | translate }}
    </button>
  </ng-container>
</div>
