import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MemberService } from '@fgb/core';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'fgb-user-change-password',
  templateUrl: './user-change-password.component.html',
  styleUrls: ['./user-change-password.component.scss'],
})
export class UserChangePasswordComponent implements OnInit {
  formChangePassword: UntypedFormGroup;
  resetKey: string = '';
  errorMessage: string;
  visiblePassword: boolean = false;
  visibleConfirm: boolean = false;
  timeout: any;
  appleLinkUrl: string;
  googleLinkUrl: string;
  @ViewChild('alert') alert: TemplateRef<any>;
  
  constructor(
    private _fb: UntypedFormBuilder,
    private memberService: MemberService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.resetKey = this.getResetKeyFromRoute();
    this._initLinks();
  }

  private _initLinks(): void{
    this.appleLinkUrl = this.translate.instant('apple.link.url');
    this.googleLinkUrl = this.translate.instant('google.link.url');
  }

  getResetKeyFromRoute() {
    return this.route.snapshot.queryParamMap.get('resetkey') || '';
  }

  initForm() {
    this.formChangePassword = this._fb.group({
      password: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]],
    }, { validators: samePasswordValidator() });
  }

  submit() {
    this.errorMessage = '';
    this.formChangePassword.markAllAsTouched();

    if( this.timeout ){
      clearTimeout(this.timeout);
    }

    if (this.formChangePassword.invalid) return;

    let password = this.formChangePassword.get('password')?.value;
    let confirm = this.formChangePassword.get('confirmPassword')?.value;
    this.memberService
      .changePassword(this.resetKey, password, confirm)
      .then((_) => {
        this.openModal();
      })
      .catch((err) => {
        const errorMessage = this.translate.instant('user.change.password.error');
        this.errorMessage = err.error.Message ? err.error.Message : errorMessage;
      });
  }

  openModal() {
    this.modalService.open(this.alert, {
      centered: true,
      windowClass: 'user-change-password-modal'
    });
  }

  toggleVisiblePassword() {
    this.visiblePassword = !this.visiblePassword;
  }

  toggleVisibleConfirm() {
    this.visibleConfirm = !this.visibleConfirm;
  }

}

function samePasswordValidator(): ValidatorFn{
  return (control: AbstractControl): ValidationErrors | null => {
    const { password, confirmPassword } = control.value;

    if (password !== confirmPassword) {
      return {
        samePassword: true
      };
    }

    return null;
  }
}
