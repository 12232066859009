import { Component, OnInit } from '@angular/core';
import { LottoService, MemberQuery } from '@fgb/core';
import { ScreenType } from '@fgb/portal-component-library/src/lib/directives';
import { Populations } from 'src/app/shared/utilities/populations';

@Component({
  selector: 'fgb-lottos-page',
  templateUrl: './lottos-page.component.html',
  styleUrls: ['./lottos-page.component.scss'],
})
export class LottosPageComponent implements OnInit {
  screenType = ScreenType;

  constructor(private lottoService: LottoService, private memberQuery: MemberQuery) {}

  ngOnInit() {
    const isFreeMember = this.memberQuery.getPopulationName() === Populations.NonMember;
    if (isFreeMember) {
      this.lottoService.lockAllItems();
    }
  }
}
