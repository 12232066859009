<!-- <fgbcl-game-banner></fgbcl-game-banner> -->
<div class="container mt-4">
  <ng-container *ngIf="ticketList$ | async as ticketList">
    <ng-container *ngIf="ticketList.length > 0; else noTickets">
      <ng-container *ngIf="activeGame$ | async">
        <div class="row" *ngIf="!pastGame">
          <ng-container *ngIf="TransferWindowExists(ticketList[0].StartTime, ticketList[0].EndTime); else noTicketTransferWindow">
            <div class="col-lg-6" [ngClass]="{ 'col-lg-9': !IsWindowOpen(ticketList[0].StartTime, ticketList[0].EndTime) }">
              <h4
                *ngIf="IsWindowOpen(ticketList[0].StartTime, ticketList[0].EndTime); else closedWindowHeading"
                class="fw-bold text-primary mb-3"
              >
                SELECT A SEAT
              </h4>
              <ng-template #closedWindowHeading>
                <h4 class="fw-bold text-primary mb-3">TICKET WINDOW NOT OPEN</h4>
              </ng-template>
              <p class="description">
                Below select the ticket that you wish to forward on to a friend or family member. Then click the green transfer
                now button. Members will receive 40 points every time they forward a ticket.
              </p>
            </div>
            <div class="col-lg-3">
              <fgbcl-ticket-transfer-window-status
                [windowStartDate]="ticketList[0].StartTime"
                [windowEndDate]="ticketList[0].EndTime"
              ></fgbcl-ticket-transfer-window-status>
            </div>
            <ng-container *ngIf="IsWindowOpen(ticketList[0].StartTime, ticketList[0].EndTime)">
              <div class="col-lg-3 col-sm-8 card text-center p-4 selected-tickets-container">
                <fgbcl-selected-tickets-count class="d-flex d-lg-block"></fgbcl-selected-tickets-count>
              </div>
            </ng-container>
          </ng-container>
          <ng-template #noTicketTransferWindow>
            <div class="col-12">
              <h4 class="fw-bold text-primary mb-4">TICKETS NOT AVAILABLE TO FORWARD</h4>
            </div>
          </ng-template>
        </div>
      </ng-container>
      <ng-container *ngIf="IsWindowOpen(ticketList[0].StartTime, ticketList[0].EndTime) === true">
        <h4 class="fw-bold text-primary mb-4">TICKETS</h4>
        <fgbcl-ticket-list
          [tickets]="ticketList"
          [pastGame]="pastGame"
          [transferWindowExists]="TransferWindowExists(ticketList[0].StartTime, ticketList[0].EndTime)"
          [transferWindowOpen]="IsWindowOpen(ticketList[0].StartTime, ticketList[0].EndTime)"
        ></fgbcl-ticket-list>
      </ng-container>

      <ng-container *ngIf="IsWindowOpen(ticketList[0].StartTime, ticketList[0].EndTime) === false">
        <ng-container *ngSubscribe="availableTicketList$ as availableTicketList">
          <ng-container *ngIf="availableTicketList.length > 0">
            <h4 class="fw-bold text-primary mb-4">Tickets</h4>
            <fgbcl-ticket-list
              [tickets]="availableTicketList"
              [pastGame]="pastGame"
              [transferWindowExists]="TransferWindowExists(ticketList[0].StartTime, ticketList[0].EndTime)"
              [transferWindowOpen]="IsWindowOpen(ticketList[0].StartTime, ticketList[0].EndTime)"
              ticketColumn1="AreaLine1"
              ticketColumn2="StandName"
              [showTicketColumn1]="showTicketColumn1"
              [showTicketColumn2]="showTicketColumn2"
            ></fgbcl-ticket-list>
          </ng-container>
        </ng-container>

        <ng-container *ngSubscribe="transferredTicketList$ as transferredTicketList">
          <ng-container *ngIf="transferredTicketList.length > 0">
            <h4 class="fw-bold text-primary mb-4">Transferred</h4>
            <fgbcl-ticket-list
              [tickets]="transferredTicketList"
              [pastGame]="pastGame"
              [transferWindowExists]="TransferWindowExists(ticketList[0].StartTime, ticketList[0].EndTime)"
              [transferWindowOpen]="IsWindowOpen(ticketList[0].StartTime, ticketList[0].EndTime)"
              ticketColumn1="AreaLine1"
              ticketColumn2="StandName"
              [showTicketColumn1]="showTicketColumn11"
              [showTicketColumn2]="showTicketColumn22"
            ></fgbcl-ticket-list>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
    <ng-template #noTickets>
      <div class="text-center text-info">
        <span class="icon icon-ticket"></span>
        <p>Sorry, No Tickets Available To Forward</p>
      </div>
    </ng-template>
  </ng-container>
</div>
