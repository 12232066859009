import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnnouncementsComponent } from './announcements/announcements.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { LoadingModule } from '@fgb/portal-component-library/src/lib/shared/loading';
import { AnnouncementContentComponent } from './announcement-content/announcement-content.component';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from '@fgb/portal-component-library/src/lib/pipes';
import { RouterModule } from '@angular/router';
import { DirectivesModule } from '@fgb/portal-component-library/src/lib/directives';

@NgModule({
  declarations: [AnnouncementsComponent, AnnouncementContentComponent],
  imports: [
    CommonModule,
    CarouselModule,
    LoadingModule,
    PipesModule,
    RouterModule,
    TranslateModule,
    DirectivesModule
  ],
  exports: [AnnouncementsComponent, AnnouncementContentComponent],
})
export class AnnouncementsModule {}
