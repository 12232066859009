<ng-container class="d-none d-lg-block">
  <div class="banner" role="banner">
    <h1>Watch</h1>
  </div>
  <ng-container *ngIf="worksheets$ | async as worksheets; else loadingRef">
    <ng-container *ngIf="worksheets.length > 0; else noGameToday">
      <div *ngFor="let worksheetGroup of worksheets" class="h-100">
        <ng-container *ngIf="worksheetGroup.Items?.length > 0; else noVideo" class="h-0">
          <div class="container">
            <div class="row mt-3">
              <div class="col-12 col-lg-6">
                <fgbcl-portal-link-card [portalLink]="'/rewards'" [portalLinkText]="'Rewards'"></fgbcl-portal-link-card>
              </div>
            </div>
          </div>
          <div class="watch-container my-4 py-2">
            <div class="container">
              <div *ngFor="let item of worksheetGroup.Items">
                <fgb-worksheet-item [worksheetItem]="item"></fgb-worksheet-item>
              </div>
              <!-- Loading -->
            </div>
          </div>
          <div class="container">
            <ng-container
              *ngIf="{
                polls: polls$ | async,
                quizzes: quizzes$ | async,
                predictor: predictor$ | async
              } as surveys"
            >
              <div *ngIf="surveys.quizzes && !!surveys.quizzes.length">
                <div class="title">
                  <h5 class="text-start mb-3 mt-4">Quiz</h5>
                </div>
                <div *ngFor="let quiz of surveys.quizzes">
                  <fgb-survey-item [survey]="quiz"></fgb-survey-item>
                </div>
              </div>

              <div *ngIf="surveys.predictor && !!surveys.predictor.length">
                <div class="title">
                  <h5 class="text-start mb-3 mt-4">Predictor</h5>
                </div>
                <div *ngFor="let predictor of surveys.predictor">
                  <fgb-survey-item [survey]="predictor"></fgb-survey-item>
                </div>
              </div>

              <div *ngIf="surveys.polls && !!surveys.polls.length">
                <div class="title">
                  <h5 class="text-start mb-3 mt-4">Polls</h5>
                </div>
                <div *ngFor="let poll of surveys.polls">
                  <fgb-survey-item [survey]="poll"></fgb-survey-item>
                </div>
              </div>
            </ng-container>
          </div>
        </ng-container>
        <ng-template #noVideo>
          <div class="no-items w-100 text-center bg-white p-2 rounded">
            <div
              class="icon svg no-results-placeholder-icon fw-bold text-center"
              [inlineSVG]="'assets/images/icons/television.svg'"
            ></div>
            <div class="no-results-placeholder-title fw-bold text-primary">Game Day Video</div>
            <div class="no-results-placeholder-text text-info mb-3">Currently Unavailable</div>
          </div>
        </ng-template>
      </div>
    </ng-container>
  </ng-container>
  <ng-template #noGameToday>
    <div class="no-items w-100 text-center bg-white p-2 rounded">
      <div
        class="icon svg no-results-placeholder-icon fw-bold text-center"
        [inlineSVG]="'assets/images/icons/television.svg'"
      ></div>
      <div class="no-results-placeholder-title fw-bold text-primary">
        Join us for matchday streaming and other video content throughout the season.
      </div>
    </div>
  </ng-template>
  <ng-template #loadingRef>
    <div class="w-100">
      <fgbcl-loading></fgbcl-loading>
    </div>
  </ng-template>
</ng-container>
