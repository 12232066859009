<div class="banner" role="banner">
  <h1 *ngIf="memberDetails$ | async as memberDetails">{{ memberDetails!.FirstName }} {{ memberDetails!.Surname }}</h1>
</div>

<fgb-account-nav></fgb-account-nav>
<div *ngIf="partnerLimit$ | async as partnerLimit" class="container">
  <div class="card mb-2 mt-2 mt-lg-0">
    <div class="row m-0 partner-card">
      <div class="col-12 col-lg-7 pe-0 mb-4 mb-lg-0">
        <p class="m-0 text-info">
          Use this page to setup your account partners for the 2020 season. Partners receive great benefits like exchanges, eCASH
          Back, and can earn points. Be sure to also add them as a “Friend” on the “Tickets” page so you can easily transfer
          tickets to your partners . Please visit
          <a class="text-info fw-bold" href="http://www.fortressgb.com/" target="_blank">http://www.fortressgb.com/</a>
          to learn more about forwarding tickets.
        </p>
      </div>
      <div class="col-12 col-lg-5 m-0 flex-nowrap pe-0 ps-0 ps-lg-3">
        <div class="d-flex justify-content-center align-items-center">
          <button
            class="partner-button border-0 text-center text-white bg-primary pt-0"
            (click)="toggleAddForm()"
            [ngClass]="{
              active: !showAcceptForm,
              'not-active': showAcceptForm,
              'greyed-out': currentPartnerCount >= partnerLimit
            }"
          >
            <div class="icon svg partner-add-svg d-block mx-auto mt-1" [inlineSVG]="'assets/images/icons/partner-add.svg'"></div>
            <label class="mt-1">Add New Partner</label>
          </button>
          <button
            class="partner-button border-0 text-center text-white bg-secondary pt-0"
            (click)="toggleAcceptForm()"
            [ngClass]="{ active: !showAddForm, 'not-active': showAddForm, 'greyed-out': currentPartnerCount >= partnerLimit }"
          >
            <div
              class="icon svg partner-accept-svg d-block mx-auto mt-1"
              [inlineSVG]="'assets/images/icons/partner-accept.svg'"
            ></div>
            <label class="mt-1">Accept Partner</label>
          </button>
        </div>
      </div>
    </div>
    <ng-container *ngIf="showAddForm || showAcceptForm">
      <hr class="m-2" />
      <div class="card-body">
        <fgb-partner-add-form (close)="toggleAddForm()" *ngIf="showAddForm"></fgb-partner-add-form>
        <fgb-partner-accept-form (close)="toggleAcceptForm()" *ngIf="showAcceptForm"></fgb-partner-accept-form>
      </div>
    </ng-container>
  </div>
  <fgbcl-errors></fgbcl-errors>
  <ng-container *ngIf="currentPartnerCount > 0; else partnerPlaceholder">
    <fgb-partner-list></fgb-partner-list>
    <fgb-partner-pending-list></fgb-partner-pending-list>
  </ng-container>
  <ng-template #partnerPlaceholder>
    <div class="col-12 col-lg-4 col-md-6 offset-md-3 offset-lg-4">
      <div class="icon svg no-partner-svg d-block mx-auto mt-5" [inlineSVG]="'assets/images/placeholders/no-partners.svg'"></div>
      <div class="no-partner-text text-center mt-2">
        <h3 class="text-secondary mb-0">NO PARTNER</h3>
        <div class="text-tertiary">
          To add your first partner please click on add new partner and insert your partners details.
        </div>
      </div>
    </div>
  </ng-template>
</div>
