<ng-container *ngIf="transactions$ | async as transactions">
    <div *ngIf="transactions?.length > 0 else noData">
        <ng-container *ngFor="let data of transactions; trackBy: trackByFn">
            <h4 class="split-transaction-list-header">{{ data?.date }}</h4>
            <div class="split-transaction-list">
                <ng-container *ngFor="let item of data.transactions">
                    <fgb-split-transaction-item [data]="item"></fgb-split-transaction-item>
                </ng-container>
            </div>
        </ng-container>
        <ng-container *ngIf="!(loading$ | async)">
            <div class="btn-container">
                <button id="load-more-btn" class="primary" (click)="loadMore()" *ngIf="!isLoadDisabled">
                    Load More
                </button>
            </div>
        </ng-container>
    </div>
    <ng-container *ngIf="(loading$ | async)">
        <div class="split-transaction-list-loading">
            <fgbcl-loading></fgbcl-loading>
        </div>
    </ng-container>
</ng-container>

<ng-template #noData>
    <div class="no-data"></div>
</ng-template>

