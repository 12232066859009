// External Dependencies
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { NgbModule, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { JwtModule } from '@auth0/angular-jwt';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MissingTranslationHandler, TranslateModule } from '@ngx-translate/core';
import { QRCodeModule } from 'angularx-qrcode';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ToastrModule } from 'ngx-toastr';
import { InlineSVGModule } from 'ng-inline-svg-2';
// Routing & Configuration
import { AppRoutingModule } from './app-routing.module';
// Feature Modules
import { SharedModule } from './shared/shared.module';
import { ClubModule } from './modules/club/club.module';
import { UserModule } from './modules/user/user.module';
import { LoyaltyModule } from './modules/loyalty/loyalty.module';
import { VouchersModule } from './modules/vouchers/vouchers.module';
import { EcashModule } from './modules/ecash/ecash.module';
import { PurchaseModule } from './modules/purchase/purchase.module';
import { GuardianModule } from './modules/guardian/guardian.module';
import { EventsModule } from './modules/events/events.module';
import { TicketsModule } from './modules/tickets/tickets.module';
import { FantasyModule } from './modules/fantasy/fantasy.module';
import { DisplayModule } from './modules/display/display.module';

// Utilities
import { MomentDateFormatter } from './shared/utilities/moment-date-formatter';
// @fgb/core
import {
  PopulationGuard,
  ProductCodeGuard,
  jwtTokenGetter,
  tmAuthConfig,
  tmOAuthConfig,
  FGBMissingTranslationHandler,
  NotificationLoyaltyType,
} from '@fgb/core';

// Component Library
import { CardTokenListModule } from '@fgb/portal-component-library/src/lib/ecash/card-token-list';
import { EcashDescriptionModule } from '@fgb/portal-component-library/src/lib/ecash/ecash-description';
import { LoadingModule } from '@fgb/portal-component-library/src/lib/shared/loading';
import { ErrorsModule } from '@fgb/portal-component-library/src/lib/shared/errors';
import { EarnPointsModule } from '@fgb/portal-component-library/src/lib/club/earn-points';
import { FaqSectionModule } from '@fgb/portal-component-library/src/lib/club/faq-section';
import { TermsAndConditionsModule } from '@fgb/portal-component-library/src/lib/club/terms-and-conditions';
import { CountryAndRegionsModule } from '@fgb/portal-component-library/src/lib/shared/country-and-regions';
import { DirectivesModule } from '@fgb/portal-component-library/src/lib/directives';
import { AuctionDetailsModule } from '@fgb/portal-component-library/src/lib/loyalty/auctions/auction-details';
import { AuctionPanelModule } from '@fgb/portal-component-library/src/lib/loyalty/auctions/auction-panel';
import { AuctionListModule } from '@fgb/portal-component-library/src/lib/loyalty/auctions/auction-list';
import { LottoDetailsModule } from '@fgb/portal-component-library/src/lib/loyalty/lottos/lotto-details';
import { LottosListItemModule } from '@fgb/portal-component-library/src/lib/loyalty/lottos/lottos-list-item';
import { LottosListModule } from '@fgb/portal-component-library/src/lib/loyalty/lottos/lottos-list';
import { MarketplaceDetailsModule } from '@fgb/portal-component-library/src/lib/loyalty/marketplace/marketplace-details';
import { MarketplaceListItemModule } from '@fgb/portal-component-library/src/lib/loyalty/marketplace/marketplace-list-item';
import { MarketplaceListModule } from '@fgb/portal-component-library/src/lib/loyalty/marketplace/marketplace-list';
import { ScorecardModule } from '@fgb/portal-component-library/src/lib/loyalty/scorecards';
import { BadgeListItemModule } from '@fgb/portal-component-library/src/lib/loyalty/badges/badge-list-item';
import { BadgeOverviewListModule } from '@fgb/portal-component-library/src/lib/loyalty/badges/badge-overview-list';
import { BadgeFeaturedListModule } from '@fgb/portal-component-library/src/lib/loyalty/badges/badge-featured-list';
import { WalletModule } from '@fgb/portal-component-library/src/lib/loyalty/wallet';
import { FeaturedRewardsModule } from '@fgb/portal-component-library/src/lib/loyalty/featured-rewards';
import { AddressBookModule } from '@fgb/portal-component-library/src/lib/user/address-book';
import { ShortLongDescriptionModule } from '@fgb/portal-component-library/src/lib/shared/short-long-description';
import { ContactUsModule } from '@fgb/portal-component-library/src/lib/club/contact-us';

// General Pages
import { AppComponent } from './app.component';
import { MasterPageComponent } from './pages/master/master-page/master-page.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { LoginAccountsPageComponent } from './pages/login-accounts-page/login-accounts-page.component';
import { MaintenancePageComponent } from './pages/maintenance-page/maintenance-page.component';
import { TermsPageComponent } from './pages/terms-page/terms-page.component';
import { FaqPageComponent } from './pages/faq-page/faq-page.component';
import { ContactUsPageComponent } from './pages/contact-us-page/contact-us-page.component';
import { ReferAFriendPageComponent } from './pages/refer-a-friend-page/refer-a-friend-page.component';
import { ReferAFriendSuccessPageComponent } from './pages/refer-a-friend-page/refer-a-friend-success-page/refer-a-friend-success-page.component';
import { NotFoundPageComponent } from './pages/not-found-page/not-found-page.component';

// Loyalty Pages
import { RewardsLayoutComponent } from './pages/rewards-layout/rewards-layout.component';
import { RewardsPageComponent } from './pages/rewards-page/rewards-page.component';
import { AuctionsPageComponent } from './pages/auctions-page/auctions-page.component';
import { LottosPageComponent } from './pages/lottos-page/lottos-page.component';
import { MarketplacePageComponent } from './pages/marketplace-page/marketplace-page.component';
import { EventsPageComponent } from './pages/events-page/events-page.component';
import { WalletPageComponent } from './pages/wallet-page/wallet-page.component';
import { AuctionDetailPageComponent } from './pages/auction-detail-page/auction-detail-page.component';
import { LottoDetailPageComponent } from './pages/lotto-detail-page/lotto-detail-page.component';
import { MarketplaceDetailPageComponent } from './pages/marketplace-detail-page/marketplace-detail-page.component';
import { LottosSuccessPageComponent } from './pages/lottos-page/lottos-success-page/lottos-success-page.component';
import { MarketplacePurchaseSuccessPageComponent } from './pages/marketplace-page/marketplace-purchase-success-page/marketplace-purchase-success-page.component';
import { AuctionBidsPageComponent } from './pages/auction-bids-page/auction-bids-page.component';
import { AuctionBidDetailsPageComponent } from './pages/auction-bid-details-page/auction-bid-details-page.component';
import { LottoWalletDetailsPageComponent } from './pages/lotto-wallet-details-page/lotto-wallet-details-page.component';
import { QuizPageComponent } from './pages/quiz-page/quiz-page.component';
// Polls, Surveys & Trivia (Games)
import { GamesLayoutComponent } from './pages/games-layout/games-layout.component';
import { PollsPageComponent } from './pages/polls-page/polls-page.component';
import { FantasyGamesPageComponent } from './pages/fantasy-games-page/fantasy-games-page.component';
import { FantasyPlayerSelectPageComponent } from './pages/fantasy-player-select-page/fantasy-player-select-page.component';
import { PredictorPageComponent } from './pages/predictor-page/predictor-page.component';
// Account Pages
import { AccountDetailsPageComponent } from './pages/account-details-page/account-details-page.component';
import { ActivityPageComponent } from './pages/activity-page/activity-page.component';
// Ecash Pages
import { EcashPageComponent } from './pages/ecash-page/ecash-page.component';
import { EcashResultPageComponent } from './pages/ecash-page/ecash-result-page/ecash-result-page.component';
import { EcashSuccessPageComponent } from './pages/ecash-page/ecash-success-page/ecash-success-page.component';
import { EcashFailurePageComponent } from './pages/ecash-page/ecash-failure-page/ecash-failure-page.component';
import { EcashHistoryPageComponent } from './pages/ecash-history/ecash-history.component';
// Tickets Pages
import { TicketsPageComponent } from './pages/ticket-transfer-page/tickets-page/tickets-page.component';
import { ManageTicketsPageComponent } from './pages/manage-tickets-page/manage-tickets-page.component';
import { TicketTransferPageComponent } from './pages/ticket-transfer-page/ticket-transfer-page.component';
import { TransferConfirmPageComponent } from './pages/ticket-transfer-page/transfer-confirm-page/transfer-confirm-page.component';
import { TicketTransferSuccessPageComponent } from './pages/ticket-transfer-page/ticket-transfer-success-page/ticket-transfer-success-page.component';
import { ContactsPageComponent } from './pages/contacts-page/contacts-page.component';
import { ManageTicketHistoryPageComponent } from './pages/manage-ticket-history-page/manage-ticket-history-page.component';
// Offer Pages
import { OffersPageComponent } from './pages/offers-page/offers-page.component';
import { OfferDetailsPageComponent } from './pages/offer-details-page/offer-details-page.component';
// Population Pages
import { TestPopHomePageComponent } from './pages/test-pop-home-page/test-pop-home-page.component';
// Portal Products
import { PortalProductResultsPageComponent } from './pages/portal-products-pages/portal-product-results-page/portal-product-results-page.component';
import { PortalProductSuccessPageComponent } from './pages/portal-products-pages/portal-product-success-page/portal-product-success-page.component';
import { PortalProductFailurePageComponent } from './pages/portal-products-pages/portal-product-failure-page/portal-product-failure-page.component';
// Benefits
import { BenefitsPageComponent } from './pages/benefits-page/benefits-page.component';
import { BenefitModule } from './modules/benefit/benefit.module';
// External Cash Transfer
import { CardPageComponent } from './pages/card-page/card-page.component';
import { TransferArenaCashPageComponent } from './pages/transfer-arena-cash-page/transfer-arena-cash-page.component';
import { TransferMultipleAccountsPageComponent } from './pages/transfer-multiple-accounts-page/transfer-multiple-accounts-page.component';
import { TransferArenaCashSuccessPageComponent } from './pages/transfer-arena-cash-success-page/transfer-arena-cash-success-page.component';
// Club Pages
import { EarnPageComponent } from './pages/earn-page/earn-page.component';
import { GuardianPageComponent } from './pages/guardian-page/guardian-page.component';
import { LayoutModule } from './modules/layouts/layout.module';

// Points Management Page
import { PointsManagementPageComponent } from './pages/points-management-page/points-management-page.component';

// Partners Pages
import { PartnerPageComponent } from './pages/partner-page/partner-page.component';
import { LeaderboardPageComponent } from './pages/leaderboard-page/leaderboard-page.component';
import { LottosTermsPageComponent } from './pages/lottos-terms-page/lottos-terms-page.component';
import { MarketplaceTermsPageComponent } from './pages/marketplace-terms-page/marketplace-terms-page.component';
import { JokesPageComponent } from './pages/jokes-page/jokes-page.component';
import { WorksheetPageComponent } from './pages/worksheet-page/worksheet-page.component';
import { ZoomMeetingPageComponent } from './pages/zoom-meeting-page/zoom-meeting-page.component';
import { FantasyLeaderboardPageComponent } from './pages/fantasy-leaderboard-page/fantasy-leaderboard-page.component';
import { RenewalBonusTransferPageComponent } from './pages/renewal-bonus-transfer-page/renewal-bonus-transfer-page.component';
import { SubscriptionsPageComponent } from './pages/subscriptions-page/subscriptions-page.component';
import { CartCheckoutPagePortalProductsComponent } from './pages/cart-checkout-page-portal-products/cart-checkout-page-portal-products.component';
import { CartCheckoutPageComponent } from './pages/cart-checkout-page/cart-checkout-page.component';
import { CartCheckoutResultsPageComponent } from './pages/cart-checkout-page/cart-checkout-results-page/cart-checkout-results-page.component';
import { CartCheckoutSuccessPageComponent } from './pages/cart-checkout-page/cart-checkout-success-page/cart-checkout-success-page.component';
import { NotificationManagementPageComponent } from './pages/notification-management-page/notification-management-page.component';
import { TailgatePageComponent } from './pages/tailgate-page/tailgate-page.component';
import { ScorePredictorPageComponent } from './pages/score-predictor-page/score-predictor-page.component';
import { ProductsPageComponent } from './pages/products-page/products-page.component';
import { PaymentPageComponent } from './pages/payment-page/payment-page.component';
import { BadgesPageComponent } from './pages/badges-page/badges-page.component';
import { CardLinkingPageComponent } from './pages/card-linking-page/card-linking-page.component';
import { CardLinkingSuccessPageComponent } from './pages/card-linking-success-page/card-linking-success-page.component';
import { CardLinkAffiliatesPageComponent } from './pages/card-link-affiliates-page/card-link-affiliates-page.component';
import { CardLinkActivatedOffersPageComponent } from './pages/card-link-activated-offers-page/card-link-activated-offers-page.component';
import { CardLinkOfferDetailsPageComponent } from './pages/card-link-offer-details-page/card-link-offer-details-page.component';
import { CardLinkAllOffersPageComponent } from './pages/card-link-all-offers-page/card-link-all-offers-page.component';
import { CardLinkModule } from '@fgb/portal-component-library/src/lib/cardlinking';
import { SharedSearchBarModule } from '@fgb/portal-component-library/src/lib/shared/shared-search-bar';
import { CountdownModule } from '@fgb/portal-component-library/src/lib/shared/countdown';
import { OfferSearchBarModule } from '@fgb/portal-component-library/src/lib/club/offer-search-bar';
import { CartItemModule } from '@fgb/portal-component-library/src/lib/loyalty/cart-item';
import { TicketInvitePageComponentComponent } from './pages/ticket-invite-page-component/ticket-invite-page-component.component';
import { SplitTransactionPageComponent } from './pages/split-transaction-page/split-transaction-page.component';
import { ClubSelectorModule } from '@fgb/portal-component-library/src/lib/user/club-selector';
import { EditQuestionsModule } from '@fgb/portal-component-library/src/lib/user/edit-questions';
import { CampaignDetailsPageComponent } from './pages/campaign-details-page/campaign-details-page.component';
import { RegistrationLayoutPageComponent } from './modules/registration/pages/registration-layout-page/registration-layout-page.component';
import { WalletClaimedPageComponent } from './pages/wallet-claimed-page/wallet-claimed-page.component';
import { KidsLinkedPageComponent } from './pages/kids-linked-page/kids-linked-page.component';
import { WelcomeComponent } from './modules/club/components/welcome/welcome.component';
import {
  ALERT_NOTIFICATION_COMPONENTS,
  AlertNotificationComponentMap,
  NotificationModule,
  mergeAlertNotificationComponentMaps,
} from '@fgb/portal-component-library/src/lib/club/notification';
import { ClubNotificationComponent } from './modules/club/components/club-notification/club-notification.component';
import { TiersPageComponent } from './pages/tiers-page/tiers-page.component';
import { AnnouncementsModule } from './modules/club/components/announcements';
import { provideAppConfig, provideTmConfig } from './shared/providers';
import { MixedLoyaltyListModule } from '@fgb/portal-component-library/src/lib/loyalty/mixed-loyalty';
import { FilterModalModule } from './pages/rewards-page/filter-modal';
import { ChallengesPageComponent } from './pages/challenges-page/challenges-page.component';
import { MemberUpgradeCardComponent } from './modules/user/components/member-upgrade-card/member-upgrade-card.component';
import { TierV2Component } from './modules/loyalty/components/tier/tier-v2/tier-v2.component';

const tmSSOAuthConfig: tmOAuthConfig = {
  clientId: '0d1bc7bb2005.fortress.tbjrc-tbjrc.us',
  club: 'tbjrc',
  clientSecret: '1fa5a94b2eeddec0a6088a0068fdc308ea965f475f0a49bcc32c2ee06179ce19',
  tokenUri: 'https://auth.ticketmaster.com/api/oauth/token',
  loginUri: 'https://am.ticketmaster.com/tbjrc/sso',
  authoriseUri: 'https://auth.ticketmaster.com/as/authorization.oauth2',
};

const tmLoginAuthConfig: tmAuthConfig = {
  clientId: '0d1bc7bb2005.fortress.tbjrc-tbjrc.us',
  authUri: 'https://auth.ticketmaster.com/archtics/sign-in',
  loginUri: 'https://am.ticketmaster.com/tbjrc/sso',
  visualPresents: 'tbjrc',
};

const alertNotificationComponents: Partial<AlertNotificationComponentMap> = {
  [NotificationLoyaltyType.VoucherCodeEntered]: ClubNotificationComponent
};


@NgModule({
    declarations: [
        // General Pages
        AppComponent,
        MasterPageComponent,
        HomePageComponent,
        LoginPageComponent,
        LoginAccountsPageComponent,
        MaintenancePageComponent,
        TermsPageComponent,
        FaqPageComponent,
        ContactUsPageComponent,
        ReferAFriendPageComponent,
        ReferAFriendSuccessPageComponent,
        NotFoundPageComponent,
        // Loyalty Pages
        RewardsLayoutComponent,
        RewardsPageComponent,
        AuctionsPageComponent,
        LottosPageComponent,
        MarketplacePageComponent,
        EventsPageComponent,
        WalletPageComponent,
        AuctionDetailPageComponent,
        LottoDetailPageComponent,
        MarketplaceDetailPageComponent,
        LottosSuccessPageComponent,
        MarketplacePurchaseSuccessPageComponent,
        AuctionBidsPageComponent,
        AuctionBidDetailsPageComponent,
        LottoWalletDetailsPageComponent,
        ChallengesPageComponent,
        // Polls, Surveys & Trivia Pages (Games)
        GamesLayoutComponent,
        PollsPageComponent,
        FantasyGamesPageComponent,
        FantasyPlayerSelectPageComponent,
        PredictorPageComponent,
        // Account Pages
        AccountDetailsPageComponent,
        ActivityPageComponent,
        PointsManagementPageComponent,
        // Ecash Pages
        EcashPageComponent,
        EcashResultPageComponent,
        EcashSuccessPageComponent,
        EcashFailurePageComponent,
        EcashHistoryPageComponent,
        PaymentPageComponent,
        PortalProductResultsPageComponent,
        PortalProductSuccessPageComponent,
        PortalProductFailurePageComponent,
        // Tickets Pages
        TicketsPageComponent,
        ManageTicketsPageComponent,
        TicketTransferPageComponent,
        TransferConfirmPageComponent,
        TicketTransferSuccessPageComponent,
        ContactsPageComponent,
        ManageTicketHistoryPageComponent,
        // Offer Pages
        OffersPageComponent,
        OfferDetailsPageComponent,
        // Population Pages
        TestPopHomePageComponent,
        // Guardians Pages
        GuardianPageComponent,
        // External Cash Transfers
        CardPageComponent,
        TransferArenaCashPageComponent,
        TransferMultipleAccountsPageComponent,
        TransferArenaCashSuccessPageComponent,
        // Club Pages
        EarnPageComponent,
        BenefitsPageComponent,
        QuizPageComponent,
        PartnerPageComponent,
        LeaderboardPageComponent,
        LottosTermsPageComponent,
        MarketplaceTermsPageComponent,
        JokesPageComponent,
        WorksheetPageComponent,
        ZoomMeetingPageComponent,
        FantasyLeaderboardPageComponent,
        RenewalBonusTransferPageComponent,
        SubscriptionsPageComponent,
        CartCheckoutPagePortalProductsComponent,
        CartCheckoutPageComponent,
        CartCheckoutSuccessPageComponent,
        CartCheckoutResultsPageComponent,
        NotificationManagementPageComponent,
        TailgatePageComponent,
        ScorePredictorPageComponent,
        ProductsPageComponent,
        BadgesPageComponent,
        CardLinkingPageComponent,
        CardLinkingSuccessPageComponent,
        CardLinkAffiliatesPageComponent,
        CardLinkActivatedOffersPageComponent,
        CardLinkOfferDetailsPageComponent,
        CardLinkAllOffersPageComponent,
        CardLinkActivatedOffersPageComponent,
        TicketInvitePageComponentComponent,
        SplitTransactionPageComponent,
        CampaignDetailsPageComponent,
        WelcomeComponent,
        // Registration
        RegistrationLayoutPageComponent,
        WalletClaimedPageComponent,
        KidsLinkedPageComponent,
        TiersPageComponent
    ],
    imports: [
        // External Modules
        RouterModule,
        CarouselModule,
        AppRoutingModule,
        FormsModule,
        NgbModule,
        BrowserModule,
        HttpClientModule,
        ReactiveFormsModule,
        InlineSVGModule.forRoot(),
        QRCodeModule,
        BrowserAnimationsModule,
        EarnPointsModule,
        FaqSectionModule,
        TermsAndConditionsModule,
        AuctionDetailsModule,
        AuctionPanelModule,
        AuctionListModule,
        LottoDetailsModule,
        LottosListModule,
        LottosListItemModule,
        ScorecardModule,
        BadgeListItemModule,
        BadgeOverviewListModule,
        BadgeFeaturedListModule,
        MarketplaceDetailsModule,
        ShortLongDescriptionModule,
        MarketplaceListModule,
        MarketplaceListItemModule,
        MixedLoyaltyListModule,
        WalletModule,
        LoadingModule,
        CountryAndRegionsModule,
        EcashDescriptionModule,
        CardTokenListModule,
        CountdownModule,
        ErrorsModule,
        EditQuestionsModule,
        FeaturedRewardsModule,
        AddressBookModule,
        NotificationModule,
        ContactUsModule,
        MemberUpgradeCardComponent,
        TierV2Component,
        ToastrModule.forRoot({
            maxOpened: 5,
        }),
        JwtModule.forRoot({
            config: {
                allowedDomains: ['http://localhost/portal2/api'],
                tokenGetter: jwtTokenGetter,
            },
        }),
        TranslateModule.forRoot({
            missingTranslationHandler: {
                provide: MissingTranslationHandler,
                useClass: FGBMissingTranslationHandler,
            },
        }),
        // Feature Modules
        LayoutModule,
        GuardianModule,
        EventsModule,
        ClubModule,
        EcashModule,
        PurchaseModule,
        VouchersModule,
        UserModule,
        SharedModule,
        FantasyModule,
        TicketsModule,
        LoyaltyModule,
        BenefitModule,
        DisplayModule,
        DirectivesModule,
        CardLinkModule,
        AnnouncementsModule,
        OfferSearchBarModule,
        SharedSearchBarModule,
        CartItemModule,
        ClubSelectorModule,
        FilterModalModule
    ],
    providers: [
        provideAppConfig({
            dateOptions: {
                NgbDateFormat: 'DD MMM, YYYY',
                LongDateFormat: 'D MMM, yyyy h:mma',
                ShortDateFormat: 'D MMM, yyyy',
            }
        }),
        provideTmConfig({
            oauthConfig: tmSSOAuthConfig,
            loginAuthConfig: tmLoginAuthConfig,
        }),
        {
            provide: ALERT_NOTIFICATION_COMPONENTS,
            useFactory: () => {
                return mergeAlertNotificationComponentMaps(alertNotificationComponents);
            },
        },
        PopulationGuard,
        ProductCodeGuard,
        { provide: NgbDateParserFormatter, useClass: MomentDateFormatter },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
