<div class="w-100 d-md-flex d-block px-0">
  <!--Left Side-->

  <div class="shared-login-banner d-md-flex d-none">
    <img draggable="false" src="assets/images/login-registration/login-banner-2024.jpg" />
  </div>
  <div class="bg-primary d-flex w-100 d-md-none m-auto mobile-header"></div>
  <!--Right Side-->
  <div class="right-side-login d-flex flex-column align-items-center justify-content-center mt-5 mt-lg-0">
    <img class="maintenance-icon mb-3" height="75" draggable="false" src="assets/images/maintenance-icon.svg" />

    <h5
      class="text-primary text-center mb-0"
      [innerHTML]="'maintenancepage.currentlyunavailable.text' | translate | markdownTranslate"
    ></h5>
    <p
      class="text-center font-size-14"
      [innerHTML]="'maintenancepage.currentlyunavailable.subheading' | translate | markdownTranslate"
    ></p>
  </div>
</div>
