import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CampaignEntitlements, CampaignOffersQuery, CampaignOffersService } from '@fgb/core';
import { Subscription, firstValueFrom } from 'rxjs';

@Component({
  selector: 'fgb-campaign-details-page',
  templateUrl: './campaign-details-page.component.html',
  styleUrls: ['./campaign-details-page.component.scss'],
})
export class CampaignDetailsPageComponent implements OnInit, OnDestroy {
  currentCampaign: CampaignEntitlements;
  campaignOffferSub: Subscription;

  constructor(
    private route: ActivatedRoute,
    private campaignOffersService: CampaignOffersService,
    private campaignOffersQuery: CampaignOffersQuery
  ) {}

  ngOnInit() {
    let offerId = +(this.route.snapshot.paramMap.get('offerId') || '0');
    this._updateOffer(offerId);
  }

  ngOnDestroy(): void {
    this.campaignOffferSub.unsubscribe();
  }

  _updateOffer(offerId: number) {
    if (!!offerId) {
      firstValueFrom(this.campaignOffersService
        .fetchOffers())
        .then(() => {
          this.campaignOffferSub = this.campaignOffersQuery.selectFirst().subscribe((campaignOffer) => {
            if (campaignOffer) {
              for (let entitlement of campaignOffer?.CampaignsAndEntitlements) {
                if (entitlement.CampaignID === offerId) {
                  this.currentCampaign = entitlement;
                  break;
                }
              }
            }
          });
        });
    }
  }
}
