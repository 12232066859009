<div class="col-lg-12">
  <h2 class="text-start">{{ 'home.page.leaderboard.title' | translate }}</h2>
</div>
<div class="card text-start border-0 shadow">
  <div class="card-header bg-primary text-white font-size-12 fw-bold text-uppercase text-center py-2">
    {{ today | date : 'yyyy' }}
  </div>
  <div *ngIf="data" class="card-body pt-2 pb-3">
    <div class="row">
      <div class="col-3 offset-1 font-size-12 fw-bold">{{ 'leaderboard.rank' | translate }}</div>
      <div class="col-3 font-size-12 fw-bold offset-5 text-end">{{ 'leaderboard.points' | translate }}</div>
    </div>
    <ng-container *ngFor="let item of data; index as i">
      <div *ngIf="i > 0 && item.Position - data[i - 1].Position > 1" class="row">
        <div class="col flex-grow-1"><hr class="my-2" /></div>
      </div>
      <div class="row py-2 font-size-14 fw-bold" [ngClass]="{ 'bg-primary rounded my-1': item.IsCurrentUser }">
        <div class="col-1 font-size-12 fw-bold text-info" [ngClass]="{ 'text-white': item.IsCurrentUser }">#</div>
        <div class="col-3">
          <div [ngClass]="{ 'text-white': item.IsCurrentUser }">{{ item.Position }}</div>
        </div>
        <div class="col-5">
          <div class="text-center text-info" [ngClass]="{ 'text-white': item.IsCurrentUser }" *ngIf="item.IsCurrentUser">
            {{ item.FullName }}
          </div>
        </div>
        <div class="col-3">
          <div class="text-end text-info px-1" [ngClass]="{ 'text-white': item.IsCurrentUser }">
            {{ item.Points | number }}
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <!-- <div *ngIf="showViewFull" class="w-100 mb-3 text-center">
    <a class="fw-bold font-size-14 text-primary" [routerLink]="['/leaderboard']">View Full Leaderboard</a>
  </div> -->
</div>
