<div class="w-100 d-md-flex d-block px-0">
  <!--Left Side-->
  <div class="shared-login-banner d-md-block d-none">
    <img draggable="false" src="assets/images/login-registration/login-banner-2024.jpg" />
  </div>

  <div class="bg-dark-green d-flex w-100 d-md-none m-auto mobile-header">
    <img draggable="false" src="assets/images/club-logo.png" />
  </div>
  <!--Right Side-->
  <div class="p-lg-4 p-2 right-side-login bg-light-grey">
    <form class="mt-lg-0 mt-3 change-password container" (submit)="submit()" [formGroup]="formChangePassword">
      <h4 class="reset-password-title mb-4">{{ 'change.password.title' | translate : { fgbdefault: 'NEW PASSWORD' } }}</h4>
      <p class="font-size-14">
        {{ 'change.password.instruction' | translate : { fgbdefault: 'Please enter your new password:' } }}
      </p>
      <div class="mb-4">
        <div class="form-input mb-3">
          <div class="form-floating position-relative">
            <input class="form-control" [type]="visiblePassword ? 'text' : 'password'" formControlName="password" />
            <label>{{ 'change.pw.label' | translate }}</label>
            <span
              class="visible-icon material-icons cursor-pointer text-info"
              (click)="toggleVisiblePassword()"
              *ngIf="visiblePassword"
            >
              visibility
            </span>
            <span
              class="visible-icon material-icons cursor-pointer text-info"
              (click)="toggleVisiblePassword()"
              *ngIf="!visiblePassword"
            >
              visibility_off
            </span>
          </div>
          <div
            class="text-danger font-size-12 mt-2"
            *ngIf="formChangePassword.get('password')?.touched && formChangePassword.get('password')?.hasError('required')"
          >
            {{ 'change.password.required' | translate : { fgbdefault: 'New Password is required' } }}
          </div>
        </div>
        <div class="form-input mb-1">
          <div class="form-floating position-relative">
            <input class="form-control" [type]="visibleConfirm ? 'text' : 'password'" formControlName="confirmPassword" />
            <label>{{ 'change.pw.label.confirm' | translate }}</label>
            <span
              class="visible-icon material-icons cursor-pointer text-info"
              (click)="toggleVisibleConfirm()"
              *ngIf="visibleConfirm"
            >
              visibility
            </span>
            <span
              class="visible-icon material-icons cursor-pointer text-info"
              (click)="toggleVisibleConfirm()"
              *ngIf="!visibleConfirm"
            >
              visibility_off
            </span>
          </div>
          <div
            class="text-danger font-size-12 mt-2"
            *ngIf="
              formChangePassword.get('confirmPassword')?.touched &&
              formChangePassword.get('confirmPassword')?.hasError('required')
            "
          >
            {{ 'change.password.required' | translate : { fgbdefault: 'Confirm New Password is required' } }}
          </div>
        </div>
        <div class="text-danger font-size-12" *ngIf="errorMessage">
          {{ errorMessage }}
        </div>
      </div>
      <div class="button-wrapper mt-5 d-flex justify-content-center align-items-center flex-column">
        <button type="submit" [disabled]="formChangePassword.invalid" class="btn btn-primary round">
          {{ 'change.password.button' | translate : { fgbdefault: 'Reset Password' } }}
        </button>
      </div>
    </form>
  </div>
</div>

<ng-template #alert let-modal>
  <div class="user-change-password-modal p-3 text-center">
    <h4>{{ 'user.change.password.modal.title' | translate : { fgbdefault: 'CHANGE PASSWORD SUCCESSFULLY' } }}</h4>
    <p class="font-size-14">
      {{ 'user.change.password.modal.message' | translate : { fgbdefault: 'Click OK to go back to login page' } }}
    </p>
    <div class="modal-button-wrapper">
      <a [routerLink]="'/login'" class="btn btn-primary round" id="modal-btn" (click)="modal.close()">{{
        'user.change.password.modal.button' | translate : { fgbdefault: 'OK' }
      }}</a>
    </div>
  </div>
</ng-template>
