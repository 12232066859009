<div class="banner d-none d-lg-flex" role="banner">
  <h1 class="text-uppercase">{{ 'wallet.page.title' | translate }}</h1>
</div>
<div class="d-none d-lg-block">
  <fgb-mixed-loyalty-nav></fgb-mixed-loyalty-nav>
</div>
<div class="container tier-container" *ifScreenSize="screenType.Mobile">
  <fgb-tier-v2></fgb-tier-v2>
</div>

<!-- Wallet -->
<ng-container
  *ngIf="
    {
      walletItemsUnclaimed: walletItemsUnclaimed$ | async
    } as walletItems;
    else noWalletItems
  "
>
  <div class="wallet-redeem-carousel">
    <div class="container">
      <ng-container *ngIf="walletItems.walletItemsUnclaimed && walletItems.walletItemsUnclaimed.length; else noWalletItems">
        <fgbcl-rewards-wallet-redeem-list [unRedeemedItems]="walletItems.walletItemsUnclaimed"></fgbcl-rewards-wallet-redeem-list>
      </ng-container>
    </div>
  </div>
</ng-container>

<ng-template #noWalletItems>
  <div class="container">
    <h5 class="no-wallet-items mb-3 fw-bold text-uppercase">{{ 'rewards.wallet.no.items' | translate }}</h5>
    <div class="border round no-wallet-items-container">
      <div class="no-wallet-items-description font-size-12 text-center py-3">
        {{ 'rewards.wallet.no.items.description' | translate }}
      </div>
    </div>
  </div>
</ng-template>

<div class="container mt-3 text-center">
  <a class="font-size-14 claimed-text fw-bold" [routerLink]="['/rewards/claimed']">
    {{ 'rewards.wallet.view.claimed' | translate }}
  </a>
</div>
