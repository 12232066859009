import { Component, OnInit } from '@angular/core';
import { FantasyHistory, FantasyHistoryQuery } from '@fgb/core';
import { Observable } from 'rxjs';
import * as _moment from 'moment';
import { map } from 'rxjs/operators';
import { OwlOptions } from 'ngx-owl-carousel-o';
const moment = _moment;

@Component({
  selector: 'fgb-fantasy-history-list',
  templateUrl: './fantasy-history-list.component.html',
  styleUrls: ['./fantasy-history-list.component.scss'],
})
export class FantasyHistoryListComponent implements OnInit {
  fantasyHistory$: Observable<FantasyHistory[]>;
  pendingPeriod: number = 0;

  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: true,
    autoplay: true,
    autoplayTimeout: 4000,
    autoplaySpeed: 700,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: { items: 1 },
      400: { items: 1 },
      740: { items: 4 },
      940: { items: 4 },
    },
    nav: false,
  };

  constructor(private fantasyHistoryQuery: FantasyHistoryQuery) {}

  ngOnInit() {
    this.fantasyHistory$ = this.fantasyHistoryQuery
      .selectHistoryBeforeDate(moment.utc().subtract(this.pendingPeriod, 'hours'))
      .pipe(map((x) => (x = x.slice(0, 5))));
  }
}
