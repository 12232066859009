<div class="banner d-none d-lg-flex" role="banner">
  <h1>{{ 'games.page.pick.player.title' | translate }}</h1>
</div>
<div class="container pt-lg-2 pt-0">
  <div class="d-flex d-lg-none mt-2">
    <a
      [routerLink]="[backButtonLink]"
      class="btn back-btn d-flex align-items-center justify-content-center h-100 py-lg-2 p-0 fw-bold mb-2 px-0"
    >
      <span class="icon material-icons" aria-hidden="true">chevron_left</span>
      <div class="py-lg-3 py-0 rounded w-100 d-flex justify-content-start align-items-center fw-bold">
        <div class="svg svg-player-bar svg-error" [inlineSVG]="'assets/images/icons/nav-pick-a-player.svg'"></div>
        <div class="text-capitalize mb-0 ms-1 font-size-14">
          {{ 'pick.player.back.button' | translate }}
        </div>
      </div>
    </a>
  </div>
  <div class="row">
    <div class="col-lg-4 col-12 pt-lg-3 pt-2">
      <fgb-fantasy-game-panel
        [game]="game$ | async"
        (button)="confirmSelection()"
        [buttonState]="button"
        [horizontal]="false"
      ></fgb-fantasy-game-panel>
      <div class="d-none d-lg-block">
        <a [routerLink]="[backButtonLink]" class="d-flex align-items-center back-btn fw-bold px-0 pt-3">
          <span class="icon material-icons pt-1" aria-hidden="true">chevron_left</span>
          <span class="font-size-14 font-default fw-bold pt-1"> {{ 'pick.player.back.button' | translate }}</span>
        </a>
      </div>
    </div>
    <div class="col-lg-8 col-12">
      <div class="d-none d-lg-block">
        <div class="row">
          <div class="col-3" *ngFor="let player of players$ | async">
            <div
              class="card player-card overflow-hidden mt-3 border"
              [class.selected]="player.IsSelected"
              (click)="select(player.PlayerId)"
            >
              <img class="card-img-top w-100 border-bottom" src="{{ player.Image | contentImage : '3' }}" />
              <div
                class="card-body d-flex justify-content-start align-items-center p-0 player-name-div"
                [ngClass]="{
                  'bg-tertiary text-white player-not-selected': player.IsSelected,
                  'bg-white text-black player-not-selected': !player.IsSelected
                }"
              >
                <div class="mx-auto mb-0 font-size-12 text-capitalize text-center player-name">{{ player.PlayerName }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-block d-lg-none container p-0 mt-lg-0 mt-3">
        <div class="row justify-content-around">
          <div class="col-5 my-2" *ngFor="let player of players$ | async">
            <div class="row flex-column" (click)="select(player.PlayerId)">
              <div class="border-bottom px-0">
                <img class="w-100 rounded-top-4" src="{{ player.Image | contentImage : '3' }}" />
              </div>
              <div
                class="rounded-bottom-4 d-flex justify-content-center align-items-center player-name-div"
                [ngClass]="{
                  'bg-tertiary text-white player-selected': player.IsSelected,
                  'text-black player-not-selected': !player.IsSelected
                }"
              >
                <div class="ms-1 mb-0 font-size-12 text-capitalize player-name">{{ player.PlayerName }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
