<div class="survey-container rounded bg-poll">
  <div class="survey-header text-center">
    <div
      class="image-container-polls w-100 overflow-hidden position-relative bg-white rounded-top mb-2 d-flex"
      *ngIf="survey()?.ImageURL; else noImage"
    >
      <img class="w-100" src="{{ survey()?.ImageURL | contentImage : '7' }}" alt="{{ survey()?.ImageURL }}" />
    </div>
    <ng-template #noImage>
      <div class="no-image text-white">
        <div class="no-image-title text-uppercase mb-5">{{ 'survey.item.poll.title' | translate }}</div>
      </div>
    </ng-template>
    <div class="survey-info">
      <div class="d-flex justify-content-between align-items-center">
        <div class="d-flex align-items-center">
          <div class="gIcon-polls material-icons inline-block my-0 text-white" aria-hidden="true">assessment</div>
        </div>
        <div class="px-3 d-none d-lg-block text-white survey-question">{{ survey()?.SurveyQuestion }}</div>
        <div *ngIf="!survey()?.HasBeenAnsweredByUser" class="text-end text-white font-size-16 align-items-center d-flex">
          <div class="icon material-icons me-2" aria-hidden="true">schedule</div>
          <fgbcl-countdown [endDateTime]="survey()?.EndDate" [isUTC]="true" class="font-size-16 fw-bold"></fgbcl-countdown>
        </div>
      </div>
      <div class="d-block d-lg-none mt-3 text-white font-size-14 survey-question xs text-start">
        {{ survey()?.SurveyQuestion }}
      </div>

      <div class="text-center fw-bold text-white font-size-14" [ngClass]="pointsWon() > 0 ? 'd-block d-lg-none' : 'd-none'">
        +{{ pointsWon() }} Pts
      </div>
    </div>
  </div>
  <ng-container *ngIf="survey()?.HasBeenAnsweredByUser; else answer">
    <fgb-survey-item-answer-completed-poll [survey]="survey()" [pointsWon]="pointsWon()"></fgb-survey-item-answer-completed-poll>
  </ng-container>
  <ng-template #answer>
    <fgb-survey-item-answer [survey]="survey()"></fgb-survey-item-answer>
  </ng-template>
</div>
