<div *ngSubscribe="offersList$ as offersList">
  <fgb-offers-toolbar
    [offers]="offersList"
    (viewChange)="view = $event"
    (filteredArray)="updateFilteredOffers($event, true)"
  ></fgb-offers-toolbar>

  <div class="container mt-4 results">
    <div *ngIf="view === 'grid'" [class.row]="isList">
      <ng-container *ngIf="filteredOffers && filteredOffers.length; else noOffer">
        <div class="px-2" *ngFor="let offer of filteredOffers" [class.col-lg-4]="isList" [class.col-md-6]="isList">
          <fgb-offers-list-item (navigateToOffer)="navigatedToOffer($event)" [offer]="offer"></fgb-offers-list-item>
        </div>
      </ng-container>
      <div class="container d-flex justify-content-end" *ngIf="filteredOffers.length >= 9">
        <button class="link fw-bold" *ngIf="allShown" (click)="showFirstPage()">
          <span class="icon icon icon-arrow-1-circle-up pe-1 fw-bold"></span>{{ 'offers.view.less' | translate }}
        </button>
        <button class="link fw-bold" *ngIf="!allShown" (click)="showAll()">
          <span class="icon icon icon-arrow-1-circle-down pe-1 fw-bold"></span>{{ 'offers.view.more' | translate }}
        </button>
      </div>

      <ng-template #noOffer>
        <div class="container text-center">
          <div class="material-icons text-secondary" aria-hidden="true">manage_search</div>
          <div class="fw-bold">{{ 'no.offers.found' | translate }}</div>
          <div class="font-size-12">{{ 'no.offers.suggestion' | translate }}</div>
        </div>
      </ng-template>
      <ng-template #loadingRef>
        <div class="loading-container">
          <fgbcl-loading></fgbcl-loading>
        </div>
      </ng-template>
    </div>
    <div id="map-view-container" *ngIf="view === 'map'" class="row">
      <div class="col-lg-8 col-12 mb-lg-0 mb-4">
        <fgb-offers-map #map [offers]="filteredOffers" (selectedDepartmentID)="selectedDepartmentID = $event"></fgb-offers-map>
      </div>
      <div class="col-lg-4 overflow-auto hide-scrollbar">
        <div class="row">
          <div class="col-lg-12 col-md-6" *ngFor="let offer of filteredOffers" (click)="selectOffer(offer)">
            <fgb-offers-list-item
              (navigateToOffer)="navigatedToOffer($event)"
              [offer]="offer"
              [highlighted]="offer.DepartmentId === selectedDepartmentID || offer.OfferId === selectedOfferID"
            ></fgb-offers-list-item>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
