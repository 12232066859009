import { Component, OnInit, Input } from '@angular/core';
import { FantasyPlayer, FantasyGame, FantasyGameQuery, FantasyGameService } from '@fgb/core';
import { Observable, firstValueFrom } from 'rxjs';
import { Router } from '@angular/router';
import { map, filter, tap } from 'rxjs/operators';

@Component({
  selector: 'fgb-fantasy-player-select',
  templateUrl: './fantasy-player-select.component.html',
  styleUrls: ['./fantasy-player-select.component.scss'],
})
export class FantasyPlayerSelectComponent implements OnInit {
  constructor(private fantasyQuery: FantasyGameQuery, private fantasyService: FantasyGameService, private router: Router) {}

  @Input() gameId: number;
  @Input() backButtonLink: string = '/games/pickaplayer';

  selectedPlayer$: Observable<FantasyPlayer | undefined>;

  game$: Observable<FantasyGame | undefined>;
  players$: Observable<FantasyPlayer[] | undefined>;
  button: 'make-selection' | 'confirm' | 'changing-player' = 'make-selection';
  firstSelection: boolean = false;
  changedSelection: boolean = false;

  ngOnInit() {
    this.game$ = this.fantasyQuery.selectGameById(this.gameId).pipe(
      tap((g) => {
        if (!g) {
          this.router.navigate(['/games/pickaplayer']);
          return;
        }
        if (g.SelectedPlayerId === 0) {
          this.firstSelection = true;
        } else if (this.changedSelection || (this.firstSelection && g.SelectedPlayerId > 0)) {
          this.button = 'confirm';
        } else {
          this.button = 'changing-player';
        }
      })
    );
    this.players$ = this.fantasyQuery.selectPlayersByGameId(this.gameId);
    this.selectedPlayer$ = this.game$.pipe(
      map((game) => {
        if (game) {
          return game.GamePlayers.find((p) => p.IsSelected) as FantasyPlayer;
        }
        return;
      }),
      filter((player) => !!player)
    );
  }

  select(playerId: number) {
    this.button = 'confirm';
    this.changedSelection = true;
    this.fantasyService.markSelected(this.gameId, playerId);
  }
  confirmSelection() {
    firstValueFrom(this.fantasyService.confirmSelection(this.gameId));
    this.router.navigate(['/games/pickaplayer']);
  }
}
