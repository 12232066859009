<div class="banner" role="banner">
  <h1 class="text-uppercase">{{ 'account.activity.page.title' | translate }}</h1>
</div>

<fgb-account-nav></fgb-account-nav>

<div class="container activity-container">
  <div class="points-history">
    <fgb-transaction-history [transactionTypes]="[transactionTypes.loyalty]" [accountView]="true"></fgb-transaction-history>
  </div>
</div>
