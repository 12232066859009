import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, fromEvent, of } from 'rxjs';
import { distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators';

export interface BenefitItem{
  title: string;
  description: string;
  images: string[];
  position: {
    top: number;
    left: number;
  },
  mobilePosition:{
    top: number;
    left: number;
  }
}

@Component({
  selector: 'fgb-upgrade-benefits-card',
  templateUrl: './upgrade-benefits-card.component.html',
  styleUrls: ['./upgrade-benefits-card.component.scss']
})
export class UpgradeBenefitsCardComponent implements OnInit {

  @Input() benefits: BenefitItem[] = [
    {
      title: 'upgrade.benefits.card.one.title',
      description: 'upgrade.benefits.card.one.description',
      images: ['/assets/images/benefits/benefit-item-1-1.png', '/assets/images/benefits/benefit-item-1-2.png'],
      position: {
        top: 25,
        left: 48
      },
      mobilePosition: {    
        top: 22,
        left: 134
      }
    },
    {
      title: 'upgrade.benefits.card.two.title',
      description: 'upgrade.benefits.card.two.description',
       images: ['/assets/images/benefits/benefit-item-2-1.png', '/assets/images/benefits/benefit-item-2-2.png'],
      position: {
        top: 73,
        left: 40
      },
      mobilePosition: {    
        top: 73,
        left: 97
      }
    },
    {
      title: 'upgrade.benefits.card.three.title',
      description: 'upgrade.benefits.card.three.description',
      images: ['/assets/images/benefits/benefit-item-3-1.png', '/assets/images/benefits/benefit-item-3-2.png'],
      position: {
        top: 80,
        left: 30
      },
      mobilePosition: {
        top: 80,
        left: 36
      }
    }
  ];
  @Input() itemsPerRow: number = 3;
  @Input() imageHeight: number = 210;
  @Input() useTranslate: boolean = true;
  items$: Observable<BenefitItem[]>;
  link: string;
  isMobile$: Observable<boolean> = fromEvent(window, 'resize').pipe(startWith(window.innerWidth), map(() => window.innerWidth < 992), distinctUntilChanged());

  constructor(private translate: TranslateService) { }

  ngOnInit(): void {
    this.items$ = this.isMobile$.pipe(switchMap(isMobile => {
      return of(this.benefits).pipe(
        map(items => items.map(item => {
          let title: string = item.title;
          let description: string = item.description;

          if( this.useTranslate ){
            title = this._translateText(item.title);
            description = this._translateText(item.description);
          }

          return { ...item, 
                    title,
                    description,
                    position: isMobile ? item.mobilePosition : item.position 
                  }
        }))
      )
    }));
    this.link = this._translateText('upgrade.benefits.button.link');
  }

  private _translateText(text: string): string{
    return this.translate.instant(text);
  }

}
